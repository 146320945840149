// project imports
import MinimalLayout from "layout/MinimalLayout";
import {createElement, lazy} from "react";
import Loadable from "ui-component/Loadable";
import Registration from "views/pages/registration";
import { Offer } from "views/pages/misc/Offer";
import { ApplicationLoader } from "views/pages/application/ApplicationLoader";
import {Navigate, useParams} from "react-router-dom";

// ==============================|| MAIN ROUTING ||============================== //
// maintenance routing
const MaintenanceError = Loadable(lazy(() => import("views/pages/maintenance/Error")));
const MaintenanceUnderConstruction = Loadable(lazy(() => import("views/pages/maintenance/UnderConstruction")));

// landing + application / intake routing
const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));
const PagesIntake = Loadable(lazy(() => import("views/pages/intake")));
const PagesFaqs = Loadable(lazy(() => import("views/pages/misc/Faqs")));
const PagesPrivacyPolicy = Loadable(lazy(() => import("views/pages/misc/PrivacyPolicy")));

const RedirectToLanding = () => {
	const {tenant} = useParams();
	return <Navigate to={`/${tenant}/pages/landing`} replace />;
};

const RedirectToPrevious = () => {
	const redirect = sessionStorage.getItem("ppulus-redirect");
	sessionStorage.removeItem("ppulus-redirect");
	return <Navigate to={`${redirect}`} replace />;
};

const MainRoutes = {
	path: "/:tenant/*",
	element: (
		<MinimalLayout />
	),
	children: [
		{
			path: "pages/error",
			element: <MaintenanceError />
		},
		{
			path: "pages/under-construction",
			element: <MaintenanceUnderConstruction />
		},
		{
			path: "pages/landing",
			element: <PagesLanding />
		},
		{
			path: "pages/intake/*",
			element: <PagesIntake />
		},
		{
			path: "pages/faqs",
			element: <PagesFaqs />
		},
		{
			path: "pages/privacy-policy",
			element: <PagesPrivacyPolicy />
		},
		{
			path: "pages/registration?/:type",
			element: <Registration />
		},
		{
			path: "pages/offer/:code",
			element: createElement(ApplicationLoader, undefined, <Offer/>)
		},
		{ //Gets hit when returning from an authorization call (oauth/endpoint)
			path: "callback",
			element: <RedirectToPrevious />
		},
		{
			path: "*",
			element: <RedirectToLanding />
		}
	]
};

export default MainRoutes;
