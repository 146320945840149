import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "library/api";
import {ClientRegistrationResult} from "library/ClientRegistrationResult";

const registerUser = createAsyncThunk("auth/registerMigratingUser", async (email: string, {rejectWithValue}) => {
	try {
		return await api.Register.create(email);
	}
	catch {
		return rejectWithValue(new ClientRegistrationResult({hasError: true}));
	}
});

const registration = createSlice({
	name: "migrationState",
	initialState: {loading: false, value: new ClientRegistrationResult()},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(registerUser.pending, state => ({value: state.value, loading: true}));
		builder.addCase(registerUser.fulfilled, (_, {payload}) => ({value: payload, loading: false}));
		builder.addCase(registerUser.rejected, () => ({value: new ClientRegistrationResult({hasError: true}), loading: false}));
	}
});

export default registration.reducer;
export {registerUser};