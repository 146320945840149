import { FC } from "react";
import { Avatar, Typography, useTheme } from "@mui/material";
import { getInitials } from "utils/getInitials";

const ApplicantAvatar: FC<{ fullName: string | undefined }> = ({ fullName }) => {
	const theme = useTheme();

	return (
		<Avatar sx={{ background: theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.main}}>
			<Typography variant="h3" sx={{ color: "white", fontSize: "1rem", lineHeight: "1.75rem" }}>
				{ getInitials(fullName) }
			</Typography>
		</Avatar>
	);
};

export { ApplicantAvatar };
