import { FC } from "react";
import { Button, Grid } from "@mui/material";
import { filterObjectForInfoCard } from "utils/filterObjectForInfoCard";
import { ApplicantContactCardDetailItem } from "./ApplicationContactCard/ApplicantContactCardDetailItem";
import { OtherIncome } from "library";

type OtherIncomeAssetsCardProps = {	
	otherIncome: Partial<OtherIncome>;
	onEdit?: () => void;
	onRemove?: () => void;
}

const OtherIncomeAssetsCard: FC<OtherIncomeAssetsCardProps> = (
	{ otherIncome, onEdit, onRemove }
) => (
	<Grid container spacing={0}>		
		<Grid item xs={11}>
			<Grid container sx={{ pt: 1, pb: 1, pr: 3, justifyItems: "flex-end" }}>				
				{
					onEdit && <Grid item sx={{ mt: -1, mr: 1 }}>
						<Button variant="outlined" onClick={onEdit}>Edit</Button>
					</Grid>
				}
				{
					onRemove && <Grid item sx={{ mt: -1, mr: -3 }}>
						<Button variant="outlined" onClick={onRemove}>Remove</Button>
					</Grid>
				}
			</Grid>
			<Grid item rowSpacing={2} container sx={{ pb: 2, textAlign: "left" }}>
				{
					filterObjectForInfoCard(otherIncome, ["name", "annualamount", "member", "validated"])
						.map(([k, v]) => <ApplicantContactCardDetailItem key={k} detailName={`${k}`} detailValue={`${v}`} />)
				}
			</Grid>
		</Grid>
	</Grid>
);

export { OtherIncomeAssetsCard };