import { DateValue } from "./DateValue";
import {ApplicantInformation, ApplicationContacts, Circumstances, Housing, IAccountInfo, NoticeOfAssessmentIncome, OtherIncome, Situation} from "./application";

export type InformationType = ApplicantInformation | Housing | Situation | ApplicationContacts | NoticeOfAssessmentIncome | OtherIncome | IAccountInfo | Circumstances;

export const stringify = (k: string, v: string | object | boolean, information?: InformationType): null | string => {
	if (["holderName","transit","institution","account"].includes(k))
		return `${v}`;

	if (k === "validated" || Array.isArray(v))
		return null;

	if (v === null || v === undefined)
		return null;

	if (v === true)
		return "Yes";

	if (v === false)
		return "No";
	
	if (typeof v === "number")
		return `${v}`;

	if (k === "socialInsuranceNumber")
		return "●●● ●●● ●●●";

	if (typeof v === "object" && k.match(/.*phone/i) && information instanceof ApplicantInformation) {
		const key = k.toString() === "primaryPhone" ? "primaryPhone" : "secondaryPhone";
		const phoneNumber = (information[key] || {}).number?.replace(/^\+\d{1,3}\s?/, "");
		const phoneType = (information[key] || {}).type;
		if (!phoneNumber) return null;

		return phoneType
			? `${phoneNumber} (${phoneType})`
			: phoneNumber;
	}

	if (typeof v === "object" && k === "email" && information instanceof ApplicantInformation) {
		const emailAddress = (information[k] || {}).address;
		if (!emailAddress) return null;

		return emailAddress;
	}

	if (information instanceof Housing) {
		if (k === "leaseExpiry") {
			const leaseExpiry = information[k] as DateValue;
			if (!leaseExpiry) return null;

			return stringify(k, leaseExpiry);
		} else if (k === "monthlyRent" || k === "monthlyUtilities") {
			return `$${Number(v).toLocaleString()}`;
		}
	}
		
	if (DateValue.isDate(v)) {
		const asDate = new DateValue(v as string | number | DateValue | Date);
		return asDate.format("MMM D, YYYY");
	}

	return v.toString();
};