// material-ui
import { createTheme } from "@mui/material/styles";
import {useMemo} from "react";
import {useLocation} from "react-router-dom";
import useConfig from "hooks/useConfig";

const getTenantStyles = (tenant: string) => {
	try {
		return require(`assets/scss/${tenant}.variables.scss`);
	} catch {
		return require ("assets/scss/_themes-vars.module.scss");
	}
};

export const useTenantPalette = () => {
	const location = useLocation();
	const {navType} = useConfig();
	
	const tenant = useMemo(() => location.pathname.split("/").filter(Boolean)[0], [location]);
	
	const colors = getTenantStyles(tenant).default;
	setBrowserVariables(colors);
	
	return createTheme({
		newUser: "blue",
		fontFamily: colors.fontFamily,
		palette: {
			mode: navType,
			common: {
				black: colors.darkPaper
			},
			existingUser: {
				main: colors.existingUser
			},
			newUser: {
				main: colors.newUser
			},
			existingUserLoggedIn: {
				main: colors.existingUserLoggedIn
			},
			primary: {
				light: navType === "dark" ? colors.darkPrimaryLight : colors.primaryLight,
				main: navType === "dark" ? colors.darkPrimaryMain : colors.primaryMain,
				dark: navType === "dark" ? colors.darkPrimaryDark : colors.primaryDark,
				200: navType === "dark" ? colors.darkPrimary200 : colors.primary200,
				800: navType === "dark" ? colors.darkPrimary800 : colors.primary800
			},
			secondary: {
				light: navType === "dark" ? colors.darkSecondaryLight : colors.secondaryLight,
				main: navType === "dark" ? colors.darkSecondaryMain : colors.secondaryMain,
				dark: navType === "dark" ? colors.darkSecondaryDark : colors.secondaryDark,
				200: navType === "dark" ? colors.darkSecondary200 : colors.secondary200,
				800: navType === "dark" ? colors.darkSecondary800 : colors.secondary800
			},
			error: {
				light: colors.errorLight,
				main: colors.errorMain,
				dark: colors.errorDark
			},
			orange: {
				light: colors.orangeLight,
				main: colors.orangeMain,
				dark: colors.orangeDark
			},
			warning: {
				light: colors.warningLight,
				main: colors.warningMain,
				dark: colors.warningDark
			},
			success: {
				light: colors.successLight,
				200: colors.success200,
				main: colors.successMain,
				dark: colors.successDark
			},
			grey: {
				50: colors.grey50,
				100: colors.grey100,
				500: navType === "dark" ? colors.darkTextSecondary : colors.grey500,
				600: navType === "dark" ? colors.darkTextTitle : colors.grey900,
				700: navType === "dark" ? colors.darkTextPrimary : colors.grey700,
				900: navType === "dark" ? colors.darkTextPrimary : colors.grey900
			},
			dark: {
				light: colors.darkTextPrimary,
				main: colors.darkLevel1,
				dark: colors.darkLevel2,
				800: colors.darkBackground,
				900: colors.darkPaper
			},
			text: {
				primary: navType === "dark" ? colors.darkTextPrimary : colors.grey700,
				secondary: navType === "dark" ? colors.darkTextSecondary : colors.grey500,
				dark: navType === "dark" ? colors.darkTextPrimary : colors.grey900,
				hint: colors.grey100
			},
			divider: navType === "dark" ? colors.darkTextPrimary : colors.grey200,
			background: {
				paper: navType === "dark" ? colors.darkLevel2 : colors.paper,
				default: navType === "dark" ? colors.darkPaper : colors.paper
			},
			banners: {
				annualReview: {
					left: colors["annualReviewBanner-left"],
					main: colors["annualReviewBanner-main"],
					right: colors["annualReviewBanner-right"]
				},
				gbv: {
					left: colors["gbvBanner-left"],
					main: colors["gbvBanner-main"],
					right: colors["gbvBanner-right"]
				},
				interimReview: {
					left: colors["interimReviewBanner-left"],
					main: colors["interimReviewBanner-main"],
					right: colors["interimReviewBanner-right"]
				},
				standard: {
					left: colors["standardBanner-left"],
					main: colors["standardBanner-main"],
					right: colors["standardBanner-right"]
				}
			}
		}
	});
};

const setBrowserVariables = (colors: Record<string, string>) => {
	Object.entries(colors).forEach(([key, value]) => document.documentElement.style.setProperty(`--${key}`, value));
};