import styles from "styles/applicationReview.module.scss";

import {Button, Grid, Stack, Switch, Typography} from "@mui/material";
import {ApplicantContactLayout} from "components/ApplicationContactCard/ApplicantContactCard";
import {ApplicationReviewInfoCard} from "components/ApplicationReviewInfoCard";
import {ContactConsensusCard} from "components/ContactConsensusCard";
import {ItemList} from "components/ItemList";
import {useApplication} from "hooks";
import useApplicationRoutes from "hooks/useApplicationRoutes";
import {Adult, ApplicationDocuments, ApplicationType, Dependant, fullWidth, isEmpty} from "library";
import {ApplicationConsent} from "library/application/consent";
import {FC, useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "store";
import {setConsent, setDocuments} from "store/slices/application";
import {submitApplication} from "store/slices/submission";
import {SectionHeading, SectionSubHeading} from "ui-component/SectionHeading";
import {IPreviousNext} from "./ApplicationContainer";
import {ApplicationPreviousNext} from "./ApplicationPreviousNext";
import {Trab} from "./Trab";

import {IncomeAssetsReviewCard} from "components/IncomeAssetsReviewCard";
import {ApplicantAvatar} from "ui-component/ApplicantAvatar";
import {ApplicantContactName} from "ui-component/ApplicantContactName";
import {Conditions} from "./Conditions";
import useNavigator from "hooks/useNavigator";
import {useFileUploaderActions} from "hooks/useFileUploaderActions";
import {FileUploader} from "components/FileUploader";

const Review: FC<IPreviousNext> = ({ previous, next }) => {
	const dispatch = useDispatch();
	const { application } = useApplication();
	const { loading: submissionPending } = useSelector(s => s.submission);
	const { applicant, housing, situation, contacts, household, consent } = application;
	const [appConsent, setValue] = useState(new ApplicationConsent(consent));
	const { locations, gotoRoute } = useApplicationRoutes();
	const navigate = useNavigator();

	const canSubmit = useMemo(() => (!submissionPending
		&& appConsent.authorizeCommunication && (application.type !== ApplicationType.AnnualReview || appConsent.understandResponsibilities) || (!submissionPending && application.type === ApplicationType.InterimReview)), [submissionPending, application.type, appConsent]);

	const onNext = useCallback(async () => {
		dispatch(setDocuments(new ApplicationDocuments(application.documents).validate(application.isGbv)));
		if (!canSubmit) return;
		
		dispatch(setConsent(appConsent));
		return dispatch(submitApplication(application))
			.unwrap()
			.then(() => next.location)
			.catch(console.error);
	}, [application, appConsent]);
	const householdMembers: (Adult | Dependant)[] = [
		...(household?.adults || []),
		...(household?.dependants || []),
	];

	const contactConsensus = {
		requireFollowup: contacts?.requireFollowup || false
	};

	const set = useCallback((v: Partial<ApplicationConsent>) => {
		setValue(current => {
			const updatedConsent = new ApplicationConsent({ ...current, ...v });
			dispatch(setConsent(updatedConsent));
			return updatedConsent;
		});
	}, [setValue]);

	const contactPersons = contacts?.contacts?.filter(c => {
		if (c.name || c.organization || (c.phone && c.phone!.number) || (c.email && c.email!.address)) return c;
	}) || [];
	const { addFiles, removeFile } = useFileUploaderActions();
	return (
		<>
			{!applicant?.income?.includeSocialProgramSource && <Trab />}
			<SectionHeading>Review and Submit</SectionHeading>
			{application.type === ApplicationType.Standard ?
				<Grid item {...fullWidth} mb={8}>
					Your application is ready for submission.Please review your application details below and return to any previous
					sections to make edits if required.  If the application details are accurate, please proceed to submission by
					clicking on the “Submit Application” button.
				</Grid> :
				<Grid item {...fullWidth} mb={8}>
					Your review is ready for submission.Please confirm your review information below and return to any previous
					sections to make edits if required.  If the review information is accurate, please proceed to submission by
					clicking on the “Submit” button.
				</Grid>
			}

			{applicant && <ApplicationReviewInfoCard testElementName="btnEditApplicantInfo" title="Applicant" information={applicant} excludeKeys={["income", "age", "isIncomeEligible"]} onEdit={() => gotoRoute(locations.index)} />}

			{housing && <ApplicationReviewInfoCard testElementName="btnEditHousingInfo" title="Housing" information={housing} files={application.documents.rentProof.files} onEdit={() => gotoRoute(locations.housing)} />}

			{householdMembers.length > 0 &&
				<ItemList testEditElementName="btnEditHousehold" largeHeading title={"Household Members"} items={householdMembers} onEdit={() => gotoRoute(locations.household)}>
					{(item, index) => !isEmpty(item) ? <ApplicantContactLayout memberIndex={index} contact={item} /> : <></>}
				</ItemList>
			}
			{housing && application.type !== ApplicationType.Standard && <ApplicationReviewInfoCard testElementName="btnEditBankInfo" title="Bank Information" information={application.documents.bankInfo} files={application.documents.eftDetails.files} onEdit={() => navigate(`/pages/application/${application.code}/${locations.documents}`) } />}

			{situation && <ApplicationReviewInfoCard testElementName="btnEditCircumstances" title="Your Circumstances" information={situation} onEdit={() => gotoRoute(locations.situation)} />}

			<Grid container justifyContent={"space-between"}>
				<Grid item>
					<SectionHeading>Income</SectionHeading>
				</Grid>
				<Grid item>
					<Button variant={"contained"} color={"secondary"} data-sel="btnEditIncome" onClick={() => gotoRoute(locations.income)}>Edit Income</Button>
				</Grid>
			</Grid>

			<Grid item {...fullWidth} className={styles.incomeContainer}>
				<Grid className={styles.incomeContainer}>
					<Grid item className={styles.incomeCardHeading}>
						<ApplicantAvatar fullName={applicant?.displayName} />
						<ApplicantContactName fullName={applicant?.displayName} />
					</Grid>
					<Grid item {...fullWidth}>
						<IncomeAssetsReviewCard income={applicant?.income}></IncomeAssetsReviewCard>
					</Grid>
				</Grid>
				{household?.adults.filter(a => a.isIncomeEligible).map((adult, adultIndex) => (
					adult.isIncomeEligible && (
						<div key={adultIndex}>
							<Grid className={styles.incomeContainer}>
								<Grid item sm={12} md={"auto"} className={styles.incomeCardHeading}>
									<ApplicantAvatar fullName={(adult as Adult)?.displayName} />
									<ApplicantContactName fullName={(adult as Adult)?.displayName} />
								</Grid>
								<Grid item {...fullWidth}>
									<IncomeAssetsReviewCard income={adult?.income}></IncomeAssetsReviewCard>
								</Grid>
							</Grid>
						</div>
					)
				))}
				{application.documents.incomeDocuments.required &&

					<div>
						<SectionSubHeading>Income Supporting Documents</SectionSubHeading>

						<FileUploader
							label="Please provide current proof of each source of income for ALL houshold members over the age of 22 years old."
							files={application.documents.incomeDocuments.files}
							error={application.documents.errorState.incomeDocuments}
							onAdd={(files) => addFiles(files, "incomeDocuments")}
							onRemove={(file) => removeFile(file, "incomeDocuments")}
						/>
					</div>
				}
			</Grid>

			<ContactConsensusCard				
				title="Contact(s)"
				{...contactConsensus}
				onEdit={() => gotoRoute(locations.contact)}
			/>

			<ItemList title={"Emergency Contacts"} items={[contacts?.emergencyContactOne, contacts?.emergencyContactTwo].filter(c => c && c.name && c.phone && c.relationship)}>
				{(item, index) => item ? <ApplicantContactLayout memberIndex={index} contact={item} /> : <></>}
			</ItemList>

			{contactPersons.length > 0 &&
				<ItemList largeHeading items={contactPersons}>
					{(item, index) => !isEmpty(item) ? <ApplicantContactLayout memberIndex={index} contact={item} /> : <></>}
				</ItemList>
			}

			<Conditions programs={application.programs}/>

			<Consent consent={appConsent} type={application.type} set={set} />

			<ApplicationPreviousNext application={application} previousText={previous?.text} onPrevious={() => previous.location} nextText={next.text} nextDisabled={!canSubmit} onNext={onNext} />
		</>
	);
};

const Consent = ({ consent, type, set }: { consent: ApplicationConsent, type: ApplicationType, set: (value: Partial<ApplicationConsent>) => void }) => {
	const validationMessage: Record<ApplicationType, string> = {
		[ApplicationType.Standard]: "Please review and authorize Civida to communicate with you by email if you would like to proceed with submitting this application.",
		[ApplicationType.AnnualReview]: "Please review the 2 items above to authorize Civida to communicate with you and to declare that the information provided in this annual review is true and complete to proceed with submission.",
		[ApplicationType.InterimReview]: "Your changes are ready for submission. Please review your details and return to any previous sections to make edits if required. If the details are accurate, please proceed to submission by clicking on the “Submit Changes” button.",
	};
	{
		switch (type) {
			case ApplicationType.InterimReview:
				return;
			default:
				return (<Grid container display={"flex"} flexDirection={"column"} gap={3}>
					<Grid item {...fullWidth}>
						<Stack direction={"row"}>
							<Switch data-sel="togConsent" color={"primary"} checked={consent.authorizeCommunication} onChange={e => set({authorizeCommunication: e.target.checked})}/>
							<Typography>I/we authorize Civida to communicate with me by email any correspondence, requests for information, or any documents as necessary under the Residential
								Tenancies Act and/or the Alberta Housing Act.</Typography>
						</Stack>
					</Grid>
					{type === ApplicationType.AnnualReview ? (
						<Grid item {...fullWidth}>
							<Stack direction={"row"}>
								<Switch data-sel="togUnderstandResponsibilities" color={"primary"} checked={consent.understandResponsibilities}
									onChange={e => set({understandResponsibilities: e.target.checked})}/>
								<div>
									<Typography>I/we declare that the information provided in this annual review is true and complete.</Typography>
									<ul>
										<li>A failure to respond to requests for additional information or documentation by Civida may result in my rent supplement being cancelled</li>
										<li>Providing false information to Civida may result in no longer being eligible for services</li>
										<li>It is my/our responsibility to keep Civida updated on any changes to my/our circumstances or the information provided in this annual income review</li>
									</ul>
								</div>
							</Stack>
						</Grid>) :	(<></>)}

					{((type === ApplicationType.AnnualReview && !consent.understandResponsibilities) || !consent.authorizeCommunication) && <Typography variant={"h6"} color={"red"} mt={2}>{validationMessage[type]}</Typography>}
				</Grid>
				);
		}
	}
};

export { Review };
