import { FC } from "react";
import {IncomeAssets, YesNo, fullWidth, oneQuarterWidth} from "library";
import { Grid, Typography, Card, CardContent, CardHeader, useTheme } from "@mui/material";

import styles from "styles/applicationReview.module.scss";

type IncomeAssetsReviewCardProps = {
	income?: IncomeAssets;
	onEdit?: () => void;
};
const IncomeAssetsReviewCard: FC<IncomeAssetsReviewCardProps> = ({ income }) => {
	const theme = useTheme();
	return (
		<>
			{income?.noticeOfAssessment === YesNo.Yes ? (
				<>
					<Card elevation={4} sx={{ marginTop: 2 }}>
						<CardContent sx={{ p: 4 }} >
							<Card sx={{ m: -4, textAlign: "left" }}>
								<CardHeader sx={{ p: 0.5, background: theme.palette.primary.main }} />

								<Grid item {...fullWidth}>
									<Typography variant="h4" sx={{ p: 3, paddingBottom: 0 }}>
										Notice of Assessment
									</Typography>
								</Grid>
								<Grid container justifyContent={"space-between"} sx={{ p: 3 }}>
									{income?.noticeOfAssessmentIncome.map((assessmentIncome, index) =>
										<div key={index}>
											<Grid container className={styles.incomeRow} spacing={3}>
												<Grid item >
													<Typography variant="h5">Income</Typography>
													<Typography>${Number(assessmentIncome.amount).toLocaleString()}</Typography>
												</Grid>
												<Grid item >
													<Typography variant="h5">Year</Typography>
													<Typography>{assessmentIncome.year}</Typography>
												</Grid>
												<Grid item >
													<Typography variant="h5">Highest Source of Income</Typography>
													<Typography>{assessmentIncome.source?.name}</Typography>
												</Grid>
											</Grid>
										</div>
									)}
								</Grid>
							</Card>
						</CardContent>
					</Card>
				</>
			) : (
				<>
					<Card elevation={4} sx={{ marginTop: 2 }}>
						<CardContent sx={{ p: 4 }} >
							<Card sx={{ m: -4, textAlign: "left" }}>
								<CardHeader sx={{ p: 0.5, background: theme.palette.primary.main }} />

								<Grid item {...fullWidth}>
									<Typography variant="h4" sx={{ p: 3, paddingBottom: 0 }}>
										Other Income
									</Typography>
								</Grid>
								<Grid container justifyContent={"space-between"} sx={{ p: 3 }}>
									{income?.otherIncome.map((incomeItem, index) =>
										<div key={index}>
											<Grid container className={styles.incomeRow} spacing={3} justifyContent={"space-between"}>
												<Grid item {...oneQuarterWidth}>
													<Typography variant="h5">Income</Typography>
													<Typography>${Number(incomeItem.amount).toLocaleString()}</Typography>
												</Grid>
												<Grid item {...oneQuarterWidth}>
													<Typography variant="h5">Frequency</Typography>
													<Typography>{incomeItem.frequency}</Typography>
												</Grid>
												<Grid item {...oneQuarterWidth}>
													<Typography variant="h5">Income Type</Typography>
													<Typography>{incomeItem.source?.name}</Typography>
												</Grid>
											</Grid>
										</div>
									)}
								</Grid>
							</Card>
						</CardContent>
					</Card>
				</>
			)}
		</>
	);



};

export { IncomeAssetsReviewCard };