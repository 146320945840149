import {Gender, RelationToApplicant, StudentStatus} from "../enums";
import {createErrorState, ErrorState, IErrorState} from "../errorState";
import {Address} from "../types";

interface IPerson {
	firstName: string;
	middleName: string;
	lastName: string;
	preferredName: string;
	gender: Gender | undefined;
	canadianCitizen?: boolean;
	dateOfBirth: Date;
	address?: Address;
	studentStatus?: StudentStatus;
	displayName: string;
	age: number;
}

class Person implements Partial<IPerson>, IErrorState<Partial<IPerson>> {
	readonly firstName?: string;
	readonly middleName?: string;
	readonly lastName?: string;
	readonly preferredName?: string;
	readonly gender?: Gender;
	readonly canadianCitizen?: boolean;
	readonly dateOfBirth?: Date;
	readonly address?: Address;
	readonly studentStatus?: StudentStatus;
	readonly age?: number;
	readonly isIncomeEligible?: boolean;
	protected readonly validated: boolean;
	
	constructor(from?: Partial<IPerson>, addressRequired = true) {
		this.firstName = from?.firstName;
		this.middleName = from?.middleName;
		this.lastName = from?.lastName;
		this.preferredName = from?.preferredName;
		this.gender = from?.gender;
		this.dateOfBirth = from?.dateOfBirth;
		this.canadianCitizen = from?.canadianCitizen;
		this.address = from?.address instanceof Address 
			? from.address
			: addressRequired ? new Address(from?.address) : undefined;
		this.studentStatus = (from?.studentStatus ?? "") in StudentStatus || Object.values(StudentStatus).some(v => v === from?.studentStatus) ? from?.studentStatus : undefined;
		this.age = from?.age;
		this.isIncomeEligible = (from?.age ?? 0) >= 22 && from?.studentStatus !== StudentStatus.fullTime;
		this.validated = (from as Person)?.validated ?? false;
	}
    
	get errorState(): ErrorState<Partial<IPerson>> {
		return this.validated
			? createErrorState<IPerson>({
				firstName: !this.firstName ? "First name required." : "",
				lastName: !this.lastName ? "Last name required." : "",
				gender: !this.gender ? "Gender required." : "",
				address: !this.address?.city ? "Required" : "",
				dateOfBirth: !this.dateOfBirth ? "Valid date required" : this.dateOfBirth > new Date() ? "Date cannot be in the future." : "",
				studentStatus: !this.studentStatus ? "Student status required." : ""
			}) : {};
	}
	
	get displayName(): string {
		return 	!this.preferredName
			? `${this.firstName} ${this.lastName}`
			: this.preferredName;
	}
	
	validate(): Person {
		return new Person({...this, validated: true});
	}
}

class HouseholdMember extends Person implements IErrorState<Partial<HouseholdMember>> {
	public readonly relationToApplicant?: RelationToApplicant;

	constructor(from?: Partial<HouseholdMember>, addressRequired = true) {
		super(from, addressRequired);
		this.relationToApplicant = from?.relationToApplicant;
	}

	override validate(): HouseholdMember {
		return new HouseholdMember({...this, ...super.validate()});
	}

	get errorState(): ErrorState<Partial<HouseholdMember>> {
		return this.validated ? createErrorState<HouseholdMember>({
			...super.validate().errorState,
			relationToApplicant: !this.relationToApplicant ? "Relationship to applicant required." : "",
		}) : {};
	}
}

class Dependant extends HouseholdMember implements IErrorState<Partial<HouseholdMember>> {
	public readonly isDependant = true;
	
	constructor(from?: Partial<Dependant>) {
		super({...from, address: undefined}, false);
	}
	
	validate(): Dependant {
		return new Dependant({...this, ...super.validate()});
	}

	get errorState(): ErrorState<Partial<Dependant>> {
		return this.validated ? createErrorState<Dependant>({
			...super.validate().errorState,
			address: ""
		}) : {};
	}
}

const ADULT_RELATIONSHIPS = Object.values(RelationToApplicant);

const DEPENDANT_RELATIONSHIPS = [
	RelationToApplicant.child,
	RelationToApplicant.grandchild,
	RelationToApplicant.sibling,
	RelationToApplicant.otherRelative,
	RelationToApplicant.other
];

export {Person, Dependant, HouseholdMember, ADULT_RELATIONSHIPS, DEPENDANT_RELATIONSHIPS};
export type { IPerson };