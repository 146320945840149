import {createSlice} from "@reduxjs/toolkit";
import {ApplicationDocuments, fileEquivalent, FileProcess, GbvApplication, GbvBuilder, IFileInfo, LoadStatus} from "library";
import api from "library/api";
import { createAppAsyncThunk } from "./appAsyncThunk";

const startNewApplication = createAppAsyncThunk("gbv/startNew", async (value: GbvBuilder) => {
	return new GbvBuilder(await api.Applications.create(value));
});

const loadWithCode = createAppAsyncThunk("gbv/get", async (code: string) => {
	return new GbvBuilder(await api.Applications.get<GbvBuilder>(code));
});

const saveDocuments = createAppAsyncThunk("gbv/saveDocuments", async (value: {property: keyof ApplicationDocuments, files: File[]}, { dispatch, getState }) => {
	const { gbvApplication: { builder: { code, documents} } } = getState();

	for (const file of value.files)
		await api.Applications.addFile(code, value.property, file);

	return dispatch(saveProgress({
		documents: new ApplicationDocuments({
			...documents,
			...{
				[value.property]: {
					required: true,
					files: (documents[value.property] as {files: IFileInfo[]}).files.map(f => ({name: f.name, size: f.size, type: f.type, state: FileProcess.Idle}))
				}
			}
		})
	}));
});

const removeDocuments = createAppAsyncThunk("gbv/removeDocuments", async (value: {property: keyof ApplicationDocuments, file: IFileInfo}, {dispatch, getState}) => {
	const { gbvApplication: { builder: { documents } } } = getState();

	return dispatch(saveProgress({
		documents: new ApplicationDocuments({
			...documents,
			...{
				[value.property]: {
					required: true,
					files: [...(documents[value.property] as {files: IFileInfo[]}).files]
						.filter(f => f.state !== FileProcess.Deleting)
						.filter(f => !fileEquivalent(f, value.file))
				}
			}
		})
	}));
});

const saveProgress = createAppAsyncThunk("gbv/save", (value: Partial<GbvApplication>, { getState }) => {
	const { gbvApplication: { builder: existingApplication } } = getState();
	const updatedApplication = new GbvBuilder({...existingApplication, ...value});
	void api.Applications.update(updatedApplication);
	return updatedApplication;
});

const combineStateFiles = (inState: IFileInfo[], newState: IFileInfo[]) => newState.map(f => {
	const existing = inState.find(inner => inner.name === f.name && inner.size === f.size && inner.type === f.type);
	return !existing
		? f
		: {...f, state: existing.state === FileProcess.Deleting ? FileProcess.Deleting : f.state};
});

const application = createSlice({
	name: "gbvState",
	initialState: {
		status: LoadStatus.Loaded,
		isStale: true,
		builder: new GbvBuilder()
	},
	reducers: {
		reset: () => ({status: LoadStatus.Loaded, isStale: true, builder: new GbvBuilder()}),
		set: (state, {payload}) => ({...state, builder: new GbvBuilder({...state.builder, ...payload})}),
		setStatus: (state, {payload}) => ({ ...state, builder: new GbvBuilder({...state.builder as GbvBuilder, status: payload})}),
		setDocuments: (state, {payload}) => ({ ...state, builder: new GbvBuilder({...state.builder as GbvBuilder, documents: payload})}),
	},
	extraReducers: builder => {
		builder.addCase(saveProgress.pending, (state, {meta: {arg}}) =>
			({ ...state, status: LoadStatus.Loaded, builder: new GbvBuilder({...state.builder as GbvBuilder, ...arg})}));
		builder.addCase(saveProgress.fulfilled, (state, { payload }) => {
			return ({
				isStale: false,
				status: LoadStatus.Loaded,
				builder: new GbvBuilder({
					...payload,
					documents: new ApplicationDocuments({
						bankInfo: payload.documents.bankInfo,
						rentProof: {...payload.documents.rentProof, files: combineStateFiles(state.builder.documents.rentProof.files, payload.documents.rentProof.files)},
						eftDetails: {...payload.documents.eftDetails, files: combineStateFiles(state.builder.documents.eftDetails.files, payload.documents.eftDetails.files)},
						incomeDocuments: {...payload.documents.incomeDocuments, files: combineStateFiles(state.builder.documents.incomeDocuments.files, payload.documents.incomeDocuments.files)},
						statusInCanada: {...payload.documents.statusInCanada, files: combineStateFiles(state.builder.documents.statusInCanada.files, payload.documents.statusInCanada.files)},
						other: {...payload.documents.other, files: combineStateFiles(state.builder.documents.other.files, payload.documents.other.files)},
						safety: {...payload.documents.safety, files: combineStateFiles(state.builder.documents.safety.files, payload.documents.safety.files)}
					})
				})
			});
		});
		builder.addCase(saveProgress.rejected, (state) =>
			({ ...state, status: LoadStatus.Loaded, builder: state.builder }));
		builder.addCase(startNewApplication.pending, (state) => ({...state, status: LoadStatus.Loading}));
		builder.addCase(startNewApplication.fulfilled, (_, { payload }) => ({isStale: false, status: LoadStatus.Loaded, builder: payload }));
		builder.addCase(loadWithCode.pending, (state) =>
			({ ...state, status: LoadStatus.Loading }));
		builder.addCase(loadWithCode.fulfilled, (_, { payload }) => ({
			isStale: false,
			status: payload ? LoadStatus.Loaded : LoadStatus.DoesNotExist,
			builder: new GbvBuilder(payload)
		}));
		builder.addCase(saveDocuments.pending, (state, {meta: {arg: {property, files}}}) => {
			return ({
				...state,
				builder: new GbvBuilder({
					...state.builder as GbvBuilder,
					documents: new ApplicationDocuments({
						...state.builder.documents,
						[property]: {
							required: true,
							files: [...(state.builder.documents[property] as {files: IFileInfo[]}).files, ...files.map(f => ({name: f.name, size: f.size, type: f.type, state: FileProcess.Uploading}))]
						}
					})
				})
			});
		});
		builder.addCase(removeDocuments.pending, (state, {meta: {arg: {property, file}}}) => {
			return ({
				...state,
				builder: new GbvBuilder({
					...state.builder as GbvBuilder,
					documents: new ApplicationDocuments({
						...state.builder.documents,
						[property]: {
							required: true,
							files: (state.builder.documents[property] as {files: IFileInfo[]}).files.map(f => fileEquivalent(f, file) ? {...f, state: FileProcess.Deleting} : f)
						}
					})
				})
			});
		});
	}
});

export default application.reducer;

export const {
	set,
	reset
} = application.actions;
export { startNewApplication, loadWithCode, saveDocuments, removeDocuments, saveProgress };