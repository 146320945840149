import { FormContainer } from "ui-component/extended/FormContainer";
import { Container } from "@mui/system";

import {ApplicationHeaderInfo, FormHeaderTextGroup} from "components/FormHeaderTextGroup";
import {PROVIDER_EMAIL, PROVIDER_PHONE} from "config";
import {useParams} from "react-router-dom";
import ClientRegistration from "./ClientRegistration";
import { useMemo } from "react";

const HeaderInfo: Record<string, ApplicationHeaderInfo> = {
	GBV: {
		title: "Create Account",
		subtitle: "",
		prompt: "Please enter your email address. This email address will be used to access the benefits portal.",
		message: <>
			Please create an account with your email address. The email address you provide will be your username to log into your Benefit Portal.
			Contact our Rent Supplement team at <a href={`mailto: ${PROVIDER_EMAIL}`}>{PROVIDER_EMAIL}</a> or {PROVIDER_PHONE} if you have any questions or concerns.
		</>
	},
	default: {
		title: "Create Account",
		subtitle: "Let's create and link your previous account",
		prompt: "This process links your previous account's history with your new system.",
		message: `Please create an account with your email address from the previous system. We will use this to lookup your history. Contact our Rent Supplement team at ${PROVIDER_EMAIL} or ${PROVIDER_PHONE} if you have any questions or concerns. `
	}
};

const RegistrationContainer = () => {
	const {type} = useParams();
	
	const headerInfo = useMemo(() => HeaderInfo[type?.toUpperCase() ?? "default"] ?? HeaderInfo.default, [type]);
	
	return (
		<Container sx={{ height: "100vh" }}>
			<FormHeaderTextGroup headerInfo={headerInfo} />
			<FormContainer>
				<ClientRegistration prompt={headerInfo.prompt!} />
			</FormContainer>
		</Container>
	);
};

export default RegistrationContainer;
