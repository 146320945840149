import styles from "styles/application.module.scss";

import {Stack} from "@mui/system";
import AnimateButton from "ui-component/extended/AnimateButton";
import {Button, Grid} from "@mui/material";
import {useCallback, useMemo} from "react";
import {ApplicationBuilder, ApplicationStatus, fullWidth} from "library";
import useNavigator from "hooks/useNavigator";

interface IApplicationPreviousNext<T extends ApplicationBuilder> {
	application: T;
	previousText?: string;
	onPrevious?: () => string | undefined | Promise<string | void>;
	nextText: string;
	nextDisabled?: boolean;
	onNext: () => string | undefined | Promise<string | void>;
}

const ApplicationPreviousNext = <T extends ApplicationBuilder>({application, previousText, onPrevious, nextText, nextDisabled, onNext}: IApplicationPreviousNext<T>) => {
	const navigate = useNavigator();
	
	const previouslySaved = useMemo(() => ![ApplicationStatus.Generated, ApplicationStatus.Draft].includes(application.status), [application.status]);
	const nextButtonText = useMemo(() => previouslySaved ? `SAVE AND ${nextText}` : nextText, [nextText, application.status]);
	
	const saveAndGoto = useCallback(async (pathOverride?: string) => {
		const nextPath = await onNext();
		if (!nextPath) return;
		
		navigate(pathOverride ?? nextPath, {relative: "path"});
	}, [navigate, onNext]);

	const goBack = useCallback(async () => {
		const previousPath = !!onPrevious && await onPrevious();
		if (!previousPath) return;

		navigate(previousPath, {relative: "path"});
	}, [navigate, onPrevious]);
	
	return (
		<Grid className={styles.navigationButtons} container>
			<Grid item {...fullWidth}>
				<Stack direction="row" justifyContent={previousText ? "space-between" : "flex-end"}>
					{previousText &&
						<AnimateButton>
							<Button variant={"contained"} color={"secondary"} onClick={goBack} data-sel="btnPrevious">{previousText}</Button>
						</AnimateButton>}
	
					<Stack direction={"row"} justifyContent={"flex-end"}>
						<AnimateButton>
							<Button variant={"contained"} onClick={() => saveAndGoto()} color={"secondary"} disabled={nextDisabled} data-sel="btNext">{nextButtonText}</Button>
						</AnimateButton>

						{previouslySaved &&
                            <AnimateButton>
	                            <Button className={styles.saveAndExit} variant={"contained"} onClick={() => saveAndGoto("/pages/dashboard")} data-sel={"btnSaveReturn"}>Save and Exit</Button>
                            </AnimateButton>
						}
					</Stack>
				</Stack>
			</Grid>
		</Grid>
	);
};

export {ApplicationPreviousNext};