import style from "styles/conditions.module.scss";
import {Box, Grid, Typography} from "@mui/material";
import {fullWidth, ProgramApplications} from "library";

const Conditions = ({programs}: {programs:ProgramApplications | undefined}) => {
	const hasTrab = programs?.programs?.has("TRAB");

	return(
		<>
			<Grid container item spacing={3} {...fullWidth}>
				{hasTrab ?
					(<Grid item>
						<Typography variant={"h3"}>By submitting your application, you acknowledge the terms and conditions of the Rent Assistance  and Temporary Rent Benefit Programs.</Typography>
					</Grid>)
						 : (<Grid item>
							 <Typography variant={"h3"}>By submitting your application, you acknowledge the terms and conditions of the Rent Assistance Benefit Program.</Typography>
					</Grid>)
				}
				<Grid item className={style.panel} >
					<Typography className={style.panelLabel}>Rent Assistance Benefit Terms and Conditions</Typography>
					<Box className={style.text}>
						<Typography>
							<ul>
								<li>The Rent Assistance Benefit shall be used towards the payments of rent for the premises listed above.</li>
								<li>The Recipient shall provide the Administrator with information and/or documentation as required for the purposes of annual income verification and determining continued eligibility, including, but not limited to, proof that the Recipient remains a resident in the premises listed above.</li>
								<li>If the Recipient receives Rent Assistance Benefit that they are not entitled, the Recipient will repay that money when asked by the Administrator by offsetting future Rent Assistance Benefit payments unless the Recipient is able to pay the amount in full immediately.</li>
								<li>The Recipient authorizes the Administrator to make contact with the Recipient’s landlord for the purposes of confirming:</li>
								<ul className={style.plainList}>
									<li>i. The type of housing accommodation,</li>
									<li>ii. The number of people living in the housing accommodation,</li>
									<li>iii. The Recipient’s tenancy</li>
									<li>iv. The monthly rent amount and utility payment arrangements, and</li>
									<li>v. If the rent is being paid.</li>
								</ul>
								<li>The Administrator will stop providing Rent Assistance Benefit to the Recipient if:</li>
								<ul className={style.plainList}>
									<li>i. The Recipient does not provide the required information in the Annual Income Review form, or any additional information requested by the Administrator to assess the Recipient’s continued eligibility for the Rent Assistance Benefit Program.</li>
									<li>ii. The Recipient provides false or misleading information or fails to report income or other information required on the Application form any subsequent Annual Income Review form.</li>
									<li>iii. The Recipient moves without informing the Administrator or if the Recipient moves outside the area the Administrator provides Rent Assistance Benefit.</li>
									<li>iv. The Recipient, without informing the Administrator, moves another person into their housing.</li>
									<li>v. The Recipient moves into housing that is ineligible. Ineligible housing includes shared accommodations with the landlord and basement or secondary suites that are not legally approved by the municipality.</li>
									<li>vi. The Recipient is no longer eligible for the Rent Assistance Benefit Program under the Alberta Housing Act and its Regulations.</li>
									<li>vii. The Recipient does not use the Rent Assistance Benefit Program amount agreed to above towards payment of their rent.</li>
									<li>viii. Public funding for the Rent Assistance Benefit Program is cancelled.</li>
								</ul>
							</ul>
						</Typography>
					</Box>
				</Grid>
				{hasTrab ? (
					<>
						<Grid item {...fullWidth} className={style.panel} >
							<Typography className={style.panelLabel}>Temporary Rent Assistance Benefit Terms and Conditions</Typography>
							<Box className={style.text}>
								<Typography>
									<ul>
										<li>The Administrator reserves the right to end this Temporary Rent Assistance Benefit Agreement for the following reasons:</li>
										<ul className={style.plainList}>
											<li>i. The Recipient provides false or misleading information or fails to report income or other information required on the Application form for Temporary
												Rent Assistance Benefit or any subsequent Annual Income Review form.
											</li>
											<li>ii. The Recipient moves, or someone moves in with the recipient without informing the Administrator, or the Recipient moves outside the city limits of
												the City of Edmonton where the Administrator provides Temporary Rent Assistance Benefit assistance
											</li>
											<li>iii. The Recipient fails to provide to the Administrator, the Annual Income Review form or any additional information required by the Administrator to
												assess the Recipient’s continued eligibility for the Temporary Rent Assistance Benefit Program.
											</li>
											<li>iv. The Recipient begins to receive Income Support or the Assured Income for the Severely Handicapped (AISH).</li>
											<li>v. Public funding for the Temporary Rent Assistance Benefit Program is cancelled.</li>
											<li>vi. The Recipient is no longer eligible for the Program under the Alberta Housing Act and its Regulations.</li>
										</ul>
									</ul>
								</Typography>
							</Box>
						</Grid>
					</>
				) : (<></>)}
			</Grid>
		</>
	);
};

export {Conditions};
