import {Grid} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {DateValue} from "library/DateValue";
import {FC, useMemo} from "react";

interface Props {
	value: Date | DateValue | undefined;
	label?: string;
	error?: string;
	columnWidth?: {xs: number, sm: number, md: number};
	onChange: (value: Date | undefined) => void;
	disableFuture?: boolean;
	testElementName?: string;
	disabled?: boolean;
}

const GridDatePicker: FC<Props> = ({ value, label, error, columnWidth, onChange, disableFuture, testElementName, disabled }) => {
	const dateValue = useMemo(() => DateValue.From(value), [value]);
	const today = new DateValue(new Date());
	const internalError = ((disableFuture && dateValue?.value) && dateValue.value > today.value) ? "Date cannot be in the future." : error ?? ""; 

	const conditionalInputProps = testElementName ? { "data-sel": testElementName } : {};

	const input = useMemo(() => {		
		const baseProps = (internalError || error)
			? { fullWidth: true, helperText: internalError ?? error, error: true }
			: { fullWidth: true, error: false };

		return {
			textField: {
				...baseProps,
				inputProps: conditionalInputProps,
			}
		};
	}, [internalError, error, conditionalInputProps]);
	
	
	return (
		<Grid item {...columnWidth} >
			<DatePicker
				label={label}
				value={dateValue?.value ?? " "}
				disableFuture={disableFuture ?? false}
				onChange={v => onChange(DateValue.From(v)?.toDate())}
				slotProps={input}
				disabled={disabled}
			/>
		</Grid>
	);
};

export { GridDatePicker };