import {useCallback} from "react";
import {SectionSubHeading} from "ui-component/SectionHeading";
import {Grid} from "@mui/material";
import {CalculateAge, fullWidth, GbvApplicant, Gender, oneThirdWidth} from "library";
import {AppSelectList, GridDatePicker, GridText} from "components";

type ApplicantInformationSectionProps = {
	applicant: GbvApplicant;
	onChange?: (applicant: Partial<GbvApplicant>) => void;
};

const ApplicantInformationSection = ({applicant, onChange}: ApplicantInformationSectionProps) => {
	const set = useCallback((value: Partial<GbvApplicant>) => {
		onChange?.(value);
	}, [onChange]);

	return (
		<Grid container spacing={3}>
			<Grid item {...fullWidth}>
				<SectionSubHeading>Personal Information</SectionSubHeading>
			</Grid>
			<GridText testElementName="tbFirstName" value={applicant.firstName} error={applicant.errorState.firstName} label={"First Name *"} autocomplete={"given-name"} columnWidth={oneThirdWidth} onChange={v => set({ firstName: v })} />
			<GridText testElementName="tbMiddleName" value={applicant.middleName} error={applicant.errorState.middleName} label={"Middle Name"} columnWidth={oneThirdWidth} onChange={v => set({ middleName: v })} />
			<GridText testElementName="tbLastName" value={applicant.lastName} error={applicant.errorState.lastName} label={"Last Name *"} columnWidth={oneThirdWidth} onChange={v => set({ lastName: v })} />
			<GridText testElementName="tbPreferredName" value={applicant.preferredName} error={applicant.errorState.preferredName} label={"Preferred Name"} columnWidth={oneThirdWidth} onChange={v => set({ preferredName: v })} />
			<Grid item {...oneThirdWidth}>
				<AppSelectList
					testElementName="ddlGender"
					label='Gender *'
					value={applicant.gender ?? ""}
					items={Object.values(Gender)}
					error={applicant.errorState.gender}
					onChange={v => set({ gender: v as Gender })}
				/>
			</Grid>
			<GridDatePicker disableFuture
							testElementName="tbBirthDate"
							value={applicant.dateOfBirth}
							error={applicant.errorState.dateOfBirth}
							label={"Date of Birth *"}
							columnWidth={oneThirdWidth}
							onChange={v => set({ dateOfBirth: v, age: CalculateAge(v) })}
			/>
			<GridText
				testElementName="tbSin"
				value={applicant.socialInsuranceNumber}
				error={applicant.errorState.socialInsuranceNumber}
				label={"Social Insurance Number *"}
				columnWidth={oneThirdWidth}
				onChange={v => set({ socialInsuranceNumber: v })}
			/>
		</Grid>
	);
};

export {
	ApplicantInformationSection
};