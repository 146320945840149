import {Circumstances, GbvBuilder, Housing, ProgramDetails} from "library";
import {useCallback, useState} from "react";
import {SectionHeading} from "ui-component/SectionHeading";
import { PersonalDetailsSection } from "./PersonalDetails.Section";
import {ReferralSection} from "./ReferralSection";
import {hasErrors} from "library/errorState";
import {useNavigate} from "react-router-dom";
import {ApplicationPreviousNext} from "../application/ApplicationPreviousNext";
import {ProgramSpecificsSection} from "./ProgramSpecifics.Section";
import {useApplication} from "hooks";

const isCircumstances = (value: any): value is Circumstances => Object.keys(value ?? {}).some(k => Object.keys(new Circumstances(value)).includes(k));

const IntakeSection = () => {
	const navigate = useNavigate();
	const {application, mutations: {saveAsync, set}} = useApplication<GbvBuilder>();
	const [state, setState] = useState(new GbvBuilder(application));
	
	const onChangePersonalDetails = (value: Partial<Circumstances> | Partial<Housing>) => {
		isCircumstances(value)
			? setState(new GbvBuilder({...state, circumstances: new Circumstances({...state.circumstances, ...value})}))
			: setState(new GbvBuilder({...state, housing: new Housing({...state.housing, ...value})}));
	};
	
	const onChangeProgramDetails = (value: Partial<ProgramDetails>) => {
		setState(new GbvBuilder({...state, programDetails: new ProgramDetails({...state.programDetails, ...value})}));	
	};

	const onNext = useCallback(() => {
		const validated = state.validate();
		setState(validated);

		if (hasErrors(validated))
			return;

		void saveAsync(validated);
		return "../review";
	}, [state, saveAsync, set, navigate]);
	
	return (<>
		<SectionHeading>GBV Circumstances</SectionHeading>

		<PersonalDetailsSection circumstances={state.circumstances ?? new Circumstances()} housing={state.housing ?? new Housing()} onChange={onChangePersonalDetails} />
		<ReferralSection programDetails={state.programDetails ?? new ProgramDetails()} onChange={onChangeProgramDetails} />
		<ProgramSpecificsSection programDetails={state.programDetails ?? new ProgramDetails()} onChange={onChangeProgramDetails} />
		
		<ApplicationPreviousNext application={application}
		                         previousText={"Back to Circumstances"}
		                         onPrevious={() => "../situation"}
		                         nextText={"Next to Review"}
		                         onNext={onNext} />
	</>);
};

export {
	IntakeSection
};