import { FormControl, Select, MenuItem, FormHelperText, Autocomplete, TextField, InputLabel } from "@mui/material";
import { controlMinWidth } from "library";
import { FC } from "react";

interface Props {
	label: string;
	value: string | undefined;
	items: string[];
	autocomplete?: boolean;
	error: string | undefined;
	onChange: (value: string | undefined, index?: number) => void;
	required?: boolean;
	testElementName?: string;
}

export const AppSelectList: FC<Props> = ({ label, value, items, autocomplete, required, error, onChange, testElementName }) => {
	/** Manually handle autoselect on blur because MUI does not treate case insensitive changes as "change" */
	const fuzzyFind = (value: string | null | undefined) => {
		if (!value) return;
		return items.find((v) => v == value) ?? items.find((v) => v.trim().toLowerCase().includes(value?.trim().toLowerCase() ?? ""));
	};

	const conditionalInputProps = testElementName ? { "data-sel": testElementName } : {};

	return (
		<FormControl fullWidth error={!!error} style={{ minWidth: controlMinWidth }}>
			{autocomplete
				? <Autocomplete
					disablePortal
					options={items}
					getOptionLabel={(option) => option}
					onChange={(_, v) => onChange(fuzzyFind(v))}
					onBlur={() => onChange(fuzzyFind(value))}
					inputValue={value ?? ""}
					renderInput={(params) => (
						<TextField
							{...params}
							inputProps={{
								...params.inputProps,
								...conditionalInputProps
							}}
							error={!!error}
							label={label.concat(required ? " *" : "")}
							autoComplete="false"
							onChange={(e) => onChange(e.target.value)}
						/>
					)}
					clearOnBlur
					autoHighlight
				/>
				: <>
					<InputLabel style={{ minWidth: controlMinWidth }}>{label}</InputLabel>
					<Select value={value && items.includes(value) ? value : ""} label={label} onChange={e => onChange(e.target.value)} style={{ textAlign: "left", minWidth: controlMinWidth }}>
						{items.map((item, index) =>
							<MenuItem key={index} value={item}>{item}</MenuItem>
						)}
					</Select>
				</>
			}
			<FormHelperText>{error}</FormHelperText>
		</FormControl>
	);
};