import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { fullWidth } from "library";
import { HousingBody } from "library/civida/managedCities";
import { ACTIVE } from "library/civida/constants";

const IntakeCityContactOtherHMBInformation: FC<{ city: string | undefined, housingBody: HousingBody | undefined }> = ({ city, housingBody }) => {
	if (!city || !housingBody || housingBody.status === ACTIVE)
		return <></>;

	return (
		<Grid item {...fullWidth}>
			<Typography variant="h3" gutterBottom>
				{`Please contact ${housingBody.name} if you are looking to live in ${city}.`}
			</Typography>
		</Grid>
	);
};

export { IntakeCityContactOtherHMBInformation };
