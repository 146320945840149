import { BannerPalette } from "@mui/material/styles/createPalette";
import { ApplicationType } from "library";
import {Theme} from "@mui/material/styles";

type HeaderBackgroundProps = {
    forType?: ApplicationType | "GBV";
	palette: Theme;
};

const HeaderBackground = ({forType, palette}: HeaderBackgroundProps) => {
	const theme: Partial<Record<ApplicationType | "GBV" | "default", BannerPalette>> = {
		GBV: palette.palette.banners?.gbv,
		[ApplicationType.InterimReview]: palette.palette.banners?.interimReview,
		[ApplicationType.AnnualReview]: palette.palette.banners?.annualReview,
		default: palette.palette.banners?.standard
	};
	
	const colors = theme[forType ?? "default"] ?? theme.default;

	return (
		<svg width="100%" height="100%" viewBox="0 0 50 200" xmlns="http://www.w3.org/2000/svg">
			<rect width={"5"} height="200" fill={colors?.left ?? "#6c9030"} />
			<rect x={"5"} width={"44"} height="200" fill={colors?.main ?? "#e3f68b"} />
			<rect x={"49"} width={"1"} height="200" fill={colors?.right ?? "#963373"} />
		</svg>
	);
};

export {
	HeaderBackground
};