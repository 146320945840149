import {Application, ApplicationBuilder} from "./applicationBuilder";
import {Circumstances} from "./circumstances";
import {createErrorState, ErrorState, hasErrors} from "../errorState";
import {ProgramDetails} from "./programDetails";
import {ApplicantInformation, IApplicantInformation} from "./applicantInformation";
import {Housing} from "./housing";
import {ISituation, Situation} from "./situation";
import {ProgramApplications} from "./programApplications";

class GbvBuilder extends ApplicationBuilder {
	readonly circumstances?: Circumstances;
	readonly programDetails?: ProgramDetails;
	
	constructor(from?: Partial<GbvApplication>) {
		super({...from, programs: new ProgramApplications(["GBV"])});
				
		(this as any).applicant = new GbvApplicant(from?.applicant);
		(this as any).situation = new GbvSituation(from?.situation);
		(this as any).housing = new GbvHousing(from?.housing);
		this.circumstances = new Circumstances(from?.circumstances);	
		this.programDetails = new ProgramDetails(from?.programDetails);
		
		if (this.validated) {
			this.circumstances = this.circumstances.validate();
			this.programDetails = this.programDetails.validate();
			(this as any).documents = this.documents.validate(true);
		}
	}

	build(): GbvApplication | undefined {
		return !hasErrors(this.validate())
			? new GbvApplication(this)
			: undefined;
	}

	validate(): GbvBuilder {
		return new GbvBuilder({...this, validated: true});
	}

	get isGbv(): boolean
	{
		return true;
	}
}

class GbvApplicant extends ApplicantInformation {
	constructor(from?: Partial<GbvApplicant>) {
		super(from);
		(this as any).dateOfBirth = from?.firstName ? this.dateOfBirth : undefined;
		(this as any).citizenship = undefined;
		(this as any).isIncomeEligible = undefined;
	}

	validate() {
		return new GbvApplicant({...this, validated: true});
	}
	
	get errorState(): ErrorState<Partial<IApplicantInformation>> {
		return this.validated ? createErrorState({
			...super.validate().errorState,
			studentStatus: undefined,
		}) : {};
	}
}

class GbvSituation extends Situation {
	constructor(from?: Partial<Situation>) {
		super(from);
	}
	
	validate(): GbvSituation {
		return new GbvSituation({...this, validated: true});
	}
	
	get errorState(): ErrorState<Partial<ISituation>> {
		return this.validated ? createErrorState({
			...super.validate().errorState,
			overcrowded: "",
			currentlyEmployed: "",
			training: ""
		}) : {};
	}
}

class GbvHousing extends Housing {
	constructor(from?: Partial<GbvHousing>) {
		super(from);

		(this as any).monthlyUtilities = undefined;
		(this as any).bedrooms = undefined;
	}
	
	validate() {
		return new GbvHousing({...this, validated: true});
	}

	get errorState(): ErrorState<Partial<Housing>> {
		return this.validated ? createErrorState<Housing>({
			...super.validate().errorState,
			bedrooms: undefined,
			electricity: undefined,
			heating: undefined,
			water: undefined,
			liveInAide: undefined,
			monthlyRent: undefined,
			monthlyUtilities: undefined
		}) : {};
	}
}

class GbvApplication extends Application {
	readonly circumstances: Circumstances;
	readonly programDetails: ProgramDetails;
	
	constructor(from: GbvBuilder) {
		super(from);
		
		this.circumstances = from.circumstances!;
		this.programDetails = from.programDetails!;
	}
}

export {GbvBuilder, GbvApplication, GbvSituation, GbvHousing, GbvApplicant};