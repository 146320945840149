import Loadable from "ui-component/Loadable";
import {createElement, FunctionComponent, lazy, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {Review} from "views/pages/application/Review";
import {Adult, ApplicationContent, ApplicationStatus, ApplicationType, isEmpty} from "library";
import {ApplicationLoader} from "views/pages/application/ApplicationLoader";
import {SupportingDocumentsForm} from "views/pages/application/SupportingDocuments";
import {useApplication} from "./useApplication";
import useNavigator from "./useNavigator";
import {saveProgress} from "store/slices/application";
import {dispatch} from "store";

const ApplicantInformationForm = Loadable(lazy(() => import("views/pages/application/Applicant").then(module => ({default: module.ApplicantForm}))));
const HousingForm = Loadable(lazy(() => import("views/pages/application/Housing").then(module => ({default: module.HousingForm}))));
const HouseholdForm = Loadable(lazy(() => import("views/pages/application/Household").then(module => ({default: module.HouseholdForm}))));
const SituationForm = Loadable(lazy(() => import("views/pages/application/Situation").then(module => ({default: module.ApplicationSituationForm}))));
const IncomeAssetsForm = Loadable(lazy(() => import("views/pages/application/IncomeAssets").then(module => ({default: module.IncomeAssetsForm}))));
const ContactsForm = Loadable(lazy(() => import("views/pages/application/Contacts").then(module => ({default: module.ContactsForm}))));
const Submission = Loadable(lazy(() => import("views/pages/application/Submission").then(module => ({default: module.Submission}))));
const ReviewSubmission = Loadable(lazy(() => import("views/pages/application/ReviewSubmission").then(module => ({ default: module.ReviewSubmission }))));
const SupportingDocumentSubmissionResult = Loadable(lazy(() => import("views/pages/application/SupportingDocumentsSubmitted").then(module => ({ default: module.SupportingDocumentsSubmitted }))));

const locations = {
	index: "",
	housing: "housing",
	household: "household",
	situation: "situation",
	income: "income",
	documents: "documents",
	contact: "contact",
	review: "review",
	submission: "submission",
	dashboard: "dashboard",
	docsubmission: "docsubmission",
	reviewOffer: "offer"
};

export interface RouteStep {
    label: string;
    completed: boolean;
	location: string;
    route: {
        path: string;
        element: ReactElement; 
    };
	applicationTypes: ApplicationType[]
}

const createLoadingElement = (type: FunctionComponent<any>, props?: any, ...children: ReactNode[]) => {
	return createElement(ApplicationLoader, undefined, createElement(type, props, children));
};

export default function useApplicationRoutes() {
	const { application } = useApplication();
	const navigate = useNavigator();

	const lookupParam = ":code";
	const [reviewScreenVisited, setReviewScreenVisited] = useState(false);
	
	useEffect(() => {
		if (location.pathname.includes(locations.review))
			setReviewScreenVisited(true);
	}, [location.pathname]);


	const steps: RouteStep[] = useMemo(() => [
		{
			label: "Applicant", 
			completed: true, 
			location: locations.index,
			route: {
				path: `${lookupParam}/${locations.index}`, 
				element: createLoadingElement(ApplicantInformationForm, {next: {text: "NEXT TO HOUSING", location: locations.housing}})
			},
			applicationTypes: [ApplicationType.Standard, ApplicationType.AnnualReview, ApplicationType.InterimReview]
		},
		{
			label: "Housing", 
			completed: !!application.housing, 
			location: locations.housing,
			route: {
				path: `${lookupParam}/${locations.housing}`, 
				element: createLoadingElement(HousingForm, {previous: {text: "BACK TO APPLICANT", location: `../${locations.index}`}, next: {text: "NEXT TO HOUSEHOLD", location: `../${locations.household}`}})
			},
			applicationTypes: [ApplicationType.Standard, ApplicationType.AnnualReview, ApplicationType.InterimReview]
		},
		{
			label: "Household", 
			completed: !!application.household && !application.household.adults.some(a => !isEmpty(new Adult(a).validate().errorState)), 
			location: locations.household,
			route: {
				path: `${lookupParam}/${locations.household}`, 
				element: application.type === ApplicationType.Standard
					? createLoadingElement(HouseholdForm, {previous: {text: "BACK TO HOUSING", location: `../${locations.housing}`}, next: {text: "NEXT TO YOUR CIRCUMSTANCES", location: `../${locations.situation}`}})
					: createLoadingElement(HouseholdForm, {previous: {text: "BACK TO HOUSING", location: `../${locations.housing}`}, next: {text: "NEXT TO INCOME", location: `../${locations.income}`}})
			},
			applicationTypes: [ApplicationType.Standard, ApplicationType.AnnualReview, ApplicationType.InterimReview]
		},
		{
			label: "Circumstances", 
			completed: !!application.situation,
			location: locations.situation,
			route: {
				path: `${lookupParam}/${locations.situation}`, 
				element: createLoadingElement(SituationForm, {previous: {text: "BACK TO HOUSEHOLD", location: `../${locations.household}`}, next: {text: "NEXT TO INCOME", location: `../${locations.income}`}})
			},
			applicationTypes: [ApplicationType.Standard]
		},
		{
			label: "Income",
			completed: !!application.applicant?.income,
			location: locations.income,
			route: {
				path: `${lookupParam}/${locations.income}`,
				element: application.type === ApplicationType.Standard
					? createLoadingElement(IncomeAssetsForm, {previous: {text: "BACK TO YOUR CIRCUMSTANCES", location: `../${locations.situation}`}, next: {text: "NEXT TO CONTACT(S)", location: `../${locations.contact}`}})
					 : createLoadingElement(IncomeAssetsForm, {previous: {text: "BACK TO HOUSEHOLD", location: `../${locations.household}`}, next: {text: "NEXT TO CONTACT(S)", location: `../${locations.contact}`}})
			},
			applicationTypes: [ApplicationType.Standard, ApplicationType.AnnualReview, ApplicationType.InterimReview]
		},
		{
			label: "Contact(s)",
			completed: !!application.contacts,
			location: locations.contact,
			route: {
				path: `${lookupParam}/${locations.contact}`,
				element: createLoadingElement(ContactsForm, {previous: {text: "BACK TO INCOME", location: `../${locations.income}`}, next: {text: "NEXT TO APPLICATION REVIEW", location: `../${locations.review}`}})
			},
			applicationTypes: [ApplicationType.Standard, ApplicationType.AnnualReview, ApplicationType.InterimReview]
		},
		{
			label: "Review",
			completed: reviewScreenVisited || application.status !== ApplicationStatus.Draft, // Once landed on Review screen, we will always show the checkmark and allowing navigational clicks on the side bar
			location: locations.review,
			route: {
				path: `${lookupParam}/${locations.review}`,
				element: createLoadingElement(Review, {previous: {text: "BACK TO CONTACT(S)", location: `../${locations.contact}`}, next: {text: `SUBMIT ${ApplicationContent[application.type].heading.toUpperCase()}`, location: `../${locations.submission}`}})
			},
			applicationTypes: [ApplicationType.Standard, ApplicationType.AnnualReview, ApplicationType.InterimReview]
		},
		{
			label: "Submission",
			completed: true,
			location: locations.submission,
			route: {
				path: `${lookupParam}/${locations.submission}`,
				element: application.type === ApplicationType.Standard ? createLoadingElement(Submission, {programName: "Rent Assistance Benefit"}) : createLoadingElement(ReviewSubmission)
			},
			applicationTypes: [ApplicationType.Standard, ApplicationType.AnnualReview, ApplicationType.InterimReview]
		}
	].filter( r => r.applicationTypes.includes(application.type)), [application]);
	
	const gotoStep = useCallback(async (index: number) => {
		dispatch(saveProgress(application.validate()));
		navigate(`/pages/application/${steps[index].route.path.replace(lookupParam, application.code)}`);
	}, [navigate, application, steps]);

	const gotoRoute = useCallback((route: string) => {
		const step = steps.find(s => s.route.path.match(route))!;
		navigate(`/pages/application/${step.route.path.replace(lookupParam, application.code)}`);
	}, [navigate, application, steps]);

	const isStepperRoute = useMemo(() => {
		const routeParts = location.pathname.split("/");
		const routeEnd = routeParts.pop();
		const parentRoute = routeParts.pop();
		
		return parentRoute === "application" || !routeEnd || steps.filter(s => ![locations.index, locations.submission].includes(s.location)).some(step => routeEnd?.endsWith(step.location));
	}, [location.pathname, steps]);


	return { 
		steps, 
		gotoStep, 
		gotoRoute, 
		locations, 
		isStepperRoute,
		routes: [
			...steps.map(s => s.route)
			, {
				path: `${lookupParam}/${locations.documents}`, 
				element: createLoadingElement(SupportingDocumentsForm)
			}
			, {
				path: `${lookupParam}/${locations.docsubmission}`,
				element: createLoadingElement(SupportingDocumentSubmissionResult)
			}
		]
	};
}