import React, { FC, useState } from "react";
import { controlMinWidth } from "library";
import { Grid } from "@mui/material";
import { MuiTelInput, matchIsValidTel, MuiTelInputInfo } from "mui-tel-input";

interface IPhoneProps {
	value: string;
	label?: string;
	error?: string;
	columnWidth: { xs: number, sm: number, md: number },
	testElementName?: string;
	onChange: (value: string | undefined) => void;
}

const GridPhone: FC<IPhoneProps> = ({ value, label, error, columnWidth, testElementName, onChange }) => {
	const [phone, setPhone] = useState<MuiTelInputInfo>();
	const [internalError, setError] = useState<string>();

	const inputProps = testElementName ? { "data-sel": testElementName } : {};

	return (
		<Grid item {...columnWidth} sx={{ minWidth: controlMinWidth }}>
			<MuiTelInput value={value} 
			             label={label}
			             forceCallingCode
			             preferredCountries={["CA"]}
			             variant={"outlined"}
			             defaultCountry={"CA"}
			             fullWidth
			             error={!!internalError || !!error}
			             helperText={internalError ? internalError : error}
			             inputProps={inputProps}
			             onChange={(v, d) => {
							 setPhone(d);
							 onChange(v);
							 setError(undefined);
						 }}				
			             onBlur={() => {
							 if (!phone?.nationalNumber) onChange(undefined);
							 setError(!phone?.nationalNumber || matchIsValidTel(value, "CA") ? "" : "Invalid phone number");
						 }} />
		</Grid>
	);
};

export {
	GridPhone
};