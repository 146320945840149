
// routing
import Routes from "routes";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import Snackbar from "ui-component/extended/Snackbar";
import ThemeCustomization from "themes";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {FronteggProvider as AuthProvider} from "@frontegg/react";
// ==============================|| APP ||============================== //

const tenantResolver = () => {
	return {
		tenant: process.env.REACT_APP_AUTH_TENANT
	};
};

const contextOptions = {
	baseUrl:  process.env.REACT_APP_AUTH_BASE_URL ?? "",
	clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? "",
	appId: process.env.REACT_APP_AUTH_APP_ID ?? "",
	keepalive: true,
	tenantResolver
};

const App = () => {	
	return (
		<ThemeCustomization>
			<Locales>
				<NavigationScroll>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<AuthProvider contextOptions={contextOptions} hostedLoginBox>
							<Routes />
							<Snackbar />
						</AuthProvider>
					</LocalizationProvider>
				</NavigationScroll>
			</Locales>
		</ThemeCustomization>
	);
};

export default App;
