import { FC } from "react";
import { Button, Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@mui/material";
import { SectionHeading } from "ui-component/SectionHeading";

type ContactConsensusCardProps = {
	title: string;	
	requireFollowup: boolean;
	testElementName?: string;
	onEdit?: () => void;
}

const ContactConsensusCard: FC<ContactConsensusCardProps> = ({ title, requireFollowup, onEdit }) => {
	const theme = useTheme();
	const buttonLabel = `Edit ${title}`.toUpperCase();

	return (
		<>
			<Grid container justifyContent={"space-between"}>
				<Grid item>
					<SectionHeading>{title}</SectionHeading>
				</Grid>
				<Grid item xs={"auto"} mb={2}>
					<Button variant={"contained"} color={"secondary"} onClick={onEdit} data-sel="btnEditContacts">{buttonLabel}</Button>
				</Grid>
			</Grid>
			<Card elevation={4} sx={{ mt: 2, mb: 6 }}>
				<CardContent sx={{ p: 4 }}>
					<Card sx={{ m: -4 }}>
						<CardHeader sx={{ p: 0.5, background: theme.palette.primary.main }} />
						<Grid container spacing={0} sx={{ p: 2 }}>
							<Grid item xs={12} sm={3} sx={{[theme.breakpoints.up("xs")]: { mb: 0 }, [theme.breakpoints.up("sm")]: { mb: 3 }}}>
								<Typography variant="h5">
									Other Contact Consent
								</Typography>
							</Grid>
							<Grid item xs={3} sx={{[theme.breakpoints.up("xs")]: { mb: 3 }, [theme.breakpoints.up("sm")]: { mb: 0 }}}>
								<Typography variant="body2">
									{requireFollowup ? "Yes" : "No"}
								</Typography>
							</Grid>
						</Grid>
					</Card>
				</CardContent>
			</Card>
		</>
	);
};

export { ContactConsensusCard };
