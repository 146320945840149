import { FC } from "react";
import { Button, Grid } from "@mui/material";
import { filterObjectForInfoCard } from "utils/filterObjectForInfoCard";
import { ApplicantContactCardDetailItem } from "./ApplicationContactCard/ApplicantContactCardDetailItem";
import { NoticeOfAssessmentIncome } from "library/application/incomeBase";

type NoticeOfAssessmentIncomeAssetsCardProps = {
	testEditElementName?:string;
	testRemoveElementName?:string;

	noticeOfAssessment: Partial<NoticeOfAssessmentIncome>;
	onEdit?: () => void;
	onRemove?: () => void;
}

const NoticeOfAssessmentIncomeAssetsCard: FC<NoticeOfAssessmentIncomeAssetsCardProps> = (
	{ noticeOfAssessment, testEditElementName, testRemoveElementName, onEdit, onRemove }
) => (
	<Grid container spacing={0}>

		<Grid item xs={11}>
			<Grid container sx={{ pt: 1, pb: 1, pr: 3, justifyItems: "flex-end" }}>
				{
					onEdit && <Grid item sx={{ mt: -1, mr: 1 }}>
						<Button variant="outlined" onClick={onEdit} data-sel={testEditElementName}>Edit</Button>
					</Grid>
				}
				{
					onRemove && <Grid item sx={{ mt: -1, mr: -3 }}>
						<Button variant="outlined" onClick={onRemove} data-sel={testRemoveElementName}>Remove</Button>
					</Grid>
				}
			</Grid>
			<Grid item rowSpacing={2} container sx={{ pb: 2, textAlign: "left" }}>
				{
					filterObjectForInfoCard(noticeOfAssessment, ["name", "member", "annualamount", "validated"])
						.map(([k, v]) => <ApplicantContactCardDetailItem key={k} detailName={`${k}`} detailValue={`${v}`} />)
				}
			</Grid>
		</Grid>
	</Grid>
);

export { NoticeOfAssessmentIncomeAssetsCard };