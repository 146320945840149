import {createErrorState, ErrorState, IErrorState} from "../errorState";
import {IncomeFrequency} from "../enums";
import {IncomeType} from "../incomeTypes";

type IIncomeBase = {	
	amount: number,
	source: IncomeType,
}

class IncomeBase implements Partial<IIncomeBase>, IErrorState<Partial<IIncomeBase>> {
	public readonly amount?: number;
	public readonly source?: IncomeType;
	protected readonly validated: boolean;

	constructor(from?: Partial<IIncomeBase>) {
		this.amount = from?.amount ? Number(from.amount) : undefined;
		this.source = new IncomeType(from?.source);
		this.validated = (from as IncomeBase)?.validated ?? false;
	}
		
	get errorState(): ErrorState<Partial<IIncomeBase>> {
		return this.validated ?
			createErrorState<IIncomeBase>({				
				amount: !this.amount || isNaN(Number(this.amount)) ? "Please enter the amount." : "",
				source: !this.source ? "Please select a value." : "",
			}) : {};
	}

	validate(): IncomeBase {
		return new IncomeBase({ ...this, validated: true });
	}
}

class NoticeOfAssessmentIncome extends IncomeBase implements IErrorState<Partial<NoticeOfAssessmentIncome>> {
	public readonly year?: string;

	constructor(from?: Partial<NoticeOfAssessmentIncome>) {
		super(from);
		this.year = from?.year;
	}

	override validate(): NoticeOfAssessmentIncome {
		return new NoticeOfAssessmentIncome({ ...this, ...super.validate() });
	}

	get errorState(): ErrorState<Partial<NoticeOfAssessmentIncome>> {
		return this.validated ? createErrorState<NoticeOfAssessmentIncome>({
			...super.validate().errorState,
			year: !this.year || isNaN(Number(this.year)) ? "Please enter the year." : "",
		}) : {};
	}
}

class OtherIncome extends IncomeBase implements IErrorState<Partial<OtherIncome>> {
	public readonly frequency?: IncomeFrequency;

	constructor(from?: Partial<OtherIncome>) {
		super(from);
		this.frequency = from?.frequency;
	}

	override validate(): OtherIncome {
		return new OtherIncome({ ...this, ...super.validate() });
	}

	get errorState(): ErrorState<Partial<OtherIncome>> {
		return this.validated ? createErrorState<OtherIncome>({
			...super.validate().errorState,
			frequency: !this.frequency ? "Please select a value." : "",
		}) : {};
	}
	
	get annualTotal(): number {
		if (!this.amount) return 0;
		
		switch (this.frequency) {
			case IncomeFrequency.Weekly: return this.amount * 52;
			case IncomeFrequency.BiWeekly: return this.amount * 26;
			case IncomeFrequency.Monthly: return this.amount * 12;
			default: return this.amount;
		}
	}
}

export { IncomeBase, NoticeOfAssessmentIncome, OtherIncome };
export type { IIncomeBase };