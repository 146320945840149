import {useDispatch, useSelector} from "store";
import {getFeatureFlagsFor} from "store/slices/featureFlags";
import FeatureFlag from "../library/featureFlag";
import {useEffect} from "react";

const useFeatureFlags = (): FeatureFlag[] => {
	const dispatch = useDispatch();
	const {loading, featureFlags} = useSelector((s) => s.featureFlags);

	useEffect(() => {
		if(!loading) return;
		
		dispatch(getFeatureFlagsFor());
	}, []);
	
	return featureFlags;
};


export {useFeatureFlags};
