import {FC, useMemo} from "react";
import {ApplicationType, fullWidth, YesNo, YesNoSilent} from "library";
import {useDispatch} from "store";
import {Card, CardContent, CardHeader, Grid, Typography, useTheme} from "@mui/material";
import {YesNoButton} from "components/YesNoUnsureButton";
import {setPrograms} from "store/slices/application";
import {useApplication} from "hooks";

const Trab: FC = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { application: { situation, programs , type} } = useApplication();

	const currentlyEmployed = useMemo(() => situation?.currentlyEmployed === YesNoSilent.Yes, [situation]);
	const receivingOtherBenefits = useMemo(() => situation?.receivingOtherBenefits === YesNoSilent.Yes, [situation]);
	const metInitialConditions = useMemo(() => currentlyEmployed && !receivingOtherBenefits && type === ApplicationType.Standard, [situation]);

	if (!metInitialConditions) {
		return null;
	}

	return (
		<Card elevation={4} sx={{ marginTop: 2, marginBottom: 8 }}>
			<CardContent sx={{ p: 4 }}>
				<Card sx={{ m: -4, }}>
					<CardHeader sx={{ p: 0.5, background: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main }} />
					<Grid item {...fullWidth} sx={{ p: 3 }}>
						<Typography variant={"h5"}>
							Based on your application information, you can also apply for Temporary Rent Assistance Benefit (TRAB).
							Would you like to also apply for TRAB in addition to Rent Assistance Benefit (RAB)?
						</Typography>
						<Grid item sx={{ p: 1 }}></Grid>
						<Grid item xs={2}>
							<YesNoButton
								value={programs?.programs?.has("TRAB") ? YesNo.Yes : YesNo.No}
								onChange={v => dispatch(setPrograms(v == YesNo.Yes ? ["RAB", "TRAB"] : []))}
							/>
						</Grid>
					</Grid>
				</Card>
			</CardContent>
		</Card>
	);
};

export { Trab };