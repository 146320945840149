import { IApplicationDocuments, IFileInfo } from "library";
import { useDispatch } from "store";
import {saveDocuments as saveAppDocuments, removeDocuments as removeAppDocuments} from "store/slices/application";
import {saveDocuments as saveGbvDocuments, removeDocuments as removeGbvDocuments} from "store/slices/gbvApplication";
import {useParams} from "react-router-dom";
import {useMemo} from "react";
import {useApplication} from "./useApplication";

export const useFileUploaderActions = () => {
	const {code} = useParams();
	const dispatch = useDispatch();
	const {application} = useApplication();
	
	const [saveDocuments, removeDocuments] = useMemo(() => application.isGbv
		? [saveGbvDocuments, removeGbvDocuments] 
		: [saveAppDocuments, removeAppDocuments], [code]);
	
	const addFiles = async (files: File[], property: keyof IApplicationDocuments) => dispatch(saveDocuments({property, files}));
	const removeFile = (file: IFileInfo, property: keyof IApplicationDocuments) => dispatch(removeDocuments({property, file}));

	return { addFiles, removeFile };
};
