import { FC } from "react";
import { Button, Card, CardHeader, Grid, Typography, useTheme } from "@mui/material";
import { SectionHeading, SectionSubHeading } from "ui-component/SectionHeading";
import { toSentence } from "utils/toSentence";
import { InformationType, stringify, fullWidth, oneThirdWidth, IFileInfo } from "library";
import { PdfFile } from "./FileUploader";

type CardProps = {
	title: string;
	information?: InformationType;
	files?: IFileInfo[];
	excludeKeys?: string[];
	keyOverride?: Record<string, string>;
	testElementName?: string;
	onEdit?: () => void;
};

const ApplicationReviewInfoCard: FC<CardProps> = ({ title, information, files, excludeKeys, keyOverride, testElementName, onEdit }) => {
	const theme = useTheme();
	const buttonLabel = `Edit ${title}`.toUpperCase();

	const items = Object
		.entries(information ?? {})
		.filter(([key]) => !excludeKeys?.includes(key))
		.map(([key, value]) => ({
			label: (keyOverride && keyOverride[key]) ?? toSentence(key),
			value: stringify(key, value, information),
		}))
		.filter(({ value }) => Boolean(value));

	return (
		<>
			<Grid mb={-1} container justifyContent={"space-between"}>
				<Grid item>
					<SectionHeading>{title}</SectionHeading>
				</Grid>
				<Grid item xs={"auto"} mb={2}>
					<Button variant={"contained"} color={"secondary"} onClick={onEdit} data-sel={testElementName}>{buttonLabel}</Button>
				</Grid>
			</Grid>
			<Card elevation={4} sx={{ marginTop: 2, marginBottom: 8 }}>
				<CardHeader sx={{ p: 0.5, background: theme.palette.primary.main }} />
				<Grid container spacing={3} padding={3}>
					{items.map((item, index) =>
						<Grid item {...oneThirdWidth} sm={6} key={`key-${index}`}>
							<Typography variant="h5">{item.label}</Typography>
							<Typography variant="body2">{item.value}</Typography>
						</Grid>
					)}
					{items.length === 0 && <Grid item {...fullWidth} textAlign="center">None</Grid>}
				</Grid>

				{Boolean(files?.length) && (
					<Grid padding={3}>
						<SupportingDocumentsList files={files} />
					</Grid>
				)}
			</Card>
		</>
	);
};

const SupportingDocumentsList = ({ files }: { files?: IFileInfo[] }) => {
	const theme = useTheme();
	if (!files) return;

	return (
		<Grid container>
			<Grid item {...fullWidth}>
				<SectionSubHeading marginTop={1}>Supporting Documents</SectionSubHeading>
			</Grid>
			<Grid item {...fullWidth} display={"flex"} flexDirection={"column"} gap={2}>
				{files?.map((file, index) => (
					<Grid item {...fullWidth} border={1} padding={2} borderRadius={2} borderColor={theme.palette.primary.main} key={index}>
						<PdfFile file={file} />
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};


export { ApplicationReviewInfoCard, SupportingDocumentsList };
