import styles from "styles/itemList.module.scss";

import { isEmpty, oneThirdWidth } from "library";
import { PropsWithChildren, ReactElement } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Button, Card, CardHeader, Grid } from "@mui/material";
import { SectionHeading, SectionSubHeading } from "ui-component/SectionHeading";

interface Props<T> {
	title?: string;
	error?: string;
	errorItemText?: string;
	buttonText?: string;
	items: T[];
	largeHeading?: boolean;
	onAdd?: () => void;
	onEdit?: () => void;
	editButtonText?: string;
	disableAdd?: boolean;
	testAddElementName?: string;
	testEditElementName?: string;
	onRemove?: (item: T) => void;
	children: (item: T, index: number) => JSX.Element;
}

const ItemList: <T>(p: Props<T>) => ReactElement<Props<T>> = ({ title, error, errorItemText, buttonText, items, largeHeading, onAdd, onEdit, editButtonText, children, testAddElementName, testEditElementName, disableAdd = false }) => {
	const theme = useTheme();

	const editText = editButtonText ? editButtonText : `Edit ${title}`.toUpperCase();
	const inError = (item?: any) => error || (item && "errorState" in item && !isEmpty(item.errorState));
	
	return (
		<ThemeProvider theme={theme}>
			<Grid container mb={10} width={"100%"}>
				<Grid container justifyContent={"space-between"}>
					{title && (
						<Grid item {...oneThirdWidth}>
							{largeHeading
								? <SectionHeading marginTop={0} marginBottom={4}>{title}</SectionHeading>
								: <SectionSubHeading marginTop={0} marginBottom={4}>{title}</SectionSubHeading>
							}
						</Grid>
					)}
					{onAdd && !disableAdd && <Grid item xs={"auto"} mb={2}>
						<Button variant={"contained"} onClick={onAdd} data-sel={testAddElementName}>{buttonText?.toUpperCase() ?? "Add"}</Button>
					</Grid>}
					{onEdit && <Grid item xs={"auto"} mb={2}>
						<Button variant={"contained"} color={"secondary"} onClick={onEdit} data-sel={testEditElementName}>{editText}</Button>
					</Grid>}
				</Grid>
				<Grid container>
					{items.map((i, index) =>
						<ItemCard key={index}>
							{children(i, index)}
							{inError(i) && <span className={styles.errorText}>{error ?? errorItemText}</span>}
						</ItemCard>
					)}
					{!items.length && (
						<ItemCard>
							<span className={styles.none}>None</span>
							{inError() && <span className={styles.errorText}>{error}</span>}
						</ItemCard>
					)}
				</Grid>
			</Grid>
		</ThemeProvider>
	);
};

function ItemCard(props: PropsWithChildren) {
	const theme = useTheme();
	return (
		<Card elevation={4} className={styles.itemCard}>
			<CardHeader sx={{ p: 0.5, background: theme.palette.primary.main }} />
			<Grid container padding={3}>
				{props.children}
			</Grid>
		</Card>
	);
}

export { ItemList };