import { FC } from "react";
import { Grid, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ApplicantAvatar } from "ui-component/ApplicantAvatar";
import { ApplicantContactName } from "ui-component/ApplicantContactName";
import { filterObjectForInfoCard } from "utils/filterObjectForInfoCard";
import { ApplicantContactCardDetailItem } from "./ApplicantContactCardDetailItem";
import {Adult, ApplicantInformation, Contact, Dependant, stringify} from "library";

import styles from "styles/applicantCard.module.scss";

type ApplicationContactCardProps = {
	contact: ApplicantInformation | Adult | Dependant | Contact;
	error?: string;
	memberIndex: number;
	onEdit?: () => void;
	onRemove?: () => void;
}

const ApplicantContactLayout: FC<ApplicationContactCardProps> = ({ contact, error, memberIndex, onEdit, onRemove } ) => {

	const customAttributeName = `${contact.constructor.name}${memberIndex}`;
	const theme = useTheme();
	const defaultFilters = [
		"validated",
		"isDependant",
		"addressSameAsApplicant",
		"mailingAddressSameAsAddress",
		"income",
		"age",
		"displayName"
	];

	const filters = (() => {
		switch (true) {
			case (contact instanceof ApplicantInformation):
				return defaultFilters;
			case (contact instanceof Adult):
				return [...defaultFilters, "primaryPhone", "secondaryPhone"];
			case (contact instanceof Dependant):
				return [...defaultFilters, "primaryPhone", "address", "mailingAddress"];
			default:
				return defaultFilters;
		}
	})();

	const items = filterObjectForInfoCard(
		contact,
		filters
	).filter(([, v]) => !!v);
	return (
		<>
			<Grid container spacing={0}>
				<div className={styles.card}>
					<Grid item sm={12} md={"auto"} className={styles.cardHeading}>
						<ApplicantAvatar fullName={contact?.displayName}/>
						<ApplicantContactName fullName={contact?.displayName} />
					</Grid>
					<Grid container width={"auto"} gap={1}>
						{onEdit &&
							<Grid item sx={{ [theme.breakpoints.down("sm")]: {mt: -20, mb: 3, pr: 2}, [theme.breakpoints.up("sm")]: {mt: 0, mr: 1} }}>
								<Button variant="outlined" onClick={onEdit} data-sel={"btnEdit" + customAttributeName}>Edit</Button>
							</Grid>
						}
						{onRemove &&
							<Grid item sx={{ [theme.breakpoints.down("sm")]: {mt: -20, mb: 3}, [theme.breakpoints.up("sm")]: {mt: 0} }}>
								<Button variant="outlined" onClick={onRemove} data-sel={"btRemove" + customAttributeName}>Remove</Button>
							</Grid>
						}
					</Grid>
				</div>
				{items.map(([k, v], index) =>
					<ApplicantContactCardDetailItem key={index} detailName={k} detailValue={`${stringify(k, v)}`} />)
				}
			</Grid>
			{error}
		</>
	);
};

export { ApplicantContactLayout};