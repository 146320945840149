import { useEffect } from "react";
import { CP_PUB_KEY } from "config";
import {useDispatch, useSelector} from "store";
import {setCssLoaded, setScriptLoaded} from "store/slices/addressLookup";

const CP_JS_URL = "//ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js";
const CP_CSS_URL = "//ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css";

export const useCanadaPostExternalScript = () => {
	const dispatch = useDispatch();
	const {cssLoaded, scriptLoaded} = useSelector(s => s.addressLookup);

	const handleLoadError = (error: ErrorEvent) => {
		console.error("Error loading Canada Post scripts", error);
	};

	useEffect(() => {
		if (scriptLoaded) return;
		
		const script = document.createElement("script");
		script.addEventListener("load", () => dispatch(setScriptLoaded()));
		script.addEventListener("error", handleLoadError);
		script.src = `${CP_JS_URL}?key=${CP_PUB_KEY}`;
		script.async = true;

		document.body.appendChild(script);
	}, [scriptLoaded]);
	
	useEffect(() => {
		if (cssLoaded) return;
		
		const css = document.createElement("link");
		css.addEventListener("load", () => dispatch(setCssLoaded()));
		css.addEventListener("error", handleLoadError);
		css.rel = "stylesheet";
		css.type = "text/css";
		css.href = `${CP_CSS_URL}?key=${CP_PUB_KEY}`;

		document.body.appendChild(css);
	}, [cssLoaded]);

	return (scriptLoaded && cssLoaded);
};
