import styles from "styles/supportingDocuments.module.scss";

import {Button, Stack, Typography} from "@mui/material";
import {FileUploader} from "components/FileUploader";
import {useApplication} from "hooks";
import {useFileUploaderActions} from "hooks/useFileUploaderActions";
import {ApplicationDocuments,ApplicationType, IApplicationDocuments, IFileInfo, isEmpty} from "library";
import {setDocuments} from "store/slices/application";
import {submitApplicationDocuments, updateApplicationDocuments} from "store/slices/submission";
import {BankInformationForm} from "components/BankInformationForm";
import useApplicationRoutes from "hooks/useApplicationRoutes";
import useNavigator from "hooks/useNavigator";
import {useDispatch} from "store";
import { SectionHeading } from "ui-component/SectionHeading";

const SupportingDocumentsForm = () => {
	const dispatch = useDispatch();
	const {application} = useApplication();

	const bankInfo = application.documents.bankInfo ?? {};
	const { addFiles, removeFile } = useFileUploaderActions();
	const navigate = useNavigator();
	const locations = useApplicationRoutes();

	const submit = () => {
		const validated = new ApplicationDocuments({...application.documents, bankInfo}).validate(application.isGbv);
		dispatch(setDocuments(validated));
		if (!isEmpty(validated.errorState)) return;
		application.type === ApplicationType.Standard
			? dispatch(submitApplicationDocuments({ code: application.code, bankInfo: bankInfo }))
			: dispatch(updateApplicationDocuments({ code: application.code, bankInfo: bankInfo }));
		navigate(`../${locations.locations.docsubmission}`, {relative: "path"});
	};
	
	const addDocuments = (files: File[], property: keyof IApplicationDocuments) => void addFiles(files, property);
	
	const removeDocuments = (file: IFileInfo, property: keyof IApplicationDocuments) => void removeFile(file, property);
	
	if (!application || (!application.documents.hasRequiredDocuments && application.type === ApplicationType.Standard))
		return (
			<>
				<SectionHeading>Supporting Documents</SectionHeading>
				This application has no supporting document requirements.
			</>
		);

	const SupportingDocumentsEdited = () => {
		const navigate = useNavigator();
		const { application } = useApplication();

		return (
			<>
				<Stack direction={"row"} spacing={2}>
					<Button variant={"contained"} color={"secondary"} onClick={() => navigate("/pages/dashboard", {relative: "path"})}>BACK TO HOME</Button>
					<Button variant={"contained"} color={"secondary"} onClick={() => navigate(`/pages/application/${application.code}/review`, {relative: "path"})}>BACK TO REVIEW</Button>
				</Stack>
			</>
		);
	};

	return (
		<form>
			<SectionHeading>Supporting Documents</SectionHeading>

			{application.documents.rentProof.required &&
				<FileUploader label={"Rent Details"}
							  caption={<>
								  Please provide the lease agreement for your current rental residence or an upcoming lease agreement. Please also provide a current utility bill that matches the address of your lease agreement.
								  <br/><br/>
								  <span className={styles.bold}>EXAMPLE DOCUMENTS:</span> Lease / Rental Agreement, Utility Bills such as Epcor, Atco, Direct Energy, etc.
							  </>}
							  files={application.documents.rentProof.files}
							  error={application.documents.errorState.rentProof}
							  onAdd={files => addDocuments(files, "rentProof")}
							  onRemove={file => removeDocuments(file, "rentProof")}
				/>
			}

			{(application.documents.eftDetails.required || application.type !== ApplicationType.Standard) &&
				<BankInformationForm addDocuments={files => addDocuments(files, "eftDetails")} removeDocuments={file => removeDocuments(file, "eftDetails")} />
			}
			
			{application.documents.incomeDocuments.required &&
				<FileUploader label={"Income Document(s)"}
							  caption={<>
								  Please upload supporting documents to support your income information.
								  <br/><br/>
								  <span className={styles.bold}>EXAMPLE DOCUMENTS:</span> Pay stubs, AISH statements, CPP statements, employment letters, WCB statements, etc.
							  </>}
							  files={application.documents.incomeDocuments.files}
							  error={application.documents.errorState.incomeDocuments}
							  onAdd={files => addDocuments(files, "incomeDocuments")}
							  onRemove={file => removeDocuments(file, "incomeDocuments")}
				/>
			}

			{application.documents.statusInCanada.required &&
				<FileUploader label={"Status in Canada"}
							  caption={"Please upload your landing papers to support your status in Canada."}
							  files={application.documents.statusInCanada.files}
							  error={application.documents.errorState.statusInCanada}
							  onAdd={files => addDocuments(files, "statusInCanada")}
							  onRemove={file => removeDocuments(file, "statusInCanada")}
							  />
			}

			{application.documents.other.required &&
				<FileUploader label={"Other Documents"}
							  caption={"Please provide any supporting documentation that was requested to support your application."}
							  files={application.documents.other.files}
							  error={application.documents.errorState.other}
							  onAdd={files => addDocuments(files, "other")}
							  onRemove={file => removeDocuments(file, "other")}
							  />
			}

			{application.documents.safety.required &&
                <FileUploader label={"Safety Documents"}
                              caption={"Please provide any supporting documentation that was requested to support your application."}
                              files={application.documents.safety.files}
                              error={application.documents.errorState.safety}
                              onAdd={files => addDocuments(files, "safety")}
                              onRemove={file => removeDocuments(file, "safety")}
                />
			}

			<Typography variant={"h3"}>Have you uploaded all necessary supporting documents for your Rent Assistance Benefit application?</Typography>
			<Button variant={"contained"} className={styles.submitButton} color={"primary"} onClick={submit}>Document Submission Complete</Button>

			{application.type !== ApplicationType.Standard ? <SupportingDocumentsEdited/> : <></>}
		</form>
	);
};

export { SupportingDocumentsForm};
