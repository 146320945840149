import { CitizenStatus } from "../enums";
import { createErrorState, ErrorState, IErrorState } from "../errorState";
import { Address, Email, Phone } from "../types";
import { IPerson, Person } from "../intake";
import {isValidEmail, isValidSin} from "../common";
import { IncomeAssets } from "./income";

interface IApplicantInformation extends IPerson {
	address?: Address;
	mailingAddressSameAsAddress: boolean;
	mailingAddress?: Address;
	citizenship: CitizenStatus;
	socialInsuranceNumber?: string;
	primaryPhone: Phone;
	secondaryPhone?: Phone;
	email: Email;
	income: IncomeAssets;
}

class ApplicantInformation extends Person implements Partial<IApplicantInformation>, IErrorState<Partial<IApplicantInformation>> {
	readonly mailingAddressSameAsAddress?: boolean;
	readonly mailingAddress?: Address;
	readonly citizenship?: CitizenStatus;
	readonly socialInsuranceNumber?: string;
	readonly primaryPhone?: Phone;
	readonly secondaryPhone?: Phone;
	readonly email?: Email;
	readonly income?: IncomeAssets;

	constructor(from?: Partial<IApplicantInformation>) {
		super(from);
		
		this.mailingAddressSameAsAddress = from?.mailingAddressSameAsAddress ?? false;
		this.mailingAddress = from?.mailingAddressSameAsAddress
			? this.address
			: from?.mailingAddress instanceof Address ? from.mailingAddress : new Address(from?.mailingAddress);
		this.citizenship = from?.citizenship;
		this.socialInsuranceNumber = from?.socialInsuranceNumber;
		this.primaryPhone = new Phone(from?.primaryPhone);
		this.secondaryPhone = new Phone(from?.secondaryPhone);
		this.email = from?.email;
		this.income = from?.income ? new IncomeAssets(from?.income) : undefined;
	}

	get errorState(): ErrorState<Partial<IApplicantInformation>> {
		return this.validated
			? createErrorState<IApplicantInformation>({
				...super.validate().errorState,
				firstName: !this.firstName ? "First name required." : "",
				lastName: !this.lastName ? "Last name required." : "",
				email: !this.email?.address	? "Email is required." : !isValidEmail(this.email?.address) ? "Invalid email" : "",
				socialInsuranceNumber: this.socialInsuranceNumber === undefined
					? "Social Insurance Number required."
					: !isValidSin(this.socialInsuranceNumber) ? "Invalid Social Insurance Number." : "",
				address: !this.address?.isComplete ? "Required" : "",
				mailingAddress: !this.mailingAddress?.isComplete && !this.mailingAddressSameAsAddress ? "Required" : ""
			}) : {};
	}

	validate(): ApplicantInformation {
		return new ApplicantInformation({ ...this, validated: true });
	}
}

export { ApplicantInformation };
export type { IApplicantInformation };