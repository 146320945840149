import React, {cloneElement, useState, ReactElement} from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
	AppBar as MuiAppBar,
	Box,
	Button,
	Container,
	Drawer,
	IconButton,
	Link,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText, Menu, MenuItem,
	Stack,
	Toolbar,
	Typography,
	useScrollTrigger
} from "@mui/material";

// project imports
import Logo from "ui-component/Logo";

// assets
import {IconForms, IconInfoCircle, IconHome2, IconKey, IconArrowBack, IconHelp} from "@tabler/icons";
import MenuIcon from "@mui/icons-material/Menu";
import useAuth from "hooks/useAuth";
import {AccountCircle} from "@mui/icons-material";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {useApplication} from "hooks";
import {useFeatureFlags} from "../../hooks/useFeatureFlags";

// elevation scroll
interface ElevationScrollProps {
	children: ReactElement;
	window?: Window | Node;
}

function ElevationScroll({ children, window }: ElevationScrollProps) {
	const theme = useTheme();
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window!
	});

	return cloneElement(children, {
		elevation: trigger ? 1 : 0,
		style: {
			backgroundColor: theme.palette.mode === "dark" && trigger ? theme.palette.dark[800] : theme.palette.background.default,
			color: theme.palette.text.dark
		}
	});
}

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

function AppBar ({ ...others }) {
	const [drawerToggle, setDrawerToggle] = useState<string>();
	const {isLoggedIn, logout, login, firstName} = useAuth();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const {tenant} = useParams();
	const {startNewGbv} = useApplication();
	
	const open = Boolean(anchorEl);
	const featureFlags = useFeatureFlags();
	
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const drawerToggler = (drawer?: string) => (event: any) => {
		if (event.type! === "keydown" && (event.key! === "Tab" || event.key! === "Shift")) {
			return;
		}
		setDrawerToggle(drawer);
	};
	
	const MenuOptions: Record<string, JSX.Element> = {
		"top": <TopLevelMenu gotoMenu={setDrawerToggle} />,
		"apply": <ApplyMenu gotoMenu={setDrawerToggle} />
	};
	
	return (
		<ElevationScroll {...others}>
			<MuiAppBar sx={{position: "unset"}}>
				<Container>
					<Toolbar sx={{ py: 2.5, px: "0 !important" }}>
						<Typography component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
							<Logo />
						</Typography>
						<Stack direction="row" sx={{display: { xs: "none", sm: "flex"}, spacing: { xs: 1.5, md: 2.5 }}}>
							<Button color="inherit" data-testid="menu-homepage-hyperlink" component={RouterLink} to={isLoggedIn ? `/${tenant}/pages/dashboard` : `/${tenant}/pages/landing`} >
								<Typography variant={"h4"}>Home</Typography>
							</Button>
							<PopupState variant={"popover"}>
								{state => (
									<>
										<Button color={"inherit"} {...bindTrigger(state)}><Typography variant={"h4"}>Apply</Typography></Button>
										<Menu {...bindMenu(state)}>
											<MenuItem component={RouterLink} to={`/${tenant}/pages/intake`}>Rental Assistance</MenuItem>
											{featureFlags.find(f => f.id.toLowerCase() === "gbvenable" && f.isEnabled) && <MenuItem onClick={startNewGbv}>Gender-based Violence</MenuItem>}
										</Menu>
									</>
								)}
							</PopupState>
							<Button color="inherit" data-testid="menu-faq-hyperlink" component="a" href="https://civida.ca/rent-assistance/rent-assistance-faqs/" target="_blank">
								<Typography variant={"h4"}>FAQ</Typography>
							</Button>
							<Button color="inherit" data-testid="menu-privacy-hyperlink" component={RouterLink} to={`/${tenant}/pages/privacy-policy`} target={"_blank"}>
								<Typography variant={"h4"}>Privacy Policy</Typography>
							</Button>
							{isLoggedIn 
								? (
									<div style={{display: "flex", paddingLeft: "1rem", alignContent: "center", justifyContent: "center", alignItems:"center"}}>
										<div>
											<IconButton onClick={handleClick}>
												<AccountCircle sx={{height: "30px"}} color={"info"}/>
											</IconButton>
											<Menu
												id="userMenu"
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
											>
												<MenuItem onClick={logout}>Logout</MenuItem>
											</Menu>
										</div>
										<div style={{display: "flex", alignItems: "center"}}>
											<Typography variant={"h3"}>{firstName}</Typography>
										</div>
									</div>
								)
								: (
									<Button color="inherit" onClick={() => login()}>
										<Typography variant={"h4"}>Login</Typography>
									</Button>
								)
							}
						</Stack>
						<Box sx={{ display: { xs: "block", sm: "none" } }}>
							<IconButton color="inherit" onClick={drawerToggler("top")} size="large">
								<MenuIcon />
							</IconButton>
							<Drawer anchor="top" open={!!drawerToggle} onClose={drawerToggler(undefined)}>
								{MenuOptions[drawerToggle ?? ""]}
							</Drawer>
						</Box>
					</Toolbar>
				</Container>
			</MuiAppBar>
		</ElevationScroll>
	);
}

type MenuProps = {
	gotoMenu: (menuItem?: string) => void;
}

const TopLevelMenu = ({gotoMenu}: MenuProps) => {
	const {tenant} = useParams();
	const {isLoggedIn, logout, login} = useAuth();
	
	return (
		<Box sx={{ width: "auto" }} role="presentation">
			<List>
				<Link style={{ textDecoration: "none" }} href={isLoggedIn ? `/${tenant}/pages/dashboard` : `/${tenant}/pages/landing`}>
					<ListItemButton>
						<ListItemIcon>
							<IconHome2 />
						</ListItemIcon>
						<ListItemText primary="Home" />
					</ListItemButton>
				</Link>
				<ListItemButton onClick={() => gotoMenu("apply")}>
					<ListItemIcon>
						<IconForms />
					</ListItemIcon>
					<ListItemText primary="Apply" />
				</ListItemButton>
				<Link style={{ textDecoration: "none" }} href={"https://civida.ca/rent-assistance/faqs/"} target={"_blank"}>
					<ListItemButton>
						<ListItemIcon>
							<IconInfoCircle />
						</ListItemIcon>
						<ListItemText primary="FAQ" />
					</ListItemButton>
				</Link>
				<Link style={{ textDecoration: "none" }} href={`/${tenant}/pages/privacy-policy`}>
					<ListItemButton>
						<ListItemIcon>
							<IconInfoCircle />
						</ListItemIcon>
						<ListItemText primary="Privacy Policy" />
					</ListItemButton>
				</Link>
				<Link>
					<ListItemButton onClick={() => isLoggedIn ? logout() : login()}>
						<ListItemIcon>
							<IconKey />
						</ListItemIcon>
						{ isLoggedIn ?
							<ListItemText primary="Logout" /> : <ListItemText primary="Login" />
						}
					</ListItemButton>
				</Link>
			</List>
		</Box>
	);
};

const ApplyMenu = ({gotoMenu}: MenuProps) => {
	const {tenant} = useParams();
	
	return (
		<Box sx={{ width: "auto" }} role="presentation">
			<List>
				<Link style={{ textDecoration: "none" }} href={`/${tenant}/pages/intake`}>
					<ListItemButton>
						<ListItemIcon>
							<IconForms />
						</ListItemIcon>
						<ListItemText primary="Rental Assistance Benefit" />
					</ListItemButton>
				</Link>
				<Link style={{ textDecoration: "none" }} href={`/${tenant}/pages/gbv`}>
					<ListItemButton>
						<ListItemIcon>
							<IconHelp />
						</ListItemIcon>
						<ListItemText primary="Gender-based Violence" />
					</ListItemButton>
				</Link>
				<ListItemButton onClick={() => gotoMenu("top")}>
					<ListItemIcon>
						<IconArrowBack />
					</ListItemIcon>
					<ListItemText primary="Go back" />
				</ListItemButton>
			</List>
		</Box>
	);
};

export default AppBar;