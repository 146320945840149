//Plan to extend with logic
import {PhoneNumberType} from "./enums";
import {ErrorState, IErrorState} from "./errorState";

type Email = {address?: string};

class Phone {
	public readonly number?: string;
	public readonly type?: PhoneNumberType;

	constructor(from?: Phone) {
		this.number = from?.number;
		this.type = from?.type;
	}

	static create(value: any): Phone | undefined {
		return Object.keys(new Phone()).some(k => !!value?.[k]) ? new Phone(value) : undefined;
	}
}

class Address implements IErrorState<Partial<Address>> {
	[key: string]: any;
	
	public readonly streetNumber?: string;
	public readonly streetName?: string;
	public readonly unit?: string;
	public readonly poBox?: string;
	public city?: string;
	public readonly province?: string;
	public readonly country?: string;
	public readonly postalCode?: string;
	public readonly manuallyEntered: boolean;
	public readonly careOf?: string;
	private readonly validated: boolean;
	constructor(from?: Partial<Address>) {
		Object.entries(from ?? {}).forEach(([k, v]) => this[k] = v);
		this.manuallyEntered = from?.manuallyEntered ?? false;
		this.validated = (from as Address)?.validated ?? false;
	}
	
	static create(value: any): Address | undefined {
		return Object.keys(new Address()).some(k => !!value?.[k]) ? new Address(value) : undefined;
	}
	
	toString() {
		const unit = this.unit ? `${this.unit},` : "";
		const street = this.streetNumber || this.streetName ? `${unit}${this.streetNumber ?? ""} ${this.streetName ?? ""}`.trim() : this.poBox;
		return `${street ?? ""}${street && this.city ? ", " : ""}${this.city ?? ""}${this.province && (street || this.city) ? ", " : ""}${this.province ?? ""},${this.postalCode}`;
	}
	
	equivalentTo(value: Address | undefined) {
		if (!value) return false;
		
		return value.streetNumber === this.streetNumber && 
			value.unit === this.unit &&
			value.streetName === this.streetName && 
			value.poBox === this.poBox &&
			value.city === this.city && 
			value.province === this.province &&
			value.postalCode === this.postalCode &&
			value.country === this.country;
	}
	
	validate() {
		return new Address({...this, validated: true});
	}

	get isComplete(): boolean {
		return !!this.streetNumber 
			&& !!this.province
			&& !!this.city
			&& !!this.postalCode
			&& !!this.country;
	}
	
	get errorState(): ErrorState<Partial<Address>> {
		return {
			streetNumber: !this.streetNumber ? "Required" : "",
			city: !this.city ? "Required" : "",
			province: !this.province ? "Required" : "",
			postalCode: !this.postalCode ? "Required" : "",
			country: !this.country ? "Required" : "",
		};
	}
}

export type {Email};
export {Phone, Address};