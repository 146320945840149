import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
	Adult, ApplicantInformation, Application, ApplicationBuilder
	, ApplicationDocuments, fileEquivalent, FileProcess, Household, IFileInfo, LoadStatus, ProgramApplications
} from "library";
import api from "library/api";
import {IntakeInformation} from "types/intakeInformation";

const set = (state: Draft<ApplicationBuilder> | undefined, value: Partial<ApplicationBuilder>): ApplicationBuilder => {
	const existingHousehold = (state as ApplicationBuilder)?.household;
	const applicantAddress = (state as ApplicationBuilder)?.applicant?.address;

	if (!value.applicant || !existingHousehold || !applicantAddress)
		return new ApplicationBuilder({ ...(state as ApplicationBuilder ?? {}), ...value });

	const newAddress = value?.applicant?.address;
	const household = new Household({
		...existingHousehold,
		adults: existingHousehold.adults.map(
			adult => adult.address === applicantAddress
				? new Adult({
					...adult,
					address: newAddress,
					mailingAddress: adult.mailingAddress === applicantAddress
						? newAddress
						: adult.mailingAddress
				})
				: adult),
	});

	return new ApplicationBuilder({ ...(state as ApplicationBuilder ?? {}), household, ...value });
};

const isIntake = (value: any): value is IntakeInformation => Object.keys(value).includes("intake");

const startNewApplication = createAsyncThunk("application/startNew", async (intakeInformation: IntakeInformation | ApplicationBuilder) => {
	const application = isIntake(intakeInformation) ? intakeInformation.intake.createApplication(intakeInformation.userId) : intakeInformation;
	const response = await api.Applications.create(application);
	return set(undefined, new ApplicationBuilder({ ...application, ...response }));
});

const loadWithCode = createAsyncThunk("application/get", async (code: string) => {
	return new ApplicationBuilder(await api.Applications.get<ApplicationBuilder>(code));
});

const saveDocuments = createAsyncThunk("application/saveDocuments", async (value: {property: keyof ApplicationDocuments, files: File[]}, { dispatch, getState }) => {
	const { application: { builder: { code, documents} } } = getState() as { application: { builder: ApplicationBuilder } };

	for (const file of value.files)
		await api.Applications.addFile(code, value.property, file);

	return await dispatch(saveProgress({
		documents: new ApplicationDocuments({
			...documents,
			...{
				[value.property]: {
					required: true,
					files: (documents[value.property] as {files: IFileInfo[]}).files.map(f => ({name: f.name, size: f.size, type: f.type, state: FileProcess.Idle}))
				}
			}
		})
	}));
});

const removeDocuments = createAsyncThunk("application/removeDocuments", async (value: {property: keyof ApplicationDocuments, file: IFileInfo}, {dispatch, getState}) => {
	const { application: { builder: { documents } } } = getState() as { application: { builder: ApplicationBuilder } };

	return await dispatch(saveProgress({
		documents: new ApplicationDocuments({
			...documents,
			...{
				[value.property]: {
					required: true,
					files: [...(documents[value.property] as {files: IFileInfo[]}).files]
						.filter(f => f.state !== FileProcess.Deleting)
						.filter(f => !fileEquivalent(f, value.file))
				}
			}
		})
	}));
});

const saveProgress = createAsyncThunk("application/save", async (value: Partial<Application> | void, { getState }) => {
	const { application: { builder: existingApplication } } = getState() as { application: { builder: ApplicationBuilder } };
	const updatedApplication = new ApplicationBuilder({
		...existingApplication,
		...value
	});
	void api.Applications.update(updatedApplication);
	return updatedApplication;
});

const combineStateFiles = (inState: IFileInfo[], newState: IFileInfo[]) => newState.map(f => {
	const existing = inState.find(inner => inner.name === f.name && inner.size === f.size && inner.type === f.type);
	return !existing
		? f
		: {...f, state: existing.state === FileProcess.Deleting ? FileProcess.Deleting : f.state};
});

const application = createSlice({
	name: "applicationState",
	initialState: {
		status: LoadStatus.Loaded,
		isStale: true,
		builder: new ApplicationBuilder()
	},
	reducers: {
		reset: () => ({status: LoadStatus.Loaded, isStale: true, builder: new ApplicationBuilder()}),
		set: (state, action: PayloadAction<Partial<ApplicationBuilder>>) => {
			return ({
				...state,
				builder: set(state.builder, action.payload)
			});
		},
		setStatus: (state, { payload }) => ({ ...state, builder: set(state.builder, { status: payload }) }),
		setApplicant: (state, { payload }) => ({ ...state, builder: set(state.builder, { applicant: new ApplicantInformation({ ...state.builder.applicant, ...payload }) }) }),
		setApplicantIncome: (state, { payload }) => ({ ...state, builder: set(state.builder, { applicant: new ApplicantInformation({ ...(state.builder.applicant as ApplicantInformation), income: payload }) }) }),
		setHousing: (state, { payload }) => ({ ...state, builder: set(state.builder, { housing: payload }) }),
		setHousehold: (state, { payload }) => ({ ...state, builder: set(state.builder, { household: payload }) }),
		setHouseholdIncome: (state, { payload }) => ({ ...state, builder: set(state.builder, { household: new Household({ ...(state.builder.household as Household), adults: payload }) }) }),
		setSituation: (state, { payload }) => ({ ...state, builder: set(state.builder, { situation: payload }) }),
		setDocuments: (state, { payload }) => ({ ...state, builder: set(state.builder, { documents: payload }) }),
		setContacts: (state, { payload }) => ({ ...state, builder: set(state.builder, { contacts: payload }) }),
		setConsent: (state, { payload }) => ({ ...state, builder: set(state.builder, { consent: payload }) }),
		setPrograms: (state, { payload }) => ({ ...state, builder: set(state.builder, { programs: new ProgramApplications(payload) }) })
	},
	extraReducers: builder => {
		builder.addCase(saveProgress.pending, (state) =>
			({ ...state, status: LoadStatus.Loaded, builder: state.builder }));
		builder.addCase(saveProgress.fulfilled, (state, { payload }) => {
			return ({
				isStale: false,
				status: LoadStatus.Loaded,
				builder: new ApplicationBuilder({
					...payload,
					documents: new ApplicationDocuments({
						bankInfo: payload.documents.bankInfo,
						rentProof: {...payload.documents.rentProof, files: combineStateFiles(state.builder.documents.rentProof.files, payload.documents.rentProof.files)},
						eftDetails: {...payload.documents.eftDetails, files: combineStateFiles(state.builder.documents.eftDetails.files, payload.documents.eftDetails.files)},
						incomeDocuments: {...payload.documents.incomeDocuments, files: combineStateFiles(state.builder.documents.incomeDocuments.files, payload.documents.incomeDocuments.files)},
						statusInCanada: {...payload.documents.statusInCanada, files: combineStateFiles(state.builder.documents.statusInCanada.files, payload.documents.statusInCanada.files)},
						other: {...payload.documents.other, files: combineStateFiles(state.builder.documents.other.files, payload.documents.other.files)},
						safety: {...payload.documents.safety, files: combineStateFiles(state.builder.documents.safety.files, payload.documents.safety.files)}
					})
				})
			});
		});
		builder.addCase(saveProgress.rejected, (state) =>
			({ ...state, status: LoadStatus.Loaded, builder: state.builder }));
		builder.addCase(startNewApplication.pending, (state) => ({...state, status: LoadStatus.Loading}));
		builder.addCase(startNewApplication.fulfilled, (_, { payload }) =>
			({ isStale: false, status: LoadStatus.Loaded, builder: payload }));
		builder.addCase(loadWithCode.pending, (state) =>
			({ ...state, status: LoadStatus.Loading }));
		builder.addCase(loadWithCode.fulfilled, (_, { payload }) => ({
			isStale: false,
			status: payload ? LoadStatus.Loaded : LoadStatus.DoesNotExist,
			builder: new ApplicationBuilder(payload)
		}));
		builder.addCase(saveDocuments.pending, (state, {meta: {arg: {property, files}}}) => {
			return ({
				...state,
				builder: new ApplicationBuilder({
					...state.builder as ApplicationBuilder,
					documents: new ApplicationDocuments({
						...state.builder.documents,
						[property]: {
							required: true,
							files: [...(state.builder.documents[property] as {files: IFileInfo[]}).files, ...files.map(f => ({name: f.name, size: f.size, type: f.type, state: FileProcess.Uploading}))]
						}
					})
				})
			});
		});
		builder.addCase(removeDocuments.pending, (state, {meta: {arg: {property, file}}}) => {
			return ({
				...state,
				builder: new ApplicationBuilder({
					...state.builder as ApplicationBuilder,
					documents: new ApplicationDocuments({
						...state.builder.documents,
						[property]: {
							required: true,
							files: (state.builder.documents[property] as {files: IFileInfo[]}).files.map(f => fileEquivalent(f, file) ? {...f, state: FileProcess.Deleting} : f)
						}
					})
				})
			});
		});
	}
});

export default application.reducer;

export const {
	reset,
	set: setApplication,
	setStatus,
	setApplicant,
	setApplicantIncome,
	setHousing,
	setHousehold,
	setHouseholdIncome,
	setSituation,
	setDocuments,
	setContacts,
	setConsent,
	setPrograms,
} = application.actions;
export { startNewApplication, loadWithCode, saveDocuments, removeDocuments, saveProgress };