import {NavigateOptions, To, useNavigate, useParams} from "react-router-dom";
import {useCallback} from "react";

const useNavigator = () => {
	const navigate = useNavigate();
	const {tenant} = useParams();
	
	const navigateTo = useCallback((to: To, options?: NavigateOptions) => {
		const isAbsolute = typeof to === "string"
			? to.startsWith("/")
			: to.pathname?.startsWith("/");
		
		navigate(isAbsolute ? `/${tenant}${to}` : to, options);
	}, []);
	
	return navigateTo;
};

export default useNavigator;