import {FormControlLabel, Grid, Switch} from "@mui/material";
import {fullWidth, GbvApplicant, halfWidth} from "library";
import {SectionSubHeading} from "ui-component/SectionHeading";
import {AddressForm} from "components";
import {Address} from "library/types";
import {useCallback} from "react";

type AddressSectionProps = {
    applicant: GbvApplicant;
	onChange?: (value: Partial<GbvApplicant>) => void;
}

const AddressSection = ({applicant, onChange}: AddressSectionProps) => {
	const set = useCallback((changes: Partial<GbvApplicant>) => {
		onChange?.(changes);
	}, [onChange]);
    
	return (<>
		<Grid container spacing={3}>
			<Grid item {...fullWidth}>
				<SectionSubHeading>Your Current Address</SectionSubHeading>
			</Grid>

			<AddressForm
					prependId={"current"}
					value={applicant.address ?? new Address()}
					columnWidth={halfWidth}
					inError={!!applicant.errorState.address}
					onChange={v => set({address: new Address(v)})} />
		</Grid>

		<Grid container spacing={3}>
			<Grid item {...fullWidth}>
				<SectionSubHeading>Mailing Address</SectionSubHeading>
			</Grid>
			<Grid item {...fullWidth}>
				<FormControlLabel label="Mailing address is the same as current address" control={
					<Switch checked={applicant.mailingAddressSameAsAddress} onChange={event => set({mailingAddressSameAsAddress: event.target.checked})} data-sel="togMailingAddress" />
				} />
			</Grid>
            
			{!applicant.mailingAddressSameAsAddress &&
				<AddressForm
					prependId={"mailing"}
					value={applicant.mailingAddress ?? new Address()}
					columnWidth={halfWidth}
					inError={!!applicant.errorState.mailingAddress}
					onChange={v => set({ mailingAddress: new Address(v) })}
				/>}
		</Grid>
	</>);
};

export {
	AddressSection
};