import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "library/api";
import FeatureFlag from "library/featureFlag";

const getFeatureFlagsFor = createAsyncThunk("featureFlags/get", async() => await api.FeatureFlags.get());

interface IFeatureFlags {
	loading: boolean;
	featureFlags: FeatureFlag[];
}

const initialState: IFeatureFlags = {
	loading: true,
	featureFlags: []
};

const featureFlags = createSlice(({
	name: "featureFlagsState",
	initialState, 
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getFeatureFlagsFor.pending, (state) => ({...state, loading: true}));
		builder.addCase(getFeatureFlagsFor.fulfilled, (state, {payload}) => ({...state, loading: false, featureFlags: payload}));
	}	
}));

export default featureFlags.reducer;
export {getFeatureFlagsFor};
