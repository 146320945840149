import {IContact, IncomeType, IPerson, isAllUndefinedOrNull, isEmpty, NoticeOfAssessmentIncome, OtherIncome} from "library";
import { DateValue } from "library/DateValue";
import { Address, Phone } from "library/types";

const filterObjectForInfoCard = (
	data: Partial<IPerson> | Partial<IContact> | Partial<NoticeOfAssessmentIncome> | Partial<OtherIncome>,
	patterns: string | string[]
) => {
	const stringsPatterns = Array.isArray(patterns) ? patterns : [patterns];
	const patternRegexps = stringsPatterns.map((p: string) => new RegExp(p, "i"));

	return Object.entries(data)
		.filter(([key]) => !patternRegexps.some((pattern) => pattern.test(key)))
		.filter(([k, v]) => !(k.match(/.*phone/i) && isAllUndefinedOrNull(v)))
		.filter(([, v]) => v !== undefined && v !== "")
		.map(([k, v]) => {
			if (DateValue.isDate(v) && !k.match(/.*(social|income|organization|notify|amount|year|gender|status|phone)/i)) {
				return [k, new DateValue(v).format()];
			}

			if (k === "socialInsuranceNumber") {
				return [k, "●●● ●●● ●●●"];
			}

			if (!isEmpty(Phone.create(v) || {})) {
				let phone = v.number?.replace(/^\+\d{1,3}\s?/, "");
				if (v.type) phone += ` (${v.type})`;
				
				return [k, phone];
			}

			if (k === "email" && v?.address) {
				return [k, v?.address];
			}
			
			if (v instanceof Address || Address.create(v)) {
				return [k, new Address(v).toString()];
			}
			
			if (v instanceof IncomeType)
				return [k, v.name];

			if (k === "amount") {
				return [k, `$${Number(v).toLocaleString()}`];
			}

			return !isEmpty(v) ? [k, v] : [k, ""]; 
		});
};

export { filterObjectForInfoCard };