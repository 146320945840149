import styles from "styles/supportingDocuments.module.scss";

import {SectionHeading} from "ui-component/SectionHeading";
import {Button, Typography} from "@mui/material";
import {PROVIDER_EMAIL, PROVIDER_NAME} from "config";
import useNavigator from "hooks/useNavigator";
import {useApplication} from "hooks";
import {useMemo} from "react";

const SupportingDocumentsSubmitted = () => {
	const navigate = useNavigator();
	const {application} = useApplication();
	
	const email = useMemo(() => !application.programs?.isStrictly("GBV") ? PROVIDER_EMAIL : "gbv@civida.ca", [application.programs]);
	
	return (
		<>
			<SectionHeading>Supporting Documents Submitted</SectionHeading>
			<div className={styles.heading}>
				<Typography gutterBottom>Your supporting documents for your rent assistance benefit application have been submitted to {PROVIDER_NAME}.</Typography>
				<Typography gutterBottom>If any additional information is required, we will reach out to you promptly. Please ensure to keep your information up to date by logging into the {PROVIDER_NAME} Benefit Portal.</Typography>
				<Typography>Please contact <a href={`mailto:${email}`}>{email}</a> with any questions related to your benefit.</Typography>
			</div>

			<Button className = {styles.submitButton} variant={"contained"} color={"secondary"} onClick={() => navigate("/pages/dashboard", {relative: "path"})}>BACK TO HOME</Button>
		</>
	);
};

export { SupportingDocumentsSubmitted};