import { FC } from "react";
import { Typography } from "@mui/material";

const ApplicantContactName: FC<{ fullName: string | undefined}> = ({ fullName }) => (
	<Typography variant="h4">
		{fullName}
	</Typography>
);

export { ApplicantContactName };
