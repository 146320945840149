import {FormControlLabel, Grid, Switch, Typography} from "@mui/material";
import {Circumstances, fullWidth, Housing, HousingType, oneThirdWidth, twoThirdWidth} from "library";
import {SectionSubHeading} from "ui-component/SectionHeading";
import {AppSelectList, GridDatePicker, GridText, YesNoButton, YesNoSilentButton} from "components";
import {DateValue} from "library/DateValue";
import styles from "styles/situation.module.scss";
import React from "react";

type PersonalDetailsSectionProps = {
	circumstances: Circumstances;
	housing: Housing;
	onChange: (value: Partial<Circumstances> | Partial<Housing>) => void;
}

const PersonalDetailsSection = ({circumstances, housing, onChange}: PersonalDetailsSectionProps) => {
	return (<>
		<Grid container spacing={3}>
			<Grid item {...fullWidth}>
				<SectionSubHeading>Personal Details</SectionSubHeading>
			</Grid>

			<Grid item {...oneThirdWidth}>
				<AppSelectList
					testElementName="ddlHousingType"
					label={"Select Housing Type *"}
					value={circumstances?.housingType}
					items={Object.values(HousingType)}
					error={""}
					onChange={v => onChange({housingType: v as HousingType})}
				/>
			</Grid>

			<GridDatePicker
				testElementName="tbLeaseExpiry"
				label="Expiry Date"
				value={housing?.leaseExpiry?.value.toDate()}
				error={housing?.errorState.leaseExpiry}
				columnWidth={oneThirdWidth}
				disabled={housing.onAMonthToMonthLease}
				onChange={value => onChange({ leaseExpiry: value ? new DateValue(value) : undefined, onAMonthToMonthLease: !value})}

			/>

			<Grid item {...oneThirdWidth}>
				<FormControlLabel label="On a month-to-month lease" control={
					<Switch
						data-sel="togMonthToMonth"
						checked={housing?.onAMonthToMonthLease ?? false}
						onChange={value => onChange({ onAMonthToMonthLease: value.target.checked, leaseExpiry: value.target.checked ? undefined : housing?.leaseExpiry})}
					/>
				} />
			</Grid>

			<Grid item {...fullWidth}>
				<Typography variant={"h5"}>
					Have you experienced Gender-based violence within the last six months?
				</Typography>
				<Grid item {...twoThirdWidth} className={styles.answer}>
					<YesNoButton testElementName="togRecurrence"
					             value={circumstances?.experiencedRecently}
					             error={circumstances?.errorState.experiencedRecently}
					             onChange={v => onChange({ experiencedRecently: v})} />
				</Grid>

				{circumstances?.experiencedRecently === "No" &&
				<Grid item {...twoThirdWidth}>
					<Typography variant={"h5"}>
						Explain why the benefit is required?
					</Typography>
					<GridText label={""} value={circumstances?.reasonForBenefit} 
					          error={circumstances?.errorState.reasonForBenefit}
					          columnWidth={fullWidth}
					          onChange={v => onChange({ reasonForBenefit: v })} />
				</Grid>}
			</Grid>
			
			<Grid item {...fullWidth}>
				<Typography variant={"h5"}>
					Are you still under threat of Gender-based Violence?
				</Typography>
				<Grid item {...twoThirdWidth} className={styles.answer}>
					<YesNoSilentButton testElementName="togUnderThreat"
									   value={circumstances?.underThreat}
									   error={circumstances?.errorState.underThreat}
									   onChange={v => onChange({ underThreat: v})} />
				</Grid>
			</Grid>
			
			<Grid item {...fullWidth}>
				<Typography variant={"h5"}>
					Do you have a Safe Spaces Certification?
				</Typography>
				<Grid item {...twoThirdWidth} className={styles.answer}>
					<YesNoSilentButton testElementName="togSafeSpace"
									   value={circumstances?.safeSpace}
									   error={circumstances?.errorState.safeSpace}
									   onChange={v => onChange({ safeSpace: v})} />
				</Grid>
			</Grid>

			<Grid item {...fullWidth}>
				<Typography variant={"h5"}>
					Are you currently receiving Housing Subsidy (ie. RAB, TRAB, PLRS)?
				</Typography>
				<Grid item {...twoThirdWidth} className={styles.answer}>
					<YesNoSilentButton testElementName="togSubsidized"
									   value={circumstances?.subsidized}
									   error={circumstances?.errorState.subsidized}
									   onChange={v => onChange({ subsidized: v})} />
				</Grid>
			</Grid>

			<Grid item {...fullWidth}>
				<Typography variant={"h5"}>
					Do you currently live in Community Housing?
				</Typography>
				<Grid item {...twoThirdWidth} className={styles.answer}>
					<YesNoSilentButton testElementName="togCommunityHousing"
									   value={circumstances?.communityHousing}
									   error={circumstances?.errorState.communityHousing}
									   onChange={v => onChange({ communityHousing: v})} />
				</Grid>
			</Grid>

			<Grid item {...fullWidth}>
				<Typography variant={"h5"}>
					Do you have long-term housing plans for when the benefit expires?
				</Typography>
				<Grid item {...twoThirdWidth} className={styles.answer}>
					<YesNoSilentButton testElementName="toglongTermPlan"
					                   value={circumstances?.longTermHousingPlan}
					                   error={circumstances?.errorState.longTermHousingPlan}
					                   onChange={v => onChange({ longTermHousingPlan: v})} />
				</Grid>
			</Grid>
		</Grid>
	</>);
};

export {
	PersonalDetailsSection
};