import {ApplicantInformationSection} from "./ApplicantInformation.Section";
import {SectionHeading} from "ui-component/SectionHeading";
import {ContactSection} from "./Contact.Section";
import {GbvApplicant, IncomeAssets} from "library";
import {AddressSection} from "./Address.Section";
import {useCallback, useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {hasErrors} from "library/errorState";
import {useNavigate} from "react-router-dom";
import useAuth from "hooks/useAuth";
import {ApplicationPreviousNext} from "../application/ApplicationPreviousNext";
import {IncomeSection} from "./Income.Section";
import {useApplication} from "hooks";

const Applicant = () => {
	const navigate = useNavigate();
	const {user} = useAuth();
	const {loading, application, mutations: {saveAsync}} = useApplication();
	const [state, setState] = useState(new GbvApplicant(application.applicant));
	
	useEffect(() => {
		onChange({...application.applicant});
	}, [loading]);
	
	const onNext = useCallback(() => {
		//We don't ask for email or student status, but they're required.  So default them
		const validated = new GbvApplicant({...state, email: {address: user!.email}}).validate();
		onChange(validated);
		
		if (hasErrors(validated))
			return;

		void saveAsync({applicant: validated});
		return "situation";
	}, [state, saveAsync, navigate]);
	
	const onChange = useCallback((value: Partial<GbvApplicant>) => {
		const newState = new GbvApplicant({...state, ...value});
		setState(newState);
	}, [setState, state]);
	
	const onChangeIncome = useCallback((value: Partial<IncomeAssets>) => {
		onChange({income: new IncomeAssets(value)});
	}, [onChange]);

	return (
		<>
			<SectionHeading>Applicant Information</SectionHeading>

			{loading ? <CircularProgress color="info"/> : <>
				<ApplicantInformationSection applicant={state} onChange={onChange} />
				<ContactSection applicant={state} onChange={onChange} />
				<AddressSection applicant={state} onChange={onChange} />
				<IncomeSection income={state.income ?? new IncomeAssets()} onChange={onChangeIncome} />

				<ApplicationPreviousNext application={application}
				                         nextText={"Next to Circumstances"}
				                         onNext={onNext} />
			</>}
		</>
	);
};

export {
	Applicant
};