import {createAsyncThunk, createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import {ApplicationBuilder, ApplicationStatus, IAccountInfo} from "library";
import agent from "library/api";
import {Submission} from "library";
import {setStatus} from "./application";
import api from "library/api";

const submitApplication = createAsyncThunk<Submission, ApplicationBuilder>("application/submit", async <T extends ApplicationBuilder>(builder: T) => {
	const application = builder.build();
	if (!application) throw new Error("Application does not contain sufficient information");
	
	return await api.Applications.submit(application);
});

const submitApplicationDocuments = createAsyncThunk("application/submitDocuments", async (value: {code: string, bankInfo: IAccountInfo}, {dispatch}) => {
	await agent.Applications.submitDocuments(value.code, value.bankInfo)
		.then(() => dispatch(setStatus(ApplicationStatus.PendingApproval)))
		.catch();
});

const updateApplicationDocuments = createAsyncThunk("application/submitDocuments", async (value: {code: string, bankInfo: IAccountInfo}) => {
	await agent.Applications.submitDocuments(value.code, value.bankInfo)
		.catch();
});

const submission = createSlice<{value: Submission | null, loading?: boolean}, SliceCaseReducers<{value: Submission | null, loading?: boolean}>>({
	name: "submissionState",
	initialState: {value: null, loading: false},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(submitApplication.pending, (state) => ({...state, loading: true}));
		builder.addCase(submitApplication.rejected, (state) => ({...state, loading: false}));
		builder.addCase(submitApplication.fulfilled, (state, {payload}) => ({value: payload, loading: false}));
	}
});

export default submission.reducer;

export {submitApplication, submitApplicationDocuments, updateApplicationDocuments};