import { Card, CardContent, CardHeader, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { PROVIDER_EMAIL, PROVIDER_PHONE} from "config";

// Default props
type HelpSupportInfoCardProps = {
	classes?: string
};

const HelpSupportInfoCard: FC<HelpSupportInfoCardProps> = () => {
	const theme = useTheme();

	return (
		<Card elevation={4} sx={{ marginTop: 2 }}>
			<CardContent sx={{ p: 4 }}>
				<Card sx={{ m: -4, textAlign: "left" }}>
					<CardHeader sx={{ p: 1, paddingLeft: 2, background: theme.palette.primary.main }} title={<Typography align="left" className="lightText" variant="h5">Need Help?</Typography>} />
					<Typography sx={{ p: 2, paddingBottom: 4 }}>
						Contact our Customer Success team at {PROVIDER_EMAIL} or {PROVIDER_PHONE}.
					</Typography>
				</Card>
			</CardContent>
		</Card>
	);
};

export { HelpSupportInfoCard };
