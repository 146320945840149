import styles from "styles/applicantCard.module.scss";

import { FC } from "react";
import { Grid, Typography } from "@mui/material";

import { toSentence } from "utils/toSentence";
import { oneThirdWidth } from "library";

const ApplicantContactCardDetailItem: FC<{ detailName: string, detailValue: string }> = ({ detailName, detailValue }) => (
	<Grid item {...oneThirdWidth} sm={6} className={styles.cardItem}>
		<Typography variant="h5" sx={{ pb: 0, mb: 0 }}>{toSentence(detailName)}</Typography>
		<Typography variant="body2" sx={{ pb: 0, mb: 0 }} noWrap>{detailValue}</Typography>
	</Grid>
);

export { ApplicantContactCardDetailItem };
