import {SectionHeading} from "ui-component/SectionHeading";
import { ApplicationReviewInfoCard } from "components";
import { useNavigate } from "react-router-dom";
import {Circumstances, GbvBuilder, Housing} from "library";
import {Grid, Switch, Typography} from "@mui/material";
import {useState} from "react";
import {ApplicationPreviousNext} from "../application/ApplicationPreviousNext";
import {Stack} from "@mui/system";
import { useApplication } from "hooks";

const Review = () => {
	const navigate = useNavigate();
	const {application, mutations: {submit}} = useApplication<GbvBuilder>();
	const [consented, setConsented] = useState(false);
	
	const onPrevious = () => "../intake";
	const onSubmit = () => submit().then(() => "../submission");

	const circumstancesKeyOverride: Record<string, string> = {
		["hasMentalConcerns"] : "Struggling with Mental Health",
		["hasDisability"]: "Has Physical Limitations",
		["lgbtq"]: "LGBTQ2S+"
	};

	return (<>
		<SectionHeading>Review and Submit</SectionHeading>
		
		<p>
			Your application is ready for submission. Please review your application details below and return to any previous sections to make edits if required.
			If the application details are accurate, please proceed to submission by clicking on the "Submit Application" button.
		</p>

		<ApplicationReviewInfoCard title={"Applicant"} information={application.applicant} onEdit={() => navigate("../", {relative: "route"})} />
		<ApplicationReviewInfoCard title={"Circumstances"} information={application.situation} keyOverride={circumstancesKeyOverride} onEdit={() => navigate("../situation", {relative: "route"})} />
		<ApplicationReviewInfoCard title={"GBV Details"} information={{...application.circumstances, ...application.programDetails} as Circumstances & Housing} onEdit={() => navigate("../intake", {relative: "route"})} />

		<Grid item>
			<SectionHeading>Consent</SectionHeading>
			<Stack direction={"row"}>
				<Switch value={consented} onChange={() => setConsented(!consented)} />
				<Typography>I/we authorize Civida to communicate with me by email any correspondence, requests for information, or any documents as necessary under the Residential Tenancies Act and/or the Alberta Housing Act.</Typography>
			</Stack>
		</Grid>
		
		
		<ApplicationPreviousNext application={application}
		                         previousText={"Back to GBV Intake"}
		                         onPrevious={onPrevious}
		                         nextDisabled={!consented}
		                         nextText={"Submit Application"} 
		                         onNext={onSubmit} />
	</>);
};

export {
	Review
};