import {fullWidth, GbvApplicant, halfWidth, oneThirdWidth, PhoneNumberType} from "library";
import {useCallback} from "react";
import {SectionSubHeading} from "ui-component/SectionHeading";
import {Grid} from "@mui/material";
import {AppSelectList, GridPhone} from "components";
import {Phone} from "library/types";

type ContactSectionProps = {
	applicant: GbvApplicant;
	onChange?: (value: Partial<GbvApplicant>) => void;
};

const ContactSection = ({applicant, onChange}: ContactSectionProps) => {
	const set = useCallback((change: Partial<GbvApplicant>) => {
		onChange?.(change);
	}, [onChange]);
	
	return (
		<Grid container spacing={3}>
			<Grid item {...fullWidth}>
				<SectionSubHeading>Contact Information</SectionSubHeading>
			</Grid>
			
			<GridPhone value={applicant.primaryPhone?.number ?? ""}
			           label={"Primary Phone Number"}
			           error={applicant.errorState.primaryPhone}
			           testElementName="tbPrimaryPhone"
			           columnWidth={halfWidth}
			           onChange={v => set({ primaryPhone: new Phone({number: v, type: applicant.primaryPhone?.type}) })} />

			<Grid item {...oneThirdWidth}>
				<AppSelectList
					testElementName="ddlPrimaryPhoneType"
					label='Primary Phone Type'
					value={applicant.primaryPhone?.type ?? ""}
					items={Object.values(PhoneNumberType)}
					error={""}
					onChange={v => set({ primaryPhone: new Phone({number: applicant.primaryPhone?.number, type: v as PhoneNumberType}) })}
				/>
			</Grid>

			<GridPhone value={applicant.secondaryPhone?.number ?? ""}
			           label={"Secondary Phone Number"}
			           error={applicant.errorState.secondaryPhone}
			           testElementName="tbSecondaryPhone"
			           columnWidth={halfWidth}
			           onChange={v => set({ secondaryPhone: new Phone({number: v, type: applicant.secondaryPhone?.type}) })} />

			<Grid item {...oneThirdWidth}>
				<AppSelectList
					testElementName="ddlSecondaryPhoneType"
					label='Secondary Phone Type'
					value={applicant.secondaryPhone?.type ?? ""}
					items={Object.values(PhoneNumberType)}
					error={""}
					onChange={v => set({ secondaryPhone: new Phone({number: applicant.secondaryPhone?.number, type: v as PhoneNumberType}) })}
				/>
			</Grid>
		</Grid>
	);
};

export {
	ContactSection
};