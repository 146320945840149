import {fullWidth, oneThirdWidth, ProgramDetails} from "library";
import {FormControlLabel, Grid, Switch} from "@mui/material";
import {SectionSubHeading} from "ui-component/SectionHeading";
import React from "react";
import {GridText} from "components";

type ReferralSectionProps = {
	programDetails: ProgramDetails;
	onChange: (value: Partial<ProgramDetails>) => void;
}

const ReferralSection = ({programDetails, onChange}: ReferralSectionProps) => {
	return (
		<Grid container spacing={3}>
			<Grid item {...fullWidth}>
				<SectionSubHeading>Gender-based Violence Referral Information</SectionSubHeading>
			</Grid>

			<Grid item {...fullWidth}>
				<FormControlLabel label="I do not have a referral from an agency." control={
					<Switch
						data-sel="togHaveReferral"
						checked={!programDetails?.haveReferral}
						onChange={value => onChange({ haveReferral: !value.target.checked })}
					/>
				} />
			</Grid>

			{programDetails?.haveReferral && <>
				<GridText testElementName="tbOrganization" value={programDetails.referringOrganization} error={programDetails.errorState.referringOrganization} label={"Referring Organization *"} columnWidth={oneThirdWidth} onChange={v => onChange({ referringOrganization: v })} />
				
				<Grid item {...fullWidth}>
					<Grid container spacing={3}>
						<GridText testElementName="tbFirstName" value={programDetails.referralName} error={programDetails.errorState.referralName} label={"Referring Agent Name *"} columnWidth={oneThirdWidth} onChange={v => onChange({ referralName: v })} />
						<GridText testElementName="tbPhone" value={programDetails.referralPhone} error={programDetails.errorState.referralPhone} label={"Referring Agent Phone *"} columnWidth={oneThirdWidth} onChange={v => onChange({ referralPhone: v })} />
						<GridText testElementName="tbEmail" value={programDetails.referralEmail} error={programDetails.errorState.referralEmail} label={"Referring Agent Email *"} columnWidth={oneThirdWidth} onChange={v => onChange({ referralEmail: v })} />
					</Grid>
				</Grid>
				
				<GridText testElementName="tbRole" value={programDetails.referralRole} error={programDetails.errorState.referralRole} label={"Referring Agent Role *"} columnWidth={oneThirdWidth} onChange={v => onChange({ referralRole: v })} />
			</>}
		</Grid>
	);
};

export {
	ReferralSection
};