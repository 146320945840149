// auth provider
import {
	ContextHolder,
	useAuth as useFronteggAuth,
	useLoginWithRedirect
} from "@frontegg/react";
import {AuthenticationContextType} from "../types/auth";
import {useCallback, useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = (): AuthenticationContextType => {
	const location = useLocation();
	const {user, isAuthenticated } = useFronteggAuth();
	const firstName = user?.name?.split("(")[0] ?? "";
	const loginWithRedirect = useLoginWithRedirect();
	const {tenant} = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (tenant !== "civida") navigate("/civida/pages/dashboard");
	}, [tenant, navigate]);
	
	useEffect(() => {
		const redirectUri = sessionStorage.getItem("ppulus-redirect");
		if (!redirectUri || !isAuthenticated)
			return;

		sessionStorage.removeItem("ppulus-redirect");
		navigate(redirectUri);
	}, [navigate, isAuthenticated]);

	const logout = useCallback(() => {
		const baseUrl = ContextHolder.getContext().baseUrl;
		window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}/${tenant}`;
	}, []);

	return {
		user,
		firstName,
		isInitialized: true,
		isLoggedIn: isAuthenticated,
		login: () => {
			sessionStorage.setItem("ppulus-redirect", location.pathname.replace("/landing", "/dashboard"));
			loginWithRedirect({redirectUri: `${sessionStorage.getItem("ppulus-redirect")}`});
		},
		logout
	};
};
export default useAuth;