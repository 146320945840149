import { ApplicationType } from "./enums";
import {ReactElement} from "react";
import {renderToStaticMarkup} from "react-dom/server";
import {IFileInfo} from "./application";

export type GridWidth = {
    xs: number;
    sm: number;
    md: number;
}

export const oneThirdWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 4
};

export const oneQuarterWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 3
};


export const halfWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 6
};

export const twoThirdWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 8
};

export const fullWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 12
};

export const controlMinWidth = 250;

export const isEmpty = (value: object | [] | undefined) => !value || !Object.keys(value).filter(k => k !== "validated").length;

export const hasAnyFalseyItem = (value: object | undefined) => {
	if (!value) return true;
	return Object.values(value).some((v) => !v);
};

export const isAllUndefinedOrNull = (value: object | any[]) => {
	if (!value) return true;

	if (Array.isArray(value)) return value.every((element) => element === undefined);

	return Object.values(value).every((v) => v === undefined || v === null);
};

export const hasProperty = (obj: object, prop: string) => Object.prototype.hasOwnProperty.call(obj, prop);

const sumNumber = (value: number): number => {
	return [...`${value}`]
		.map(c => parseInt(c))
		.reduce((x, y) => x + y);
};
export const isValidSin = (value: string): boolean => {
	if (!value?.match(/^[0-9 ]*$/))
		return false;

	const numbersOnly = [...value]
		.map(c => parseInt(c))
		.filter(v => isFinite(v));

	if (numbersOnly.length !== 9)
		return false;

	return !(numbersOnly
		.map((v, i) => {
			const product = v * (i % 2 + 1);
			return product < 10 ? product : sumNumber(product);
		})
		.reduce((x, y) => x + y) % 10);
};

export const isValidEmail = (value:string | undefined): boolean => {
	const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/i;
	return value?.match(emailRegex) != null;
};

export const ApplicationContent = {
	[ApplicationType.Standard]: {
		heading: "Application"
	},
	[ApplicationType.AnnualReview]: {
		heading: "Annual Review"
	},
	[ApplicationType.InterimReview]: {
		heading: ""
	}
};

export function CalculateAge(dateOfBirth: Date | undefined): number | undefined {
	if (!dateOfBirth) return undefined;
	const today = new Date();
	const month = today.getUTCMonth() - dateOfBirth.getUTCMonth();
	let age = today.getUTCFullYear() - dateOfBirth.getUTCFullYear();

	if (month < 0 || month === 0 && today.getUTCDate() < dateOfBirth.getUTCDate()) return --age;
	return age;
}

export const getDataUri = (svg: ReactElement) => `url("data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(svg))}")`;

export const fileEquivalent = (file1: File | IFileInfo, file2: IFileInfo) => file1.name === file2.name && file1.type === file2.type && file1.size === file2.size;

export interface SortOptions<T> {
	key?: keyof T;
	compareFn?: (left: T, right: T) => number;
	ascending?: boolean;
}

export function sortBy<T>(array: T[], options?: SortOptions<T>): T[] {
	if (!array?.length)
		return [];

	const { key, compareFn, ascending = true } = options || {};
	const modifier = ascending ? 1 : -1;

	function defaultCompare(left: T, right: T) {
		if (left === right)
			return 0;
		
		if (typeof left === "string" && typeof right === "string")
			return left.localeCompare(right); 
		
		if (typeof left === "boolean" && typeof right === "boolean") 
			return left ? 1 : -1; //we know right value does not equal left value, so whatever the value of left value is all we care about 
		
		return left > right ? 1 : -1; //This also works for number types
	}

	return [...array]
		.map(i => key ? i[key] : i as any)
		.sort((left, right) => (compareFn ?? defaultCompare)(left, right) * modifier);
}