import {Housing} from "./housing";
import {ApplicantInformation} from "./applicantInformation";
import {Household} from "./household";
import {Situation} from "./situation";
import {ApplicationDocuments} from "./documents";
import {ApplicationContacts} from "./contacts";
import {ProgramApplications} from "./programApplications";
import {ApplicationStatus, ApplicationType} from "../enums";
import {ApplicationConsent} from "./consent";
import {DateValue} from "../DateValue";
import {hasErrors} from "../errorState";
import { Circumstances } from "./circumstances";
import { ProgramDetails } from "./programDetails";

interface IBuilder<T> {
	build(): T | undefined;
	validate(): IBuilder<T>;
}

interface IApplication {
	id: string;
	tenantId: string;
	screeningId?: string;
	userId: string;
	code: string;
	confirmation: string;
	type: ApplicationType;
	applicationIncome: number;
	status: ApplicationStatus;
	applicant?: ApplicantInformation;
	housing?: Housing;
	household?: Household;
	situation?: Situation;
	documents: ApplicationDocuments;
	contacts?: ApplicationContacts;
	programs?: ProgramApplications;
	consent?: ApplicationConsent;
	createdOn?: Date;
	dueDate: DateValue;
	lastStatusUpdateOn: DateValue;
	circumstances?: Circumstances;
	programDetails?: ProgramDetails;
}

class ApplicationBuilder implements IBuilder<IApplication>, IApplication {
	readonly id: string;
	readonly userId: string;
	readonly tenantId: string;
	readonly type: ApplicationType;
	readonly code: string;
	readonly confirmation: string;
	readonly applicationIncome: number;
	readonly status: ApplicationStatus;
	readonly screeningId?: string;
	readonly applicant?: ApplicantInformation;
	readonly housing?: Housing;
	readonly household?: Household;
	readonly situation?: Situation;	
	readonly documents: ApplicationDocuments;
	readonly contacts?: ApplicationContacts;
	readonly consent?: ApplicationConsent; 
	readonly programs?: ProgramApplications;
	readonly createdOn?: Date;
	readonly dueDate: DateValue;
	readonly lastStatusUpdateOn: DateValue;
	protected readonly validated: boolean;
		
	constructor(from?: Partial<IApplication>) {
		this.id = from?.id ?? crypto.randomUUID();
		this.userId = from?.userId ?? "";
		this.tenantId = from?.tenantId ?? "";
		this.code = from?.code ?? "";
		this.confirmation = from?.confirmation ?? "";
		this.type = from?.type ?? ApplicationType.Standard;
		this.applicationIncome = from?.applicationIncome ?? 0;
		this.status = from?.status ?? ApplicationStatus.Draft;
		this.screeningId = from?.screeningId;
		this.applicant = from?.applicant && new ApplicantInformation(from.applicant);
		this.housing = from?.housing && new Housing(from.housing);
		this.household = from?.household && new Household(from.household);
		this.situation = from?.situation && new Situation(from.situation);		
		this.documents = new ApplicationDocuments(from?.documents);
		this.contacts = from?.contacts && new ApplicationContacts(from.contacts);
		this.consent = new ApplicationConsent(from?.consent);
		this.programs = new ProgramApplications(from?.programs);
		this.createdOn = from?.createdOn ?? undefined;
		this.dueDate = new DateValue(from?.dueDate ?? new Date());
		this.lastStatusUpdateOn = new DateValue(from?.lastStatusUpdateOn ?? new Date());
		this.validated = (from as ApplicationBuilder)?.validated;
		
		if (this.validated) {
			this.applicant = this.applicant?.validate();
			this.housing = this.housing?.validate();
			this.household = this.household?.validate();
			this.situation = this.situation?.validate();
			this.documents = this.documents.validate();
			this.contacts = this.contacts?.validate();
			this.consent = this.consent.validate();
			this.programs = this.programs.validate();
		}
	}
	
	build(): Application | undefined {
		return !hasErrors(this.validate()) 
			? new Application(this)
			: undefined;
	}

	validate(): ApplicationBuilder {
		return new ApplicationBuilder({...this, validated: true});
	}

	get isGbv(): boolean
	{
		return false;
	}
}

class Application {
	readonly id: string;
	readonly tenantId: string;
	readonly screeningId?: string;
	readonly userId: string;
	readonly code: string;
	readonly confirmation: string;
	readonly type: ApplicationType;
	readonly applicationIncome: number;
	readonly status: ApplicationStatus = ApplicationStatus.Submitted;
	readonly applicant: ApplicantInformation;
	readonly housing: Housing;
	readonly household: Household;
	readonly situation: Situation;	
	readonly documents: ApplicationDocuments;
	readonly contacts: ApplicationContacts;
	readonly programs: ProgramApplications;
	readonly consent: ApplicationConsent;
	readonly createdOn: Date;
	readonly dueDate: DateValue;
	readonly lastStatusUpdateOn: DateValue;

	constructor(from: IApplication) {
		this.id = from.id;
		this.tenantId = from.tenantId;
		this.screeningId = from.screeningId;
		this.userId = from.userId;
		this.code = from.code;
		this.confirmation = from.confirmation;
		this.type = from.type;
		this.applicationIncome = from.applicationIncome;
		this.applicant = from.applicant!;
		this.housing = from.housing!;
		this.household = from.household!;
		this.situation = from.situation!;		
		this.documents = from.documents!;
		this.contacts = from.contacts!;
		this.consent = from.consent!; 
		this.programs = new ProgramApplications(from.programs);
		this.createdOn = from?.createdOn ?? new Date();
		this.dueDate = new DateValue(from?.dueDate ?? new Date());
		this.lastStatusUpdateOn = new DateValue(from?.lastStatusUpdateOn ?? new Date());
	}
}

export {Application, ApplicationBuilder};
export type {IBuilder, IApplication};