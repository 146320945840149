function getInitials(fullName?: string): string {
	if (!fullName) {
		return "";
	}

	const nameParts = fullName.split(" ");
	return nameParts?.map(part => [...part].shift()?.toUpperCase()).join("");
}

export { getInitials };
