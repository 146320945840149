import styles from "styles/supportingDocuments.module.scss";

import {ApplicationDocuments, ApplicationType, BankInfo, fullWidth, IApplicationDocuments, IFileInfo} from "../library";
import {FileUploader} from "./FileUploader";
import {Button, Grid, TextField} from "@mui/material";
import {useApplication} from "hooks";
import {useEffect, useState} from "react";

interface BankInfoProps {
	addDocuments: (files: File[], property: keyof IApplicationDocuments) => void;
	removeDocuments: (file: IFileInfo, property: keyof IApplicationDocuments) => void;
}

const BankInformationForm = ({addDocuments, removeDocuments}: BankInfoProps) => {
	const {application: {type, documents: {bankInfo, eftDetails, errorState, ...otherDocuments}}, mutations: {set, saveAsync}} = useApplication();
	const [state, setState] = useState(bankInfo);

	useEffect(() => {
		setState(bankInfo);
	}, [bankInfo]);
	
	const updateBankInfo = (value: Partial<BankInfo>) => {
		setState(new BankInfo({...state, ...value}));
	};
	
	const setGlobalState = () =>
		set({documents: new ApplicationDocuments({...otherDocuments, eftDetails, bankInfo: state})});
	
	return (
		<FileUploader label={"Void Cheque and/or Banking (EFT) Details"}
		              caption={"Please provide us with a void cheque or your banking details where you would like your rental assistance payments to be deposited into."}
		              files={eftDetails.files}
		              error={errorState.eftDetails}
		              onAdd={files => addDocuments(files, "eftDetails")}
		              onRemove={file => removeDocuments(file, "eftDetails")}>
			<Grid container>
				<Grid item {...fullWidth}>
					<TextField
						className={`${styles.eftInput} ${styles.name}`}
						label={"Bank account holder's legal name"}
						type={"text"}
						value={state?.holderName ?? ""}
						onChange={e => updateBankInfo({holderName: e.target.value})}
					/>
				</Grid>
				<Grid item>
					<TextField
						className={`${styles.eftInput} ${styles.institution}`}
						label={"Institution Number"}
						type={"number"}
						value={state?.institution ?? ""}
						onChange={e => updateBankInfo({institution: e.target.value})}
					/>
				</Grid>
				<Grid item>
					<TextField
						className={styles.eftInput}
						label={"Transit Number"}
						type={"number"}
						value={state?.transit ?? ""}
						onChange={e => updateBankInfo({transit: e.target.value})}
					/>
				</Grid>
				<Grid item>
					<TextField
						className={styles.eftInput}
						label={"Account Number"}
						type={"number"}
						value={state?.account ?? ""}
						onChange={e => updateBankInfo({account: e.target.value})}
						onBlur={setGlobalState}
					/>
				</Grid>
				{type !== ApplicationType.Standard ?
					<Grid item className={styles.submitButton}>
						<Button onClick={() => saveAsync({})} variant={"contained"}>UPDATE</Button>
					</Grid> : <></>}

			</Grid>
		</FileUploader>
	);
};

export {BankInformationForm};