import styles from "styles/situation.module.scss";

import React, {useState} from "react";
import {SectionHeading, SectionSubHeading} from "ui-component/SectionHeading";
import {Grid, Typography} from "@mui/material";
import {fullWidth, GbvSituation, twoThirdWidth} from "library";
import {YesNoSilentButton} from "components";
import {ApplicationPreviousNext} from "../application/ApplicationPreviousNext";
import {hasErrors} from "library/errorState";
import { useApplication } from "hooks";

const Situation = () => {
	const {application, mutations: {saveAsync}} = useApplication();
	const [state, setState] = useState(new GbvSituation(application?.situation));
	
	const onChange = (value: Partial<GbvSituation>) => setState(new GbvSituation({...state, ...value}));
	
	const onPrevious = () => "../";
	const onNext = () => {
		const validated = state.validate();
		onChange(validated);

		if (hasErrors(validated))
			return;

		void saveAsync({situation: validated});
		return "../intake";
	};
	
	return (
		<form>
			<SectionHeading>Your Circumstances and Additional Details</SectionHeading>
			<SectionSubHeading>Tell us more about your household</SectionSubHeading>
			<Grid item {...fullWidth} className={styles.introText}>
				The following household information questions are being asked to help prioritize households facing an urgent need for housing.
				These questions ask about personal information and experience and are being collected to support those who may face greater barriers to accessing housing.
				You may also choose not to disclose this information and can do so by selecting "prefer not to say".
			</Grid>

			<Grid container spacing={3} alignItems={"flex-end"} mb={8}>
				<Grid item {...fullWidth}>
					<Typography variant={"h3"} className={styles.questionGroupHeading}>Housing Information</Typography>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>
						Are you at risk of, or currently experiencing or transitioning out of homelessness? 
						Risk of homelessness may include if your current housing does not meet the adequacy or suitability standards, or if you have an eviction notice and must move out of your housing through no fault of your own.
					</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togHomeless" value={state?.homeless} error={state?.errorState.homeless}
						                   onChange={v => onChange({ homeless: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>
						Are you currently in a shared accommodation living with another family?
					</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togSharedAccomodation"
						                   value={state?.sharedAccommodation}
						                   error={state?.errorState.sharedAccommodation}
						                   onChange={v => onChange({ sharedAccommodation: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>
						Do you, or anyone in your household require physical accommodations for a disability that is not being met in your current housing?
					</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togAccommodationForDisability" value={state?.accommodationForDisability}
						                   error={state?.errorState.accommodationForDisability}
						                   onChange={v => onChange({ accommodationForDisability: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>
						Are you losing your current accommodation due to an emergency situation?
					</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togEmergencySituation" value={state?.emergencySituation}
						                   error={state?.errorState.emergencySituation}
						                   onChange={v => onChange({ emergencySituation: v })} />
					</Grid>
				</Grid>
				
				<Grid item {...fullWidth}>
					<Typography variant={"h3"} className={styles.questionGroupHeading}>Target Population</Typography>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>Do you, or a member of your household, identify as an Indigenous person in Canada such as First Nations, Inuit, or Metis?</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togIndigenous" value={state?.indigenous} error={state?.errorState.indigenous}
						                   onChange={v => onChange({ indigenous: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>Are you or a member of your household under the age of 24 and transitioning out of government care (e.g. foster home)?</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togTransitioningOutOfCare" value={state?.transitioningOutOfCare}
						                   error={state?.errorState.transitioningOutOfCare}
						                   onChange={v => onChange({ transitioningOutOfCare: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>Do you or a member of your household have a physical or mental disability?</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togHasDisability" value={state?.hasDisability} error={state?.errorState.hasDisability}
						                   onChange={v => onChange({ hasDisability: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>Are you or a member of your household a recent immigrant or refugee who has moved to Canada from another country in the last 5 years?</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togImmigrant" value={state?.immigrant} error={state?.errorState.immigrant}
						                   onChange={v => onChange({ immigrant: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>
						Do you, or a member of your household, identify as racialized - Black, Indigenous, or Person of Colour (BIPOC) or visible minority (persons who are non-Caucasian in race or non-white in colour)?
					</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togIsMinority" value={state?.isMinority} error={state?.errorState.isMinority}
						                   onChange={v => onChange({ isMinority: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>Do you or a member of your household identify as a member of the 2SLGBTQ+ community?</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togLgbtq" value={state?.lgbtq} error={state?.errorState.lgbtq}
						                   onChange={v => onChange({ lgbtq: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>Are you or a member of your household a former member of the Canadian Armed Forces</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togArmedForceMember" value={state?.armedForceMember}
						                   error={state?.errorState.armedForceMember}
						                   onChange={v => onChange({ armedForceMember: v })} />
					</Grid>
				</Grid>
				
				<Grid item {...fullWidth}>
					<Typography variant={"h3"} className={styles.questionGroupHeading}>Financial</Typography>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>
						Are you or a member of your household receiving social assistance, including Income Support, Learner Income Support,
						Assured Income for the Severely Handicapped, Alberta Seniors Benefit, Guaranteed Income Supplement, Old Age Security?
					</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togReceivingOtherBenefits" value={state?.receivingOtherBenefits}
						                   error={state?.errorState.receivingOtherBenefits}
						                   onChange={v => onChange({ receivingOtherBenefits: v })} />
					</Grid>
				</Grid>

				<Grid item {...fullWidth}>
					<Typography variant={"h3"} className={styles.questionGroupHeading}>Health and Safety</Typography>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>Are you or a member of your household fleeing violence? This may include
						domestic violence, family violence, or human trafficking</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togFacingViolence" value={state?.facingViolence} error={state?.errorState.facingViolence}
						                   onChange={v => onChange({ facingViolence: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>Are you or a member of your household currently dealing with mental
						health concerns or addictions?</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togHasMentalConcerns" value={state?.hasMentalConcerns}
						                   error={state?.errorState.hasMentalConcerns}
						                   onChange={v => onChange({ hasMentalConcerns: v })} />
					</Grid>
				</Grid>
				<Grid item {...fullWidth}>
					<Typography variant={"h5"}>
						Are you currently living in a situation that is unsafe to you and your family's physical safety
						and wellbeing
						(e.g. housing contributing to a worsening health condition, family violence, neighbourhood gang
						activity, racially motivated crimes, etc..)?
					</Typography>
					<Grid item {...twoThirdWidth} className={styles.answer}>
						<YesNoSilentButton testElementName="togThreatToPhysicalWellBeing" value={state?.threatToPhysicalWellBeing}
						                   error={state?.errorState.threatToPhysicalWellBeing}
						                   onChange={v => onChange({ threatToPhysicalWellBeing: v })} />
					</Grid>
				</Grid>
			</Grid>
			
			<Grid item {...fullWidth}>
				<ApplicationPreviousNext application={application} 
				                         previousText={"Back to Applicant"}
				                         onPrevious={onPrevious}
				                         nextText={"Next to GBV Intake"}
				                         onNext={onNext}
				/>
			</Grid>
		</form>
	);
};

export {
	Situation
};