import { RouteObject } from "react-router-dom";
import {IntakeSection} from "../views/pages/gbv/Intake.Section";
import {Applicant} from "../views/pages/gbv/Applicant";
import {Review} from "../views/pages/gbv/Review";
import {Situation} from "../views/pages/gbv/Situation";
import { Submission } from "views/pages/application/Submission";
import { ApplicationLoader } from "views/pages/application/ApplicationLoader";
import {SupportingDocumentsForm} from "views/pages/application/SupportingDocuments";
import {SupportingDocumentsSubmitted} from "../views/pages/application/SupportingDocumentsSubmitted";

const useGbvRoutes = (): RouteObject[] => {
	return [
		{
			path: ":code/*",
			children: [
				{
					path: "intake/*",
					element: <ApplicationLoader><IntakeSection /></ApplicationLoader>
				},
				{
					path: "situation/*",
					element: <ApplicationLoader><Situation /></ApplicationLoader>
				},
				{
					path: "review/*",
					element: <ApplicationLoader><Review /></ApplicationLoader>
				},
				{
					path: "submission/*", 
					element: <ApplicationLoader><Submission programName={"Gender Based Violence Benefit"} documentsNeeded={["Banking Information", "Lease Agreement (if applicable)", "Safe Spaces Certification (if applicable)"]} /></ApplicationLoader>
				},
				{
					path: "documents/*",
					element: <ApplicationLoader><SupportingDocumentsForm /></ApplicationLoader>
				},
				{
					path: "docsubmission/*",
					element: <ApplicationLoader><SupportingDocumentsSubmitted /></ApplicationLoader>
				},
				{
					index: true,
					element: <ApplicationLoader><Applicant /></ApplicationLoader>
				}
			]
		},
	];
};

export {
	useGbvRoutes
};