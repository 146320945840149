
export interface IFeatureFlag {
	id: string;
	description: string;
	isEnabled?: boolean;
}

class FeatureFlag implements IFeatureFlag {
	id: string;
	description: string;
	isEnabled: boolean;
			
	constructor(from?: Partial<IFeatureFlag>) {
		this.id = from?.id ?? "";
		this.description = from?.description ?? "";
		this.isEnabled = from?.isEnabled ?? false;
	}
}

export default FeatureFlag;
