import { CP_PUB_KEY } from "config";
import { useCanadaPostExternalScript } from "./useCanadaPostExternalScript";
import { useEffect, useState } from "react";

declare let pca: any;

export interface IAddress {
  [key: string]: string | undefined;
  
  streetNumber?: string;
  streetName?: string;
  unit?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
}

interface ICanadaPostAddress {
	BuildingNumber: string,
	Street: string,
	SubBuilding : string,
	City: string,
	Province: string,
	PostalCode: string,
	CountryName: string,
}

const fieldNameMapper = [
	{ formFieldChars: /.*streetNumber/i, cpField: "BuildingNumber"},
	{ formFieldChars: /.*streetName/i, cpField: "Street"},
	{ formFieldChars: /.*lookup/i, cpField: ""},
];

const useCanadaPostAutoComplete = (idFields: string[]) => {
	const resourcesLoaded = useCanadaPostExternalScript();
	const [userAddress, setUserAddress] = useState<IAddress>({});

	useEffect(() => {
		if (resourcesLoaded) {
			const options = { key: CP_PUB_KEY };
			const fields = idFields.map(id => {
				const element = id;
				const mode = pca.fieldMode.DEFAULT;
				const field = fieldNameMapper.find(e => id.match(e.formFieldChars))?.cpField;

				if (typeof field === "undefined") {
					return {};
				}

				return { element, field, mode };
			});

			const control: any = new pca.Address(fields, options);
			control.listen("populate", function (address: ICanadaPostAddress) {
				const selectedAddress: IAddress = {
					streetNumber: address.BuildingNumber,
					streetName: address.Street,
					unit: address.SubBuilding,
					city: address.City,
					province: address.Province,
					postalCode: address.PostalCode,
					country: address.CountryName,
				};

				setUserAddress(selectedAddress);
			});
		}
	}, [resourcesLoaded]);

	return userAddress;
};

export { useCanadaPostAutoComplete };