import {useDispatch, useSelector} from "store";
import {ApplicationBuilder, ApplicationStatus, ApplicationType, GbvBuilder, LoadStatus, ProgramApplications} from "library";
import {setApplication, reset, saveProgress} from "store/slices/application";
import {set as setGbv, reset as resetGbv, startNewApplication, saveProgress as saveGbvProgress} from "../store/slices/gbvApplication";
import useNavigator from "./useNavigator";
import {useEffect, useMemo} from "react";
import {submitApplication} from "../store/slices/submission";
import useAuth from "./useAuth";
import {useParams} from "react-router-dom";

type ApplicationMutations<T extends ApplicationBuilder> = {
	reset: () => void;
	set: (payload: Partial<T>, isGbv?: boolean) => void;
	saveAsync: (payload: Partial<T>) => Promise<void>;
	submit: () => Promise<void>;
};

type ApplicationStrategy<T extends ApplicationBuilder> = {
	type: ApplicationType;
	loading: boolean;
	isStale: boolean;
	application: T;
	startNewGbv: () => void;
	mutations: ApplicationMutations<T>;
};

const useGbv = () => {
	const {user} = useAuth();
	const {id} = useParams();
	const {status, builder: gbvApplication, isStale} = useSelector(s => s.gbvApplication);
	const navigate = useNavigator();
	const dispatch = useDispatch();

	return {
		type: gbvApplication.type,
		loading: status === LoadStatus.Loading,
		isStale: isStale,
		application: gbvApplication as GbvBuilder,
		startNew: () => {
			if (!user?.id) {
				navigate("/pages/registration/gbv");
				return;
			}

			dispatch(startNewApplication(new GbvBuilder({
				userId: user!.id,
				programs: new ProgramApplications(["GBV"])
			}))).unwrap()
				.then(r => id !== r.id && navigate(`/pages/gbv/${r.id}`));
		},
		mutations: {
			reset: () => dispatch(resetGbv()),
			set: (payload: Partial<GbvBuilder>) => dispatch(setGbv(payload)),
			saveAsync: async (payload: Partial<ApplicationBuilder>) => void await dispatch(saveGbvProgress(payload)),
			submit: async () => {
				await dispatch(submitApplication(gbvApplication))
					.unwrap()
					.then(submission => dispatch(setGbv({confirmation: submission.confirmation, status: ApplicationStatus.Submitted})));
			}
		} as ApplicationMutations<GbvBuilder>
	};
};

const useApplication = <T extends ApplicationBuilder>(): ApplicationStrategy<T> => {
	const gbv = useGbv();
	const standardApplication = useSelector(s => s.application);
	const navigate = useNavigator();
	const dispatch = useDispatch();
	
	const hookResult = useMemo(() => ({
		type: gbv.application.type ?? standardApplication.builder.type,
		loading: gbv.loading || standardApplication.status === LoadStatus.Loading,
		isStale: gbv.application.code ? gbv.isStale : standardApplication.isStale,
		application: gbv.application.code ? gbv.application : standardApplication.builder,
		startNewGbv: gbv.startNew,
		mutations: gbv.application.code
			? gbv.mutations
			: {
				reset: () => dispatch(reset()),
				set: (payload: Partial<ApplicationBuilder>, isGbv?: boolean) => dispatch(isGbv ? setGbv(payload) : setApplication(payload)),
				saveAsync: async (payload: Partial<ApplicationBuilder>) => void await dispatch(saveProgress(payload)),
				submit: async () => {
					await dispatch(submitApplication(standardApplication.builder))
						.unwrap()
						.then(() => dispatch(setApplication({ status: ApplicationStatus.Submitted })));
				}
			} as ApplicationMutations<T>,
	}), [gbv, standardApplication, setApplication, saveProgress, submitApplication]);
	
	useEffect(() => {
		if (hookResult.loading)
			return;
		
		if(standardApplication.status === LoadStatus.DoesNotExist) 
			navigate("/pages/dashboard", { relative: "path" });
	}, [hookResult, navigate, standardApplication.status]);
	
	return hookResult as ApplicationStrategy<T>;
};

export {useApplication};
export type {ApplicationMutations};