import {fullWidth, oneThirdWidth, ProgramDetails, ProgramDuration} from "library";
import {SectionSubHeading} from "ui-component/SectionHeading";
import {Grid, Stack} from "@mui/material";
import {AppSelectList} from "components";
import React from "react";

type ProgramSpecificsSectionProps = {
	programDetails: ProgramDetails;
	onChange: (value: Partial<ProgramDetails>) => void;
}

const ProgramSpecificsSection = ({programDetails, onChange}: ProgramSpecificsSectionProps) => {
	return (
		<Grid item {...fullWidth}>
			<SectionSubHeading>Program Specific Details</SectionSubHeading>

			<Grid item {...fullWidth}>
				<p>
					Gender-based Violence Benefit is a short term benefit that can be provided up to one year from approval into program.
					Please select the duration that best fits your needs.
				</p>
			</Grid>
			
			<Stack direction="row" spacing={2}>
				<Grid item {...oneThirdWidth}>
					<AppSelectList
						testElementName="ddlProgramDuration"
						label={"Program Duration"}
						value={programDetails?.duration}
						items={Object.values(ProgramDuration)}
						error={programDetails.errorState.duration}
						onChange={v => onChange({duration: v as ProgramDuration})}
					/>
				</Grid>

				{programDetails?.duration === "Other" && 
					<Grid item {...oneThirdWidth}>
						<AppSelectList
							testElementName="ddlDurationInMOnths"
							label={"Number of Months"}
							value={`${programDetails?.durationInMonths}`}
							items={Array.from({length: 12}, (_,i) => i + 1).map(i => `${i}`)}
							error={programDetails.errorState.duration}
							onChange={v => onChange({durationInMonths: Number(v)})}
						/>
					</Grid>
				}
			</Stack>
		</Grid>
	);
};

export {
	ProgramSpecificsSection
};