import {FC, ReactNode} from "react";
import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { gridSpacing } from "store/constant";
import LAYOUT_CONST from "constant";


const FormHeader: FC<{ children: JSX.Element[] }> = ({ children }) => {
	const isSmallViewport = useMediaQuery(LAYOUT_CONST.MOBILE_BREAKPOINT);
	return (
		<Grid container spacing={gridSpacing}>
			<Grid item sx={{ mb: isSmallViewport ? 3 : 5 }}>
				<Grid container spacing={gridSpacing}>
					{children}
				</Grid>
			</Grid>
		</Grid>
	);
};

type FormHeaderTextGroupProps = {
	headerInfo?: ApplicationHeaderInfo; // Make the prop optional
};

type ApplicationHeaderInfo = {
	title: string;
	subtitle: string | JSX.Element;
	prompt?: string;
	message: ReactNode;
}

const defaultHeaderInfo: ApplicationHeaderInfo = {
	title: "Rent Assistance Benefit Application",
	subtitle: "Please provide us with some basic information",
	message: "Please complete the form and questions below. Your responses will help us understand your needs better and to determine if the Rent Assistance Benefit program is suitable for you."
};

const FormHeaderTextGroup: FC<FormHeaderTextGroupProps> = ({ headerInfo = defaultHeaderInfo }) => {
	const isSmallViewport = useMediaQuery(LAYOUT_CONST.MOBILE_BREAKPOINT);

	return (
		<FormHeader>
			<Grid item sx={{ mt: 6 }}>
				<Typography
					variant="h1"
					component="div"
					sx={{
						fontSize: "2.5rem",
						fontWeight: 500,
						lineHeight: 1.4,
						textAlign: "left",
						mx: isSmallViewport ? "0px" : "100px"
					}}
				>
					{headerInfo.title}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography
					variant="h1"
					component="div"
					sx={{
						fontSize: "1.5rem",
						fontWeight: 500,
						lineHeight: 1.4,
						textAlign: "left",
						mx: isSmallViewport ? "0px" : "100px"
					}}
				>
					{headerInfo.subtitle}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography
					variant="h4"
					component="div"
					sx={{
						fontWeight: 400,
						lineHeight: 1.4,
						textAlign: "left",
						mx: isSmallViewport ? "0px" : "100px"
					}}
				>
					{headerInfo.message}
				</Typography>
			</Grid>

		</FormHeader>
	);
};

export { FormHeaderTextGroup };
export type { ApplicationHeaderInfo };
