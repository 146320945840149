import {createErrorState, ErrorState, IErrorState} from "../errorState";
import {HousingType, YesNo, YesNoSilent} from "../enums";

interface ICircumstances {
	housingType: HousingType;
	experiencedRecently?: YesNo;
	reasonForBenefit?: string;
	underThreat: YesNoSilent;
	safeSpace: YesNoSilent;
	subsidized: YesNoSilent;
	communityHousing: YesNoSilent;
	longTermHousingPlan?: YesNoSilent;
}

class Circumstances implements Partial<ICircumstances>, IErrorState<Partial<ICircumstances>> {
	readonly housingType?: HousingType;
	readonly experiencedRecently?: YesNo;
	readonly reasonForBenefit?: string;
	readonly underThreat?: YesNoSilent;
	readonly safeSpace?: YesNoSilent;
	readonly subsidized?: YesNoSilent;
	readonly communityHousing?: YesNoSilent;
	readonly longTermHousingPlan?: YesNoSilent;
	private readonly validated: boolean;

	constructor(from?: Partial<ICircumstances>) {
		this.housingType = from?.housingType;
		this.experiencedRecently = from?.experiencedRecently;
		this.reasonForBenefit = from?.reasonForBenefit;
		this.underThreat = from?.underThreat;
		this.safeSpace = from?.safeSpace;
		this.subsidized = from?.subsidized;
		this.communityHousing = from?.communityHousing;
		this.longTermHousingPlan = from?.longTermHousingPlan;
		this.validated = (from as Circumstances)?.validated;
	}

	get errorState(): ErrorState<Partial<ICircumstances>> {
		const genericMessage = "Please specify a value.";

		return this.validated ?
			createErrorState<ICircumstances>({
				housingType: !this.housingType ? "Housing type required." : "",
				experiencedRecently: !this.experiencedRecently ? genericMessage : "",
				reasonForBenefit: this.experiencedRecently === "No" && !this.reasonForBenefit?.length ? "Please explain why benefit is needed." : "",
				underThreat: !this.underThreat ? genericMessage : "",
				safeSpace: !this.safeSpace ? genericMessage : "",
				subsidized: !this.subsidized ? genericMessage : "",
				communityHousing: !this.communityHousing ? genericMessage : "",
				longTermHousingPlan: !this.longTermHousingPlan ? genericMessage : ""
			}) : {};
	}

	validate(): Circumstances {
		return new Circumstances({ ...this, validated: true });
	}
}

export { Circumstances };
export type { ICircumstances };