import {useDispatch, useSelector} from "../store";
import {useEffect} from "react";
import {getIncomeTypes} from "../store/slices/incomeTypes";

const useIncomeTypes = () => {
	const { value } = useSelector(s => s.incomeTypes);
	const dispatch = useDispatch();
	
	useEffect(() => {
		dispatch(getIncomeTypes());
	}, []);
	
	return {
		incomeSources: value
	};
};

export {useIncomeTypes};