import {FC, ReactElement, useCallback, useEffect, useState} from "react";
import {FormControlLabel, Grid, Switch} from "@mui/material";
import { CitizenStatus, Gender, fullWidth, oneThirdWidth, halfWidth, PhoneNumberType, StudentStatus, RelationToApplicant, Dependant, Adult, HouseholdMember, DEPENDANT_RELATIONSHIPS, ADULT_RELATIONSHIPS, CalculateAge } from "library";
import { SectionSubHeading } from "ui-component/SectionHeading";
import { GridText } from "components/GridText";
import { AppSelectList } from "components/AppSelectList";
import { GridDatePicker } from "./GridDatePicker";
import { GridPhone } from "./GridPhone";
import { ApplicantInformation } from "library";
import { Address, Phone } from "library/types";
import { AddressForm } from "./AddressForm";
import {useApplication} from "hooks";

type ApplicantType = ApplicantInformation | Adult | Dependant;

type Props<T extends ApplicantType> = {
	value: T;
	onChange: (value: Partial<ApplicantType>) => void;
	columnWidth?: { xs: number, sm: number, md: number };
};

const ApplicantInformationForm: <T extends ApplicantType>(props: Props<T>) => ReactElement<Props<T>> = ({
	value,
	onChange,
	columnWidth
}) => {
	const [person, setPerson] = useState(value);
	const {application:{applicant}} = useApplication();

	useEffect(() => {
		setPerson(value);
	}, [value]);

	const set = useCallback((value: Partial<ApplicantType>) => {
		onChange(value);
	}, [onChange]);

	const isInheritingAddress = (person as Adult).addressSameAsApplicant;
	const isSharingAddresses = (person as Adult).mailingAddressSameAsAddress;

	const onAddressChange = (address?: Address) => {
		if (!isSharingAddresses) {
			set({ address: address });
			return;
		}
		set({
			address: address,
			mailingAddress: address
		});
	};

	const onInheritAddressChange = (checked: boolean) => {
		set({ addressSameAsApplicant: checked });
		checked
			? onAddressChange(applicant?.address)
			: onAddressChange(new Address());
	};

	const onShareAddressChange = (checked: boolean) => {
		checked
			? set({
				mailingAddressSameAsAddress: true,
				mailingAddress: person.address
			})
			: set({
				mailingAddressSameAsAddress: false,
				mailingAddress: new Address()
			});
	};

	return (
		<Grid container>
			{person instanceof HouseholdMember &&
				<Grid container spacing={3}>
					<Grid item {...fullWidth}>
						<SectionSubHeading>Household Member</SectionSubHeading>
					</Grid>
					<Grid item {...(columnWidth ?? oneThirdWidth)}>
						<AppSelectList
							testElementName="ddlRelationship"
							label='Relationship to Applicant'
							value={person.relationToApplicant ?? ""}
							items={person instanceof Adult ? ADULT_RELATIONSHIPS : DEPENDANT_RELATIONSHIPS}
							error={person.errorState.relationToApplicant}
							onChange={v => set({ relationToApplicant: v as RelationToApplicant })}
							autocomplete
						/>
					</Grid>
				</Grid>
			}
			<Grid container spacing={3}>
				<Grid item {...fullWidth}>
					<SectionSubHeading>Personal Information</SectionSubHeading>
				</Grid>
				<GridText testElementName="tbFirstName" value={person.firstName} error={person.errorState.firstName} label={"First Name *"} autocomplete={"given-name"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => set({ firstName: v })} />
				<GridText testElementName="tbMiddleName" value={person.middleName} error={person.errorState.middleName} label={"Middle Name"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => set({ middleName: v })} />
				<GridText testElementName="tbLastName" value={person.lastName} error={person.errorState.lastName} label={"Last Name *"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => set({ lastName: v })} />
				<GridText testElementName="tbPreferredName" value={person.preferredName} error={person.errorState.preferredName} label={"Preferred Name"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => set({ preferredName: v })} />
				<Grid item {...(columnWidth ?? oneThirdWidth)}>
					<AppSelectList
						testElementName="ddlGender"
						label='Gender *'
						value={person.gender ?? ""}
						items={Object.values(Gender)}
						error={person.errorState.gender}
						onChange={v => set({ gender: v as Gender })}
						autocomplete
					/>
				</Grid>
				{person instanceof ApplicantInformation && <>
					<Grid item {...(columnWidth ?? oneThirdWidth)}>
						<AppSelectList
							testElementName="ddlCanadaStatus"
							label='Canada Status'
							items={Object.values(CitizenStatus)}
							value={person.citizenship}
							error={person.errorState.citizenship}
							onChange={v => set({ citizenship: v as CitizenStatus })}
							autocomplete
						/>
					</Grid>

				</>}
				{person instanceof ApplicantInformation && !(person instanceof Adult) &&
					<GridText
						testElementName="tbSin"
						value={person.socialInsuranceNumber}
						error={person.errorState.socialInsuranceNumber}
						label={"Social Insurance Number *"}
						columnWidth={columnWidth ?? oneThirdWidth}
						onChange={v => set({ socialInsuranceNumber: v })}
					/>
				}
				<GridDatePicker disableFuture
					testElementName="tbBirthDate"
					value={person.dateOfBirth}
					error={person.errorState.dateOfBirth}
					label={"Birthdate *"}
					columnWidth={columnWidth ?? oneThirdWidth}
					onChange={v => set({ dateOfBirth: v, age: CalculateAge(v) })}
				/>
			</Grid>

			<Grid container spacing={3}>
				<Grid item {...fullWidth}>
					<SectionSubHeading>Are they a student?</SectionSubHeading>
				</Grid>
				<Grid item {...(columnWidth ?? oneThirdWidth)}>
					<AppSelectList
						testElementName="ddlStudentStatus"
						label='Student Status *'
						value={maskStudentStatus(person.studentStatus)}
						items={Object.values(StudentStatus).map(v => v === StudentStatus.notStudent ? "No" : v)}
						error={person.errorState.studentStatus}
						onChange={v => set({ studentStatus: (v === "No" ? StudentStatus.notStudent : v) as StudentStatus })}
						autocomplete
					/>
				</Grid>
			</Grid>

			{(person instanceof ApplicantInformation || person instanceof Adult) && <>
				<Grid container spacing={3}>
					<Grid item {...fullWidth}>
						<SectionSubHeading>Your current address</SectionSubHeading>
					</Grid>
					{person instanceof HouseholdMember &&
						<Grid item {...fullWidth}>
							<FormControlLabel label="Current address is the same as applicant's address" control={
								<Switch checked={isInheritingAddress} onChange={event => onInheritAddressChange(event.target.checked)} data-sel="togCurrentAddress" />
							} />
						</Grid>
					}
					{(!(person instanceof HouseholdMember) || !isInheritingAddress) &&
						<AddressForm
							prependId={"current"}
							value={person.address ?? new Address()}
							columnWidth={columnWidth}
							inError={!!person.errorState.address}
							onChange={v => onAddressChange(new Address(v))}
						/>
					}
				</Grid>

				<Grid container spacing={3}>
					<Grid item {...fullWidth}>
						<SectionSubHeading>Mailing Address</SectionSubHeading>
					</Grid>
					<Grid item {...fullWidth}>
						<FormControlLabel label="Mailing address is the same as current address" control={
							<Switch checked={isSharingAddresses} onChange={event => onShareAddressChange(event.target.checked)} data-sel="togMailingAddress" />
						} />
					</Grid>
					{!isSharingAddresses &&
						<AddressForm
							prependId={"mailing"}
							value={person.mailingAddress ?? new Address()}
							columnWidth={columnWidth}
							inError={!!person.errorState.mailingAddress}
							onChange={v => set({ mailingAddress: new Address(v) })}
						/>}
				</Grid>
			</>}
			{person instanceof ApplicantInformation &&
				<Grid container spacing={3}>
					<Grid item {...fullWidth}>
						<SectionSubHeading>Contact Information</SectionSubHeading>
					</Grid>
					<Grid container item {...fullWidth} spacing={3}>
						<PhoneForm value={person.primaryPhone ?? new Phone()} typeLabel={"Primary Phone Type"} columnWidth={columnWidth} onChange={v => set({primaryPhone: v})} />
						<PhoneForm value={person.secondaryPhone ?? new Phone()} typeLabel={"Secondary Phone Type"} columnWidth={columnWidth} onChange={v => set({secondaryPhone: v})} />
					</Grid>
				</Grid>
			}
		</Grid>
	);
};

interface IPhoneProps {
	value: Partial<Phone>;
	typeLabel: string;
	columnWidth?: { xs: number, sm: number, md: number };
	phoneType?: "PrimaryPhone" | "SecondaryPhone";
	onChange: (value: Partial<Phone>) => void;
}

const PhoneForm: FC<IPhoneProps> = ({ value, typeLabel, columnWidth, phoneType, onChange }) => {
	const handleChange = useCallback((v: Partial<Phone>) => {
		onChange(new Phone({ ...value, ...v }));
	}, [value]);

	return (
		<>
			<GridPhone testElementName={"tb" + phoneType} value={value.number ?? ""} columnWidth={columnWidth ?? halfWidth} onChange={v => handleChange({ number: v })} />
			<Grid item {...(columnWidth ?? halfWidth)}>
				<AppSelectList
					testElementName={"ddl" + phoneType}
					label={typeLabel}
					value={value.type}
					items={Object.values(PhoneNumberType)}
					error={""}
					onChange={v => handleChange({ type: v as PhoneNumberType })}
					autocomplete
				/>
			</Grid>
		</>
	);
};

function maskStudentStatus(status: StudentStatus | undefined) {
	if (!status) return;
	return status === StudentStatus.notStudent ? "No" : status;
}

export { ApplicantInformationForm };