import styles from "styles/application.module.scss";

import {fullWidth} from "library";
import {Alert, Button, Grid, Typography} from "@mui/material";
import {Routes, useApplication} from "hooks";
import { SectionHeading } from "ui-component/SectionHeading";
import AnimateButton from "ui-component/extended/AnimateButton";
import { SubmissionConfirmationBox } from "ui-component/SubmissionConfirmationBox/SubmissionConfirmationBox";
import { useSelector } from "store";
import { Navigate } from "react-router-dom";
import {PROVIDER_NAME, PROVIDER_EMAIL, PROVIDER_PHONE} from "config";
import useNavigator from "hooks/useNavigator";
import {IconFileUpload} from "@tabler/icons";
import {useMemo} from "react";

type SubmissionProps = {
	programName: string;
	documentsNeeded?: string[];
};

const Submission = ({programName, documentsNeeded = ["Notice of Assessment", "Lease Agreement"]}: SubmissionProps) => {
	const {value: submission} = useSelector(s => s.submission);
	const {mutations: {reset}} = useApplication();
	const navigate = useNavigator();
	
	if (!submission)
		return <Navigate to={"../review"} relative={"path"} />;
	
	const gotoDashboard = () => {
		navigate(Routes.dashboard);
		reset();
	};
	
	const email = useMemo(() => programName !== "Gender Based Violence Benefit" ? PROVIDER_EMAIL : "gbv@civida.ca", [programName]);

	return (
		<Grid item sx={{ pl: 8, pr: 8 }}>
			<SectionHeading>{`Application ${submission.code} Submitted`}</SectionHeading>
			<Typography variant="h3" className={styles.submissionThankYou}>
				Thank you for your {programName} application submission.
			</Typography>
			<Typography variant="h4" sx={{ mb: 5 }}>
				Please be patient while your application is being processed.
			</Typography>

			<SubmissionConfirmationBox title = "Your Application #" confirmation={submission.code} />

			<Alert variant={"filled"} color={"warning"} icon={<IconFileUpload />} sx={{marginBottom: "3rem"}}>
				<h3>What about my supporting documents?</h3>
				<p>
					There is no need to provide them at this moment.
					You will be contacted when it is time to upload your supporting documents.
					Please do not email any files unless asked to do so.
				</p>
			</Alert>
			
			<SectionHeading>Next Steps</SectionHeading>
			<Typography variant="body2">
				{PROVIDER_NAME} will contact you via email to indicate if your application is approved or ineligible.
				Please monitor your email for your application results.
				If your application is approved, you will be required to submit supporting documentation.
				Please have the following ready for when we contact you:
				<ul>
					{documentsNeeded.map((d, i) => <li key={i}>{d}</li>)}
				</ul>
			</Typography>

			<Typography variant="body2">
				Contact our Customer Success team at <a href={`mailto: ${email}`}>{email}</a> or {PROVIDER_PHONE} if you wish to inquire about your recently submitted application.
			</Typography>

			<Grid container {...fullWidth} justifyContent="center" sx={{ my: 4}} >
				<Grid item>
					<AnimateButton>
						<Button color="secondary" size="large" variant="contained" sx={{ my: 2 }} onClick={gotoDashboard}>
							GO TO MY DASHBOARD
						</Button>
					</AnimateButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

export { Submission };