/* eslint-disable react/prop-types */

import { ReactElement } from "react";
import { FormControl, FormHelperText, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { YesNo, YesNoSilent, YesNoUnsure } from "library";

interface IYesNoButtonProps<T extends YesNoUnsure | YesNoSilent | YesNo> {
	label?: string;
	value: T | undefined;
	error?: string;
	testElementName?: string;
	onChange: (value: T) => void;
}

const YesNoUnsureButton: (props: IYesNoButtonProps<YesNoUnsure>) => ReactElement<IYesNoButtonProps<YesNoUnsure>> = ({ label, value, error, testElementName, onChange }) => {
	const customAttributes = testElementName ? { "data-sel": testElementName } : {};

	return (
		<FormControl fullWidth error={!!error} {...customAttributes}>
			<div style={{ display: "flex", flexFlow: "column", alignItems: "center", width: "100%" }}>
				{label && <Typography variant={"h5"}>{label}</Typography>}
				<ToggleButtonGroup color={"primary"} value={value} exclusive fullWidth onChange={(_, v) => onChange(v)}>
					{Object.entries(YesNoUnsure).map(([k, v]) => <ToggleButton key={k} value={v}>{k}</ToggleButton>)}
				</ToggleButtonGroup>
				<FormHelperText>{error}</FormHelperText>
			</div>
		</FormControl>
	);
};

const YesNoSilentButton: (props: IYesNoButtonProps<YesNoSilent>) => ReactElement<IYesNoButtonProps<YesNoSilent>> = ({ label, value, error, testElementName, onChange }) => {
	const customAttributes = testElementName ? { "data-sel": testElementName } : {};
	return (
		<FormControl fullWidth error={!!error} {...customAttributes}>
			<div style={{ display: "flex", flexFlow: "column", alignItems: "center", width: "100%" }}>
				{label && <Typography variant={"h5"}>{label}</Typography>}
				<ToggleButtonGroup color={"primary"} value={value} exclusive fullWidth onChange={(_, v) => onChange(v)}>
					{Object.entries(YesNoSilent).map(([k, v]) => <ToggleButton key={k} value={v}>{v}</ToggleButton>)}
				</ToggleButtonGroup>
			</div>
			<FormHelperText>{error ?? " "}</FormHelperText>
		</FormControl>
	);
};

const YesNoButton: (props: IYesNoButtonProps<YesNo>) => ReactElement<IYesNoButtonProps<YesNo>> = ({ label, value, error, testElementName, onChange }) => {
	const customAttributes = testElementName ? { "data-sel": testElementName } : {};
	return (
		<FormControl fullWidth error={!!error} {...customAttributes}>
			<div style={{ display: "flex", flexFlow: "column", alignItems: "center", width: "100%" }}>
				{label && <Typography variant={"h5"}>{label}</Typography>}
				<ToggleButtonGroup color={"primary"} value={value} exclusive fullWidth onChange={(_, v) => onChange(v)}>
					{Object.entries(YesNo).map(([k, v]) => <ToggleButton key={k} value={v}>{k}</ToggleButton>)}
				</ToggleButtonGroup>
			</div>
			<FormHelperText>{error ?? " "}</FormHelperText>
		</FormControl>
	);
};

export { YesNoUnsureButton, YesNoSilentButton, YesNoButton };