import { Grid, TextField } from "@mui/material";
import { controlMinWidth } from "library";
import { FC, HTMLInputTypeAttribute } from "react";

interface Props {
	label: string,
	value: unknown,
	error?: string,
	type?: HTMLInputTypeAttribute,
	autocomplete?: string,
	columnWidth: { xs: number, sm: number, md: number },
	id?: string,
	onChange: (value: string) => void,
	onBlur?: (event: React.FocusEvent) => void,
	required?: boolean,
	readonly?: boolean,
	testElementName?: string;
}

const GridText: FC<Props> = ({ label, value, error, type, autocomplete, columnWidth, id, onChange, required, onBlur, readonly, testElementName }) => {

	const inputProps = testElementName
		? { readonly, "data-sel": testElementName } // Include 'data-sel' only if testElementName is provided
		: { readonly };

	return (
		<Grid item {...columnWidth} sx={{ minWidth: controlMinWidth }}>
			<TextField
				id={id}
				label={label.concat(required ? " *" : "")}
				value={value ?? ""}
				onChange={e => onChange(e.target.value)}
				onBlur={e => onBlur ? onBlur(e) : onChange(e.target.value?.trim())}
				error={!!error}
				helperText={error}
				disabled={readonly}
				fullWidth
				type={type ?? "text"}
				autoComplete={autocomplete}
				inputProps={inputProps}
			/>
		</Grid>
	);
};

export {
	GridText
};