import {Grid} from "@mui/material";
import {fullWidth, IncomeAssets, IncomeFrequency, oneThirdWidth, OtherIncome} from "library";
import {SectionSubHeading} from "ui-component/SectionHeading";
import {AppSelectList} from "components";
import React from "react";
import {useIncomeTypes} from "hooks";

type IncomeSectionProps = {
	income: IncomeAssets;
	onChange: (value: Partial<IncomeAssets>) => void;
}

const IncomeSection = ({income, onChange}: IncomeSectionProps) => {
	const {incomeSources} = useIncomeTypes();
	
	return (
		<Grid container spacing={3}>
			<Grid item {...fullWidth}>
				<SectionSubHeading>Income Details</SectionSubHeading>
			</Grid>

			<Grid item {...oneThirdWidth}>
				<AppSelectList
					testElementName="ddlIncomeSource"
					label={"Primary Income Source"}
					required
					value={income?.otherIncome?.[0]?.source?.name}
					items={incomeSources.map(s => s.name)}
					error={income.errorState.otherIncome}
					onChange={v => onChange({otherIncome: [new OtherIncome({...income?.otherIncome[0], frequency: IncomeFrequency.Annually, source: incomeSources.find(t => t.name === v)})]})}
				/>
			</Grid>
		</Grid>
	);
};

export {
	IncomeSection
};