// material-ui
import {styled} from "@mui/material/styles";

// project imports
import AppBar from "ui-component/extended/AppBar";

// assets
import RegistrationContainer from "./RegistrationContainer";
import {CSSProperties} from "react";
import {useParams} from "react-router-dom";
import {HeaderBackground} from "ui-component/extended/HeaderBackground";
import {ApplicationType, getDataUri} from "library";
import {useTenantPalette} from "themes/palette";

const Registration = () => {
	const {type} = useParams();
	const theme = useTenantPalette();

	const HeaderStyles: Record<string, CSSProperties> = {
		GBV: {backgroundImage: getDataUri(<HeaderBackground forType={"GBV"} palette={theme} />)},
		default: {backgroundImage: getDataUri(<HeaderBackground forType={ApplicationType.Standard} palette={theme} />)},
	};
	
	const HeaderWrapper = styled("div")(({ theme }) => ({
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		backgroundRepeat: "no-repeat",
		textAlign: "center",
		paddingTop: 30,
		height: "600px",
		[theme.breakpoints.down("md")]: {
			paddingTop: 0
		},
		...(HeaderStyles[type?.toUpperCase() ?? "default"] ?? HeaderStyles.default),
	}));
	
	return (
		<HeaderWrapper>
			<AppBar />
			<RegistrationContainer />
		</HeaderWrapper>
	);
};

export default Registration;
