import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Address } from "library/types";
import {fullWidth, halfWidth, isEmpty, oneThirdWidth} from "library";
import { GridText } from "./GridText";
import { IAddress, useCanadaPostAutoComplete } from "hooks/useCanadaPostAutoComplete";
import {FormControl, FormControlLabel, Grid, Switch} from "@mui/material";

interface IAddressProps {
	value: Address;
	columnWidth?: {xs: number, sm: number, md: number};
	prependId?: string;
	inError?: boolean;
	onChange: (value: Partial<Address>) => void;
}

const AddressForm: FC<IAddressProps> = ({value, columnWidth, prependId, inError, onChange}) => {
	const streetNumberId = useMemo(() => `${prependId || ""}StreetNumber`, [prependId]);
	const streetNameId = useMemo(() => `${prependId || ""}StreetName`, [prependId]);
	const lookupFieldId = useMemo(() => `${prependId || ""}lookup`, [prependId]);
	const [lookupValue, setLookupValue] = useState<string>("");
	const [manualEntry, setManualEntry] = useState(value.manuallyEntered);

	const handleChange = useCallback((v: Partial<Address>) => {
		onChange(new Address({...value, ...v}));
	}, [value]);

	const cpSelectedAddress: IAddress = useCanadaPostAutoComplete([lookupFieldId]);
	useEffect(() => {
		if (isEmpty(cpSelectedAddress)) return;
		
		onChange(new Address({...cpSelectedAddress, manuallyEntered: manualEntry}));
		setLookupValue("");
	}, [cpSelectedAddress]);
	
	return (
		<>
			<GridText id={""} label={"C/O"} value={value.careOf} error={value.errorState.careOf} columnWidth={halfWidth} autocomplete={"careof"} onChange={v => handleChange({careOf: v})} />
			<GridText id={prependId ? lookupFieldId : undefined} value={lookupValue} readonly={manualEntry} label={"Address Lookup"} autocomplete={"address lookup"} columnWidth={fullWidth} onChange={v => setLookupValue(v)} />
			
			<Grid item {...fullWidth}>
				<FormControl fullWidth>
					<FormControlLabel control={<Switch value={manualEntry} onChange={() => setManualEntry(!manualEntry)} />} label={"I can't find my address and need to input it manually"} />
				</FormControl>
			</Grid>

			<GridText id={prependId ? streetNumberId : undefined} readonly={!manualEntry} value={value.streetNumber} error={inError ? value.errorState.streetNumber : ""} label={"Street Number*"} autocomplete={"street number"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => handleChange({streetNumber: v})} />
			<GridText id={prependId ? streetNameId : undefined} readonly={!manualEntry} value={value.streetName} error={inError ? value.errorState.streetName : ""} label={"Street Name"} autocomplete={"streetname"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => handleChange({streetName: v})} />
			<GridText value={value.unit} label={"Unit / Suite Number"} readonly={!manualEntry} error={inError ? value.errorState.unit : ""} autocomplete={"unitnumber"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => handleChange({unit: v})} />
			<GridText value={value.city} error={inError ? value.errorState.city : ""} label={"City*"} readonly={!manualEntry} autocomplete={"city"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => handleChange({city: v})} />
			<GridText value={value.province} error={inError ? value.errorState.province : ""} label={"Province*"} readonly={!manualEntry} autocomplete={"province"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => handleChange({province: v})} />
			<GridText value={value.postalCode} error={inError ? value.errorState.postalCode : ""} label={"Postal Code*"} readonly={!manualEntry} autocomplete={"postal-code"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => handleChange({postalCode: v})} />
			<GridText value={value.country} error={inError ? value.errorState.country : ""} label={"Country*"} readonly={!manualEntry} autocomplete={"shipping country"} columnWidth={columnWidth ?? oneThirdWidth} onChange={v => handleChange({country: v})} />
		</>
	);
};

export { AddressForm };