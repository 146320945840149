import {hasAnyFalseyItem} from "library/common";
import {createErrorState, ErrorState, IErrorState} from "../errorState";
import {FileProcess} from "../enums";

interface IApplicationDocuments {
	bankInfo?: IAccountInfo;
	rentProof: DocumentRequest;
	eftDetails: DocumentRequest;
	incomeDocuments: DocumentRequest;
	statusInCanada: DocumentRequest;
	offerAgreement: DocumentRequest;
	other: DocumentRequest;
	safety: DocumentRequest;
}

interface IDocumentRequest {
	required: boolean;
	files: IFileInfo[];
}

class DocumentRequest implements IDocumentRequest {
	readonly required: boolean;
	readonly files: IFileInfo[];
	
	constructor(from?: DocumentRequest) {
		const mapFileInfo = (f: IFileInfo) => ({name: f.name, size: f.size, type: f.type, lastModified: f.lastModified, state: f.state ?? FileProcess.Idle});
		
		this.required = from?.required ?? false;
		this.files = from?.files?.map(mapFileInfo) ?? [];
	}
}

interface IFileInfo {
	name: string;
	type: string;
	size: number;
	lastModified: Date;
	state: FileProcess;
}

interface IAccountInfo {
	holderName?: string;
	transit?: string;
	institution?: string;
	account?: string;
}
class BankInfo implements IAccountInfo {

	readonly holderName?:string;
	readonly transit?:string;
	readonly institution?:string;
	readonly account?:string;

	constructor(from?: IAccountInfo) {
		this.holderName = from?.holderName?.replaceAll(/[^a-zA-Z\s]/g, "").slice(0, 30) ?? "";
		this.transit = from?.transit?.slice(0, 5) ?? "";
		this.institution = from?.institution?.slice(0, 3) ?? "";
		this.account = from?.account?.slice(0, 12) ?? "";
	}
}

class ApplicationDocuments implements Partial<IApplicationDocuments>, IErrorState<Partial<IApplicationDocuments>> {
	[key: string]: unknown;
	
	readonly bankInfo?: BankInfo;
	readonly rentProof: DocumentRequest;
	readonly eftDetails: DocumentRequest;
	readonly incomeDocuments: DocumentRequest;
	readonly statusInCanada: DocumentRequest;
	readonly offerAgreement: DocumentRequest;
	readonly other: DocumentRequest;
	readonly safety: DocumentRequest;
	private readonly validated: boolean;
	
	constructor(from?: Partial<IApplicationDocuments>) {
		this.bankInfo = from?.bankInfo;
		this.rentProof = new DocumentRequest(from?.rentProof);
		this.eftDetails = new DocumentRequest(from?.eftDetails);
		this.incomeDocuments = new DocumentRequest(from?.incomeDocuments);
		this.statusInCanada = new DocumentRequest(from?.statusInCanada);
		this.offerAgreement = new DocumentRequest(from?.offerAgreement);
		this.other = new DocumentRequest(from?.other);
		this.safety = new DocumentRequest(from?.safety);
		this.validated = (from as ApplicationDocuments)?.validated ?? false;
	}
	
	get hasRequiredDocuments(): boolean {
		return this.rentProof.required || this.eftDetails.required || this.incomeDocuments.required || this.statusInCanada.required || this.other.required || this.safety.required;	
	}
	
	get errorState(): ErrorState<Partial<IApplicationDocuments>> {
		const errorMessage = "Supporting documents required.";
		
		return this.validated ?
			createErrorState<IApplicationDocuments>({
				rentProof: this.rentProof.required && !this.rentProof.files.length ? errorMessage : "",
				eftDetails: this.eftDetails.required 
					&& !this.eftDetails.files.length
					&& hasAnyFalseyItem(this.bankInfo) ? errorMessage : "",
				incomeDocuments: this.incomeDocuments.required && !this.incomeDocuments.files.length ? errorMessage : "",
				statusInCanada: this.statusInCanada.required && !this.statusInCanada.files.length ? errorMessage : "",
				other: this.other.required && !this.other.files.length ? errorMessage : "",
				safety: this.safety.required && !this.safety.files.length ? errorMessage : ""
			}) : {};
	}
	
	validate(isGbv?: boolean): ApplicationDocuments {
		return new ApplicationDocuments({...this, validated: isGbv ? false : true});
	}
}

export {ApplicationDocuments, BankInfo};
export type {IApplicationDocuments, IFileInfo, IAccountInfo};