import {createErrorState, ErrorState, IErrorState} from "../errorState";
import {YesNoSilent, yesNoSilentFrom} from "../enums";

interface ISituation {
	accommodationForDisability: YesNoSilent;
	armedForceMember: YesNoSilent,
	currentlyEmployed: YesNoSilent;
	emergencySituation: YesNoSilent;
	facingViolence: YesNoSilent,
	hasDisability: YesNoSilent,
	hasMentalConcerns: YesNoSilent,
	homeless: YesNoSilent,
	indigenous: YesNoSilent,
	immigrant: YesNoSilent,
	isMinority: YesNoSilent,
	lgbtq: YesNoSilent,
	overcrowded: YesNoSilent;
	receivingOtherBenefits: YesNoSilent;
	sharedAccommodation: YesNoSilent;
	threatToPhysicalWellBeing: YesNoSilent;
	training: YesNoSilent;
	transitioningOutOfCare: YesNoSilent;
}

class Situation implements Partial<ISituation>, IErrorState<Partial<ISituation>> {
	[key: string]: unknown;

	readonly accommodationForDisability?: YesNoSilent;
	readonly armedForceMember?: YesNoSilent;
	readonly currentlyEmployed?: YesNoSilent;
	readonly emergencySituation?: YesNoSilent;
	readonly facingViolence?: YesNoSilent;
	readonly hasDisability?: YesNoSilent;
	readonly hasMentalConcerns?: YesNoSilent;
	readonly homeless?: YesNoSilent;
	readonly lgbtq?: YesNoSilent;
	readonly indigenous?: YesNoSilent;
	readonly immigrant?: YesNoSilent;
	readonly isMinority?: YesNoSilent;
	readonly overcrowded?: YesNoSilent;
	readonly receivingOtherBenefits?: YesNoSilent;
	readonly sharedAccommodation?: YesNoSilent;
	readonly threatToPhysicalWellBeing?: YesNoSilent;
	readonly training?: YesNoSilent;
	readonly transitioningOutOfCare?: YesNoSilent;
	protected readonly validated: boolean;
	
	constructor(from?: Partial<ISituation>) {
		this.validated = (from as Situation)?.validated ?? false;
		this.accommodationForDisability = yesNoSilentFrom(from?.accommodationForDisability);
		this.armedForceMember = yesNoSilentFrom(from?.armedForceMember);
		this.currentlyEmployed = yesNoSilentFrom(from?.currentlyEmployed);
		this.emergencySituation = yesNoSilentFrom(from?.emergencySituation);
		this.facingViolence = yesNoSilentFrom(from?.facingViolence);
		this.hasDisability = yesNoSilentFrom(from?.hasDisability);
		this.hasMentalConcerns = yesNoSilentFrom(from?.hasMentalConcerns);
		this.homeless = yesNoSilentFrom(from?.homeless);
		this.indigenous = yesNoSilentFrom(from?.indigenous);
		this.immigrant = yesNoSilentFrom(from?.immigrant);
		this.isMinority = yesNoSilentFrom(from?.isMinority);
		this.lgbtq = yesNoSilentFrom(from?.lgbtq);
		this.overcrowded = yesNoSilentFrom(from?.overcrowded);
		this.receivingOtherBenefits = yesNoSilentFrom(from?.receivingOtherBenefits);
		this.sharedAccommodation = yesNoSilentFrom(from?.sharedAccommodation);
		this.threatToPhysicalWellBeing = yesNoSilentFrom(from?.threatToPhysicalWellBeing);
		this.training = yesNoSilentFrom(from?.training);
		this.transitioningOutOfCare = yesNoSilentFrom(from?.transitioningOutOfCare);
	}
	
	get errorState(): ErrorState<Partial<ISituation>> {
		const errorMessage = "Please select a value.";

		return this.validated ?
			createErrorState<Partial<ISituation>>(Object.entries(this)
				.filter(([,v]) => !v)
				.map(([k]) => ({[k]: errorMessage}))
				.reduce((p, v) => ({...p, ...v}), {})
			) : {};
	}
	validate(): Situation {
		return new Situation({...this, validated: true});
	}
}

export {Situation};
export type {ISituation};