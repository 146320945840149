import { PropsWithChildren, useEffect } from "react";
import {useLocation, useParams} from "react-router-dom";
import { loadWithCode } from "store/slices/application";
import { useDispatch } from "store";
import { reset } from "store/slices/intake";
import { useApplication } from "hooks";
import useNavigator from "hooks/useNavigator";
import { GbvBuilder } from "library";

const ApplicationLoader = ({ children }: PropsWithChildren) => {
	const location = useLocation();
	const navigate = useNavigator();
	const {program, code } = useParams();
	const { loading, application, isStale, mutations: {set} } = useApplication();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!code || [application.id, application.code].includes(code) || loading) return;
		if(location.pathname.includes("submission")) return;

		dispatch(loadWithCode(code))
			.unwrap()
			.then(builder => {
				if (builder.programs?.isStrictly("GBV") || program === "GBV") {
					set(new GbvBuilder(builder), true);
					navigate(location.pathname.replace(/.*\/application\//i, "/pages/gbv/"), {replace: true});
				}
			});
	}, [code, application.code, loading, isStale]);

	useEffect(() => {
		dispatch(reset());
	}, []);
	
	return loading
		? <div>loading...</div>
		: <>{children}</>;
};

export { ApplicationLoader };
