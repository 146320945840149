import { Bedrooms, YesNo } from "../enums";
import { createErrorState, ErrorState, IErrorState } from "../errorState";
import { DateValue } from "../DateValue";


interface IHousing {
	monthlyRent: number;
	bedrooms: Bedrooms;
	liveInAide: YesNo;
	water: YesNo;
	electricity: YesNo;
	heating: YesNo;
	monthlyUtilities: number;
	leaseExpiry: DateValue;
	onAMonthToMonthLease: boolean;
}

class Housing implements Partial<IHousing>, IErrorState<Partial<IHousing>> {
	readonly monthlyRent?: number;
	readonly bedrooms?: Bedrooms;
	readonly liveInAide?: YesNo;
	readonly heating?: YesNo;
	readonly electricity?: YesNo;
	readonly water?: YesNo;
	readonly monthlyUtilities?: number;
	readonly leaseExpiry?: DateValue;
	readonly onAMonthToMonthLease?: boolean;
	protected readonly validated: boolean = false;

	constructor(from?: Partial<IHousing>) {
		this.monthlyRent = from?.monthlyRent ? Number(from.monthlyRent) : undefined;
		this.bedrooms = from?.bedrooms;
		this.liveInAide = from?.liveInAide;
		this.heating = from?.heating;
		this.electricity = from?.electricity;
		this.water = from?.water;
		this.monthlyUtilities = from?.monthlyUtilities ? Number(from.monthlyUtilities) : 0;
		this.leaseExpiry = DateValue.create(from?.leaseExpiry);
		this.onAMonthToMonthLease = from?.onAMonthToMonthLease === true;
		this.validated = (from as Housing)?.validated ?? false;
	}

	get paysForUtilities(): boolean {
		return this.water === YesNo.Yes || this.electricity === YesNo.Yes || this.heating === YesNo.Yes;
	}

	get errorState(): ErrorState<Partial<IHousing>> {
		return this.validated ?
			createErrorState<IHousing>({
				monthlyRent: !this.monthlyRent || isNaN(this.monthlyRent) ? "Monthly rent required." : "",
				bedrooms: !this.bedrooms ? "Required." : "",
				monthlyUtilities: (!this.monthlyUtilities || isNaN(this.monthlyUtilities)) && this.paysForUtilities ? "Utilities total required." : "",
				leaseExpiry: !this.onAMonthToMonthLease && !this.leaseExpiry ? "Lease expiry is required" : "",
				liveInAide: !this.liveInAide ? "Required." : "",
				heating: !this.heating ? "Required." : "",
				electricity: !this.electricity ? "Required." : "",
				water: !this.water ? "Required." : ""
			}) : {};
	}

	validate(): Housing {
		return new Housing({ ...this, validated: true });
	}
}

export { Housing };
export type { IHousing };